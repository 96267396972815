import { Box, Typography } from '@material-ui/core'
import { SurButton, SurIconButton, SurPopOver } from '@sur-ui/core'
import { ReactComponent as Tick } from 'assets/svg/submitTick.svg'
import React, { useState } from 'react'
import { Language } from 'types/serverTypes'
import { useDefaultLayoutContext } from '../DefaultLayout.context'
import useStyle from './LanguageSelector.styles'

const LanguageSelector: React.FC = () => {
  const classes = useStyle()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const {
    currentLanguage,
    languages,
    handleUserLanguageChange,
  } = useDefaultLayoutContext()

  const handleClick = (item: Language) => (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation()
    e.preventDefault()
    handleUserLanguageChange(item)
  }

  return (
    <Box component="div" className={classes.root}>
      <SurIconButton
        variant="text"
        classes={{ root: classes.button }}
        color="inherit"
        onClick={(event) => {
          setAnchorEl(event.currentTarget as any)
        }}
        data-cy="LanguageSelectorButton"
      >
        <img
          className={classes.flagImage}
          src={currentLanguage?.flagUrl}
          alt={currentLanguage?.countryLanguageDto?.name}
        />
      </SurIconButton>
      <SurPopOver
        {...{ anchorEl }}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classes.paper }}
        data-cy="SelectLanguagesPopOver"
      >
        {languages?.map((item) => (
          <SurButton
            variant="text"
            color="inherit"
            key={item.id}
            classes={{
              root: classes.childButton,
              label: classes.childButtonLabel,
            }}
            onClick={handleClick(item)}
            data-cy={`LanguagesPopOverButton-${item?.countryLanguageDto?.name}`}
          >
            {currentLanguage?.id === item.id ? (
              <Tick height={16} className="TickSvg" width={16} />
            ) : (
              <div style={{ width: 16 }} />
            )}
            {item?.flagUrl && (
              <img
                className={classes.childImage}
                src={item?.flagUrl}
                alt={item?.countryLanguageDto?.name ?? ''}
              />
            )}
            <Typography>{item?.countryLanguageDto?.name}</Typography>
          </SurButton>
        ))}
      </SurPopOver>
    </Box>
  )
}
export default LanguageSelector
