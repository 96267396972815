import { makeFloraMaidStyles } from 'theme'

export default makeFloraMaidStyles(({ spacing, palette }) => ({
  root: {
    cursor: 'pointer',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  arrowIcon: {
    margin: spacing(0, 3, 0, 0),
  },
  userName: {
    fontSize: '1.125rem',
    color: palette.neutral[700],
    margin: spacing(0, 5),
  },
  paper: {
    width: 220,
    boxShadow: `0px 5px 10px rgba(0,0,0,0.2)`,
    padding: spacing(2, 0, 4, 0),
  },
}))
