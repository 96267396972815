import { SurChip, SurToast } from '@sur-ui/core'
import { contextWithEndpoint, EndpointType } from 'core/apollo/links'
import { map, pipe } from 'ramda'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Country, Currency } from 'types/serverTypes'
import { useGeneralScreenContext } from '../GeneralScreen.context'
import {
  useDefaultCurrencyMutation,
  useDeleteCurrencyMutation,
  useUpdateCurrencyMutation,
} from '../GeneralScreen.mutations.generated'
import currencyTabMessages from './CurrencyTab.messages'
import { CurrencyModalSubmit } from './CurrencyTab.types'

const useCurrencyTab = () => {
  const {
    currencies,
    currenciesLoading,
    getCurrenciesList,
  } = useGeneralScreenContext()

  const [updateCurrency] = useUpdateCurrencyMutation({
    ...contextWithEndpoint(EndpointType.Price),
  })
  const [deleteCurrency] = useDeleteCurrencyMutation({
    ...contextWithEndpoint(EndpointType.Price),
  })

  const [changeDefaultCurrency] = useDefaultCurrencyMutation({
    ...contextWithEndpoint(EndpointType.Price),
  })
  const { formatMessage } = useIntl()
  const defaultCurrency = currencies?.find((item) => item.isDefault)

  const [primaryCurrencyValue, setPrimaryCurrencyValue] = useState<
  Currency | undefined
  >()
  const [modalData, setModalData] = useState<
  { row?: Currency; status: ModalStatus } | undefined
  >(undefined)

  useEffect(() => {
    getCurrenciesList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setPrimaryCurrencyValue(defaultCurrency)
  }, [defaultCurrency])

  const primaryCurrencyOptions = {
    disabled: false,
    labelExp: 'name',
    valueExp: 'id',
    iconExp: 'slug',
    disableExp: 'disabled',
    multiple: false,
    isLoading: false,
    hasAutoComplete: true,
  }
  const primaryCurrencyInputProps = {
    name: 'primaryCurrency',
    label: formatMessage(currencyTabMessages.primaryCurrencyTitle),
    // placeholder: 'Placeholder Text',
  }

  const columns = React.useMemo(
    () => [
      {
        Header: formatMessage(currencyTabMessages.gridHeaderTitle),
        accessor: 'name', // accessor is the "key" in the data
        sortable: true,
        Cell: (props: any) => (
          <div
            style={{ display: 'flex', alignItems: 'center', padding: '0 12px' }}
          >
            <h5 style={{ margin: '0 10px' }}>{props.row.original?.slug}</h5>
            {props.row.original?.name}
          </div>
        ),
      },
      {
        Header: formatMessage(currencyTabMessages.gridHeaderCountries),
        accessor: 'countries',
        Cell: (props: any) => (
          <div
            style={{
              display: 'flex',
              padding: '0 12px',
              width: '100%',
              flexWrap: 'wrap',
            }}
          >
            {props.row.original?.usedInCountryDtos.map((item: Country) => (
              <SurChip
                key={item.name as string}
                {...{
                  color: 'primary',
                  label: item.name,
                  icon: item?.flagUrl ? (
                    <img src={item?.flagUrl} alt={item.name as string} />
                  ) : undefined,
                }}
              />
            ))}
          </div>
        ),
      },
    ],
    [formatMessage],
  )

  const onSubmit: CurrencyModalSubmit = (
    id,
    { selectCountries, selectCurrency },
  ) => {
    if (!selectCurrency) return
    const usedInCountryDtos = map(pipe((item: Country) => ({ id: item?.id })))(
      selectCountries,
    )

    updateCurrency({
      variables: {
        currency: {
          includes: ['UsedInCountries'],
          dto: {
            id: selectCurrency?.id,
            name: selectCurrency?.name,
            published: true,
            usedInCountryDtos,
          },
        },
      },
    }).then(() => {
      getCurrenciesList()
      setModalData(undefined)
      SurToast(
        formatMessage(
          modalData?.status === 'add'
            ? currencyTabMessages.addCurrencyToastContent
            : currencyTabMessages.updateCurrencyToastContent,
        ),
        {},
      )
    })
  }

  const handleDeleteCurrency = (rawRow: string[] | number[]): void => {
    deleteCurrency({
      variables: { currency: { ids: rawRow } },
    }).then(() => {
      getCurrenciesList()
      SurToast(
        formatMessage(currencyTabMessages.deleteCurrencyToastContent),
        {},
      )
    })
  }
  const handleEditCurrency = (rawRow: any) => {
    setModalData({ row: rawRow, status: 'edit' })
  }
  const handleSetPrimaryCurrency = () => {
    changeDefaultCurrency({
      variables: {
        currency: {
          dto: {
            id: primaryCurrencyValue?.id,
            isDefault: true,
          },
        },
      },
    }).then(() =>
      SurToast(
        formatMessage(currencyTabMessages.setPrimaryCurrencyToastContent),
        {},
      ),
    )
  }
  return {
    modalData,
    primaryCurrencyOptions,
    primaryCurrencyInputProps,
    columns,
    currencies,
    currenciesLoading,
    defaultCurrency,
    primaryCurrencyValue,
    handleDeleteCurrency,
    handleEditCurrency,
    handleSetPrimaryCurrency,
    setPrimaryCurrencyValue,
    setModalData,
    formatMessage,
    onSubmit,
  }
}

export default useCurrencyTab
