import { defineMessages } from 'react-intl'

const scope = 'app.pages.setting.general.currencyTab'

const CurrencyTab = defineMessages({
  addCurrency: {
    id: `${scope}.addCurrency`,
    defaultMessage: 'Currency',
  },
  primaryCurrencyTitle: {
    id: `${scope}.primaryCurrency.title`,
    defaultMessage: 'Primary Currency',
  },
  primaryCurrencyContent: {
    id: `${scope}.primaryCurrency.content`,
    defaultMessage: 'Your primary currency is set to',
  },
  primaryCurrencyButton: {
    id: `${scope}.primaryCurrency.button`,
    defaultMessage: 'Set',
  },
  gridHeaderTitle: {
    id: `${scope}.grid.header.title`,
    defaultMessage: 'Title',
  },
  gridHeaderCountries: {
    id: `${scope}.grid.header.countries`,
    defaultMessage: 'Countries',
  },
  gridHeaderActions: {
    id: `${scope}.grid.header.actions`,
    defaultMessage: 'Actions',
  },
  modalTitle: {
    id: `${scope}.modal.title`,
    defaultMessage: 'Currency',
  },
  modalTitleAddPrefix: {
    id: `${scope}.modal.title.prefix.add`,
    defaultMessage: 'Add',
  },
  modalTitleEditPrefix: {
    id: `${scope}.modal.title.prefix.edit`,
    defaultMessage: 'Edit',
  },
  modalTitleTooltip: {
    id: `${scope}.modal.title.tooltip`,
    defaultMessage: 'Currency Help',
  },
  modalFormCurrencyLabel: {
    id: `${scope}.modal.form.currency.label`,
    defaultMessage: 'Currency',
  },
  modalFormCurrencyValidation: {
    id: `${scope}.modal.form.currency.validation`,
    defaultMessage: 'Please upload Dictionary',
  },
  modalFormCountryLabel: {
    id: `${scope}.modal.form.country.label`,
    defaultMessage: 'Country',
  },
  modalFormCountryValidation: {
    id: `${scope}.modal.form.country.validation`,
    defaultMessage: 'Please select a Country',
  },
  modalFormPublishedLabel: {
    id: `${scope}.modal.form.published.label`,
    defaultMessage: 'Publish Currency',
  },
  modalCancel: {
    id: `${scope}.modal.cancel`,
    defaultMessage: 'Cancel',
  },
  modalSubmit: {
    id: `${scope}.modal.submit`,
    defaultMessage: 'OK',
  },
  setPrimaryCurrencyToastContent: {
    id: `${scope}.setPrimaryCurrency.toast.content`,
    defaultMessage: 'Your Primary Currency change successfully',
  },
  addCurrencyToastContent: {
    id: `${scope}.addCurrency.toast.content`,
    defaultMessage: 'Your Currency add successfully',
  },
  updateCurrencyToastContent: {
    id: `${scope}.updateCurrency.toast.content`,
    defaultMessage: 'Your Currency update successfully',
  },
  deleteCurrencyToastContent: {
    id: `${scope}.deleteCurrency.toast.content`,
    defaultMessage: 'Your Currency delete successfully',
  },
  footerHelpLabel: {
    id: `${scope}.footer.help.label`,
    defaultMessage: 'Learn more about Currency',
  },
})
export default CurrencyTab
