import { makeFloraMaidStyles } from 'theme'

// const drawerWidth = 336

export const useStyle = makeFloraMaidStyles(({ palette, spacing }) => ({
  root: {
    width: '100%',
    height: '100vh',
  },
  AppBar: {
    height: 100,
    background: '#fff',
    boxShadow: 'unset',
    borderBottom: `1px solid ${palette.neutral[100]}`,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  topMenuContainer: {
    display: 'flex',
    flex: 1,
    // alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    padding: spacing(0, 22, 0, 17),
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    width: 336,
    height: '100%',
    padding: spacing(3, 0, 3, 12),
  },
  endAppBar: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    zIndex: 1,
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    flexGrow: 1,
    height: 'calc(100% - 100px)',
  },
  sidebarContainer: {
    width: 336,
  },
  navItemsRoot: {
    padding: 0,
    '&:hover': {
      backgroundColor: palette.primary[50],
    },
  },
  navItemsSelected: {
    backgroundColor: 'red',
  },
  navItemsGutters: {
    padding: spacing(2.5, 0, 2.5, 12),
  },
  navIcons: {
    '& svg': {
      width: 24,
      height: 24,
    },
  },
  drawerRoot: {
    height: '100%',
  },
  drawerPaper: {
    position: 'relative',
    width: 336,
    overflow: 'inherit',
    '& ul': {
      backgroundColor: '#fff',
      overflow: 'hidden',
      overflowY: 'auto',
    },
  },
  content: {
    flex: 1,
    backgroundColor: palette.background.default,
    padding: spacing(3, 0),
    minWidth: 0, // So the Typography noWrap works
    overflow: 'hidden',
    overflowY: 'auto',
  },
  activeRoute: {
    backgroundColor: palette.primary.main,
    width: '102%',
    '& path': {
      fill: '#fff',
    },
    '& span': {
      color: '#fff',
    },
    position: 'relative',
    '&:after': {
      content: "''",
      backgroundColor: '#fff',
      position: 'absolute',
      right: 5,
      top: 0,
      bottom: 0,
      width: 3,
    },
    '&:hover': {
      backgroundColor: palette.primary.main,
    },
  },
  devider: {
    border: 'none',
    borderTop: `1px solid ${palette.primary[50]}`,
    color: '#fff',
    backgroundColor: '#fff',
    height: 1,
    width: '90%',
    margin: '14px auto',
  },
}))

export default useStyle
