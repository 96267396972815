import { useApolloClient } from '@apollo/client'
import { TOKEN_QUERY } from 'core/apollo/generalQueries'
import { convertArrayToTree } from 'core/helpers'
import { useScreenWidth } from 'core/hooks'
import Dashboard from 'domains/Dashboard'
import Setting from 'domains/Setting'
import { User } from 'oidc-client'
import { useEffect, useMemo, useState } from 'react'
import { RouteProps } from 'react-router-dom'
import { Maybe, Resource } from 'types/serverTypes'
import { IAppContext } from './App.context'
import { useGetPermissions } from './gql/hooks'

// eslint-disable-next-line no-shadow
enum EntityType {
  DashboardEntity = 'dashboard',
  SettingEntity = 'setting',
  // ProductsEntity = 'products',
  // CustomersEntity = 'customers',
  // OrdersEntity = 'orders',
  // StoresEntity = 'stores',
  // ShipmentEntity = 'shipment',
  // ReportsEntity = 'reports',
  // DiscountsEntity = 'discounts',
}

const entities: Record<EntityType, React.ComponentType<any>> = {
  [EntityType.DashboardEntity]: Dashboard,
  [EntityType.SettingEntity]: Setting,
  // [EntityType.CustomersEntity]: Dashboard,
  // [EntityType.DiscountsEntity]: Dashboard,
  // [EntityType.OrdersEntity]: Dashboard,
  // [EntityType.ProductsEntity]: Dashboard,
  // [EntityType.ReportsEntity]: Dashboard,
  // [EntityType.ShipmentEntity]: Dashboard,
  // [EntityType.StoresEntity]: Dashboard,
}

const redirectRoutes: IAppContext['redirectRoutes'] = {
  notFound: '/dashboard',
  signIn: '/dashboard',
}

const _toNavWithEntity = ({
  attribute,
  id,
  parentId,
  title: label,
  value: path,
  fileUrl: iconUri,
  uIResourceType,
}: Resource) => ({
  iconUri,
  id,
  label,
  parentId,
  path,
  uIResourceType,
  entityName: attribute as EntityType,
})

const _extractNavAndRoute = (items: Resource[]) => {
  const navsWithEntity = items.map(_toNavWithEntity)

  const routes = navsWithEntity
    .map(
      ({ entityName, path }) =>
        ({
          path,
          component: entities[entityName],
        } as RouteProps),
    )
    .filter(({ component }) => !!component)

  const [navs] = convertArrayToTree(
    navsWithEntity as INavItem[],
    (_) => !_.parentId && !!entities[_.entityName as EntityType],
  )

  return { navs, routes }
}

const useApp: () => IAppContext = () => {
  const client = useApolloClient()
  const [pageLoading, setPageLoading] = useState(false)
  const screenWidth = useScreenWidth()
  const {
    getPermissions,
    loadingIdentityResources,
    identityResources,
  } = useGetPermissions()

  const navsAndRoutes = useMemo<Pick<IAppContext, 'navs' | 'routes'>>(() => {
    if (!identityResources) return { navs: [], routes: [] }

    const menuPermissionList = (identityResources.permissions?.listDto?.items ??
      []) as Resource[]

    return _extractNavAndRoute(menuPermissionList)
  }, [identityResources])

  useEffect(() => {
    const tokenSubscription = client
      .watchQuery<Maybe<User>>({ query: TOKEN_QUERY })
      .subscribe(({ data: user }) => user?.access_token && getPermissions())
    return () => tokenSubscription.unsubscribe()
  }, [client, getPermissions])

  const startLoading = useMemo(() => {
    let timeoutId: any
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      setPageLoading(true)
      timeoutId = setTimeout(() => {
        console.log('time out!~~~~')
        setPageLoading(false)
      }, 1000)
    }
  }, [])

  return {
    redirectRoutes,
    loadingIdentityResources,
    screenWidth,
    getPermissions,
    pageLoading,
    setPageLoading: startLoading,
    ...navsAndRoutes,
  }
}

export default useApp
