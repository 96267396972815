import { makeFloraMaidStyles } from 'theme'

export default makeFloraMaidStyles(({ palette: { neutral }, spacing }) => ({
  root: { position: 'relative', height: '100%' },
  header: {
    padding: spacing(3, 12),
  },
  breadcrumbs: {
    // margin: spacing(4, 0, 7, 0),
  },
  breadcrumb: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    textDecoration: 'unset',
    color: 'inherit',
    marginRight: 5,
    '&:hover': {},
    '& img': {
      height: 18,
      margin: '0 5px',
    },
  },
  lastBreadcrumb: {
    cursor: 'default',
    color: neutral[900],
  },
  subMenus: {
    border: 'unset',
    display: 'flex',
    flexWrap: 'wrap',
  },
  contentContainer: {
    padding: spacing(0, 12),
    margin: 6,
  },
  menuItem: {
    backgroundColor: '#fff',
    overflow: 'hidden',
    margin: '1.5vw',
    width: '15vw',
    height: '15vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    border: `1px solid ${neutral[500]}`,
    borderRadius: 3,
    textDecoration: 'unset',

    '&:hover': {
      textDecoration: 'unset',
    },
    '& img': {
      height: '4vw',
      width: '4vw',
      // marginBottom: 31.5,
    },
    '& h5': {
      color: neutral[700],
      textAlign: 'center',
    },
    '& p': {
      padding: '0.5vw',
      textAlign: 'center',
      color: neutral[500],
    },
  },
  gridList: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
}))
