import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import useSetting from './Setting.biz'
import { SettingProvider } from './Setting.context'
import SettingRoutes from './SettingRoutes'

const Setting = (props: RouteComponentProps) => {
  const value = useSetting(props)

  return (
    <SettingProvider {...{ value }}>
      <SettingRoutes />
    </SettingProvider>
  )
}
export default Setting
