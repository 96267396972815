import { Box, Grid, Typography } from '@material-ui/core'
import {
  SurAutocomplete,
  SurButton,
  SurDataGrid,
  SurTabPanel
} from '@sur-ui/core'
import { ReactComponent as PlusIcon } from 'assets/svg/plusIcon.svg'
import { ReactComponent as QuestionIcon } from 'assets/svg/questionIcon.svg'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import CurrenciesEditModal from './CurrencyModal'
import useCurrencyTab from './CurrencyTab.biz'
import currencyTabMessages from './CurrencyTab.messages'
import useCurrencyTabStyle from './CurrencyTab.style'
import { CurrencyTabProps } from './CurrencyTab.types'


const CurrencyTab: FC<CurrencyTabProps> = ({ value: position }) => {
  const classes = useCurrencyTabStyle()

  const {
    modalData,
    primaryCurrencyInputProps,
    primaryCurrencyOptions,
    columns,
    currencies,
    currenciesLoading,
    defaultCurrency,
    primaryCurrencyValue,
    handleDeleteCurrency,
    handleEditCurrency,
    handleSetPrimaryCurrency,
    setPrimaryCurrencyValue,
    setModalData,
    formatMessage,
    onSubmit,
  } = useCurrencyTab()

  const handleAddCurrency = () => setModalData({ status: 'add' })
  return (
    <SurTabPanel
      {...{ value: position }}
      index={1}
      classes={{
        root: classes.root,
        content: classes.currencyTab,
      }}
    >
      <Grid lg={7} md={12} className={classes.currencysList}>
        <CurrenciesEditModal
          {...{ modalData, setModalData, isOpen: !!modalData, onSubmit }}
        />
        <SurDataGrid
          {...{
            data:
              currencies?.filter(
                (currency) => currency.usedInCountryDtos?.length,
              ) ?? [],
            columns,
            isLoading: currenciesLoading,
            noToolbar: true,
            phrases: {
              actionColumnTitle: 'Actions',
            },
            'data-cy': 'CurrencyDataGrid',
          }}
          onDelete={handleDeleteCurrency}
          onEditClick={handleEditCurrency}
        />
      </Grid>
      <Grid lg={5} md={12} className={classes.primaryCurrency}>
        <Typography
          variant="h5"
          component="h5"
          classes={{ root: classes.primaryCurrencyTitle }}
        >
          {formatMessage(currencyTabMessages.primaryCurrencyTitle)}
        </Typography>
        <Typography classes={{ root: classes.primaryCurrencyText }}>
          {formatMessage(currencyTabMessages.primaryCurrencyContent)}{' '}
          {defaultCurrency?.name}
        </Typography>
        <Box className={classes.primaryCurrencySelector}>
          <SurAutocomplete
            id="selectPrimaryCurrency"
            classes={{ root: classes.setPrimaryCurrencyInput }}
            {...{
              data: currencies ?? [],
              value: primaryCurrencyValue,
              onChange: setPrimaryCurrencyValue,
            }}
            options={primaryCurrencyOptions}
            inputProps={primaryCurrencyInputProps}
          />
          <SurButton
            className={classes.setPrimaryCurrencyButton}
            data-cy="SetPrimaryCurrencyButton"
            onClick={handleSetPrimaryCurrency}
          >
            {formatMessage(currencyTabMessages.primaryCurrencyButton)}
          </SurButton>
        </Box>
      </Grid>
      <div className={classes.addBtnContainer}>
        <Grid
          // xl={5}
          lg={5}
          md={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <SurButton disabled variant="text" className="addCurrencyBtn" />
          <SurButton
            classes={{ root: 'addCurrencyBtn' }}
            onClick={handleAddCurrency}
            size="small"
            data-cy="AddCurrencyButton"
          >
            <PlusIcon style={{ margin: '0 12px' }} />
            {formatMessage(currencyTabMessages.addCurrency)}
          </SurButton>
        </Grid>
      </div>
      <Box component="footer" className={classes.footer}>
        <Link to="#">
          <QuestionIcon style={{ margin: '0 5px' }} />
          {formatMessage(currencyTabMessages.footerHelpLabel)}
        </Link>
      </Box>
    </SurTabPanel>
  )
}

export default CurrencyTab
