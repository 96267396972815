import React, { createContext, FC, useContext } from 'react'
import { Currency, Language } from 'types/serverTypes'

export interface IGeneralScreenContext {
  languages?: Language[] | null
  // countries: Country[] | null | undefined
  currencies: Currency[] | null | undefined
  languageLoading: boolean | undefined
  // countriesLoading: boolean | undefined
  currenciesLoading: boolean | undefined
  getLanguagesList(): void
  getCurrenciesList(): void
}

interface IGeneralScreenProviderProps {
  children: any
  value: IGeneralScreenContext
}

const GeneralScreenContext = createContext<IGeneralScreenContext>({
  languages: [],
  // countries: [],
  currencies: [],
  currenciesLoading: false,
  languageLoading: false,
  // countriesLoading: false,
  getLanguagesList: () => {},
  getCurrenciesList: () => {},
})

export const useGeneralScreenContext = () =>
  useContext<IGeneralScreenContext>(GeneralScreenContext)

export const GeneralScreenProvider: FC<IGeneralScreenProviderProps> = ({
  children,
  value,
}) => (
  <GeneralScreenContext.Provider {...{ value }}>
    {children}
  </GeneralScreenContext.Provider>
)
