import React, { FC } from 'react'
import { Box, GridList } from '@material-ui/core'
import PageMenuItem from './PageMenuItem'
import useStyle from './PageTemplate.styles'

const PageContentContainer: FC<{ data?: INavItem[] }> = ({
  data,
  children,
}) => {
  const classes = useStyle()
  if (children)
    return (
      <Box component="div" className={classes.contentContainer}>
        {children}
      </Box>
    )
  return (
    <Box component="div" className={classes.subMenus}>
      <GridList
        cols={4}
        spacing={2}
        component="div"
        className={classes.gridList}
      >
        {data?.map((item) => (
          <PageMenuItem key={item.path} {...item} />
        ))}
      </GridList>
    </Box>
  )
}

export default PageContentContainer
