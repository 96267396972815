import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as Seprator } from 'assets/svg/breadcrumbSeperator.svg'
import classNames from 'classnames'
import { SurBreadcrumbs } from '@sur-ui/core'

const PageTemplateHeader: FC<{
  routes?: INavItem[]
  currentAttribute: string
  classes: {
    root: string
    container?: string
    link?: string
    last?: string
  }
}> = ({ routes, currentAttribute, classes }) => {
  const currentPosition = Object.fromEntries(
    currentAttribute.split('/').map((_) => [_, _]),
  )

  const currentRoutes = routes?.filter(
    ({ entityName }) => currentPosition[entityName],
  )

  return (
    <header className={classes.root}>
      <SurBreadcrumbs
        {...{
          classes: {
            root: classes.container,
          },
          itemsAfterCollapse: 1,
          itemsBeforeCollapse: 1,
          separator: <Seprator />,
        }}
      >
        {currentRoutes?.map((route, index) => {
          if (index === currentRoutes.length - 1) {
            return (
              <div className={classNames(classes?.link, classes.last)}>
                {route.iconUri && <img src={route.iconUri} alt={route.label} />}
                {route.label}
              </div>
            )
          }
          return (
            <Link key={route.path} to={route.path} className={classes?.link}>
              {route.iconUri && <img src={route.iconUri} alt={route.label} />}

              {route.label}
            </Link>
          )
        })}
      </SurBreadcrumbs>
    </header>
  )
}

export default PageTemplateHeader
