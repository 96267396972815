import {
  GreyPalette,
  NeutralPalette,
  PrimaryPalette,
  SecondaryPalette,
  ErrorPalette,
  ISonbolPalette,
} from 'types/FloraMaidTheme'

//@ts-expect-error
const primary: PrimaryPalette = {
  main: '#95b6a7',
  '50': '#E3EDE9',
  '100': '#BCD2C9',
  '200': '#95b6a7',
  '300': '#709B87',
  '400': '#578871',
  '500': '#46765D',
  '600': '#406A54',
  '700': '#395B47',
  '800': '#304C3C',
  '900': '#213226',
}

//@ts-expect-error
const secondary: SecondaryPalette = {
  main: '#DE8165',
  '50': '#F4E7E6',
  '100': '#F0C7BB',
  '200': '#E6A38F',
  '300': '#DE8165',
  '400': '#D96846',
  '500': '#D74E26',
  '600': '#CD4923',
  '700': '#C0431E',
  '800': '#B23C1A',
  '900': '#9A3012',
  A300: '#FCF2EF',
}

//@ts-expect-error
const grey: GreyPalette = {
  main: '#282220',
  '50': '#FFF9F6',
  '100': '#FDF4F1',
  '200': '#F7EEEB',
  '300': '#E9E1DE',
  '400': '#C6BEBB',
  '500': '#A79F9D',
  '600': '#7E7674',
  '700': '#696260',
  '800': '#4A4341',
  '900': '#282220',
}

//@ts-expect-error
const neutral: NeutralPalette = {
  '50': '#EFF4F8',
  '100': '#D9E4EA',
  '200': '#C2D1DB',
  '300': '#A9BECB',
  '400': '#94ADBD',
  '500': '#809EB0',
  '600': '#728D9D',
  '700': '#617885',
  '800': '#52646F',
  '900': '#3F4D56',
}

//@ts-expect-error
const error: ErrorPalette = {
  '50': '#FFEBEF',
  '100': '#FFCDD4',
  '200': '#F39A9C',
  '300': '#EB7276',
  '400': '#F64F53',
  '500': '#FC3C39',
  '600': '#ED3238',
  '700': '#DB2732',
  '800': '#CE1F2B',
  '900': '#BF0C1E',
}

//@ts-expect-error
const palette: ISonbolPalette = {
  primary,
  secondary,
  error,
  grey,
  neutral,
}

export default palette
