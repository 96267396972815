import { createMuiTheme } from '@material-ui/core/styles'
import { FloraMaidThemeOptions } from 'types/FloraMaidTheme'
import palette from './palette'
import shadows from './shadows'
import transitions from './transitions'
import typography from './typography'

// const Roboto = {
//   fontFamily: 'Roboto',
//   fontStyle: 'normal',
//   fontDisplay: 'swap',
//   fontWeight: 400,
//   src: `url(${RobotoMonoRegular}) format("woff")`,
//   unicodeRange:
//     'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
// }

const FloraMaidTheme = createMuiTheme(({
  palette,
  shadows,
  typography,
  spacing: 4,
  shape: { borderRadius: 4 },
  transitions,
  zIndex: {},
  overrides: {
    MuiCssBaseline: {
      '@global': {
        h1: {
          fontWeight: 300,
          fontSize: '4rem',
          lineHeight: 1,
          letterSpacing: '-0.01562em',
        },
        h2: {
          fontWeight: 300,
          fontSize: '2rem',
          lineHeight: 1,
          letterSpacing: '-0.00833em',
        },
        h3: {
          fontWeight: 400,
          fontSize: '1.75rem',
          lineHeight: 1.04,
          letterSpacing: '0em',
        },
        h4: {
          fontWeight: 400,
          fontSize: '1.5rem',
          lineHeight: 1.17,
          letterSpacing: '0.00735em',
        },
        h5: {
          fontWeight: 400,
          fontSize: '1.25rem',
          lineHeight: 1.33,
          letterSpacing: '0em',
          margin: 0,
        },
        h6: {
          fontWeight: 500,
          fontSize: '1rem',
          lineHeight: 1.6,
          letterSpacing: '0.0075em',
        },
        button: {
          '&:focus': {
            outline: 0,
          },
        },
      },
    },
    MuiContainer: {
      root: {},
      fixed: {
        padding: 0,
        '@media (min-width: 720px)': {
          maxWidth: 696,
          padding: 12,
        },
        '@media (min-width: 1280px)': {
          maxWidth: 936,
          padding: 16,
        },
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },

    MuiOutlinedInput: {
      root: {
        height: 40,
        borderRadius: 3,
        '& .MuiOutlinedInput-input': {
          padding: '0px 14px',
          color: '#52646F',
          display: 'flex',
          '&::placeholder': {
            fontSize: '0.875rem',
            color: '#A9BECB',
          },
          '&.Mui-disabled': {
            color: '#C2D1DB',
          },
        },
        '&:hover': {
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#52646F',
          },
        },
        '&.Mui-disabled': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#C2D1DB',
          },
        },
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.primary[400],
          },
        },
      },

      notchedOutline: {
        borderColor: '#809EB0',
      },
      adornedEnd: {
        '& .endAdornmentContainer': {
          top: 0,
          right: 0,
          position: 'relative',
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          color: '#94ADBD',
          '& .endAdornmentElement,& img': {
            margin: '0 2.5px',
          },
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#809EB0',
        '&.Mui-error': {
          // color: "#52646F",
          color: '#CE1F2B',
        },
        '&.Mui-disabled': {
          color: '#C2D1DB',
        },
      },
      outlined: {
        transform: 'translate(14px, 12px) scale(1)',
        zIndex: 0,
      },
    },
    MuiTextField: {
      root: {
        // padding: '5px 5px 25px 5px',

        padding: '0, 8px',
        // marginBottom: 25,
      },
    },
    MuiFormHelperText: {
      root: {
        position: 'absolute',
        bottom: -23,
        left: 0,
        right: 0,
        color: '#A9BECB',
        display: 'flex',
        justifyContent: 'space-between',
        '&.Mui-error': {
          color: '#CE1F2B',
        },
      },
      contained: {
        marginLeft: 17,
        marginRight: 17,
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-error': {
          color: '#CE1F2B',
        },
        '&.Mui-focused': {
          color: palette.primary[400],
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.primary[400],
          },
        },
      },
    },
    MuiSwitch: {
      colorPrimary: {
        color: '#fff',
        '&$checked': {
          color: '#578871',
          '&$disabled': {
            color: '#bcd2c9 !important',
            '@global': {
              '+.MuiSwitch-track': {
                backgroundColor: '#e3ede9 !important',
              },
            },
          },
        },
        '&$disabled': {
          color: '#eff4f8 !important',
          '@global': {
            '+.MuiSwitch-track': {
              backgroundColor: '#d9e4ea !important',
              opacity: 'unset',
            },
          },
        },
      },
      colorSecondary: {
        color: '#fff',
        '&$checked': {
          color: '#d96846',
          '&$disabled': {
            color: '#f0c7bb !important',
            '@global': {
              '+.MuiSwitch-track': {
                backgroundColor: '#f4e7e6 !important',
              },
            },
          },
          '@global': {
            '+.MuiSwitch-track': {
              backgroundColor: '#e6a38f !important',
            },
          },
        },
        '&$disabled': {
          color: '#eff4f8 !important',
          '@global': {
            '+.MuiSwitch-track': {
              backgroundColor: '#d9e4ea !important',
              opacity: 'unset',
            },
          },
        },
      },

      track: {
        opacity: 'unset',
        backgroundColor: '#a9becb',
      },
    },
    MuiRadio: {
      colorPrimary: {
        '&$checked': {
          color: '#578871',
        },
      },
      root: {
        color: '#809eb0',
        '&$disabled': {
          color: '#c2d1db !important',
        },
        '@global': {
          '+.MuiFormControlLabel-label.Mui-disabled': {
            color: '#c2d1db',
          },
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        color: '#809eb0',
        fontSize: '16px',
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        color: '#809eb0',
        '&$checked': {
          color: '#578871',
          '&$disabled': {
            color: '#c2d1db !important',
          },
        },
        '&$disabled': {
          color: '#c2d1db !important',
          '@global': {
            '+.MuiFormControlLabel-label': {
              color: '#c2d1db',
            },
          },
        },
      },
      colorSecondary: {
        color: '#809eb0',
        '&$disabled': {
          color: '#c2d1db !important',
          '@global': {
            '+.MuiFormControlLabel-label': {
              color: '#c2d1db',
            },
          },
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 48,
        height: 48,
      },
    },
    MuiBadge: {
      colorPrimary: {
        color: '#ffffff',
        backgroundColor: '#578871',
        fontSize: 12,
      },
      colorSecondary: {
        color: '#ffffff',
        backgroundColor: '#4a4341',
        fontSize: 12,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#3F4D56',
        fontSize: 11,
        lineHeight: '16px',
      },
    },
    MuiSlider: {
      colorPrimary: {
        color: '#578871',

        '& .MuiSlider-valueLabel > span::before': {
          borderTop: '8px solid #578871',
        },
        '& .MuiSlider-rail': {
          backgroundColor: palette.primary[200],
        },
        '& .MuiSlider-mark': {
          backgroundColor: '#BCD2C9',
        },
      },
      colorSecondary: {
        color: '#D96846',

        '& .MuiSlider-valueLabel > span::before': {
          borderTop: '8px solid #D96846',
        },
        '& .MuiSlider-rail': {
          backgroundColor: palette.secondary[200],
        },
        '& .MuiSlider-mark': {
          backgroundColor: '#F0C7BB',
        },
      },
      rail: {
        color: '#E3EDE9',
        height: 3,
      },
      valueLabel: {
        left: 'calc(-50% - 6px)',
        top: -35,
        '&>span': {
          borderRadius: '50%',
          width: 36,
          height: 36,
          transform: 'unset',
          '&::before': {
            content: '""',
            width: '0px',
            height: '0px',
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            position: 'absolute',
            bottom: -4,
            zIndex: -1,
          },
          '&::after': {
            content: '""',
            width: 25,
            height: 25,
            border: '2px solid #fff',
            borderRadius: '50%',
            position: 'absolute',
          },
          '& span': {
            color: '#fff',
            transform: 'unset',
            fontSize: 10,
          },
        },
      },
      root: {
        '&.Mui-disabled,& .Mui-disabled': {
          color: '#C2D1DB',
          '& .MuiSlider-valueLabel': {
            left: 'calc(-50% - 10px)',
            '&>span::before': {
              borderTop: '8px solid #C2D1DB !important',
            },
          },
          '& .MuiSlider-rail,& .MuiSlider-track': {
            color: '#D9E4EA',
            opacity: 1,
          },
          '& .MuiSlider-mark': {
            backgroundColor: '#EFF4F8 !important',
          },
        },
      },
      mark: {
        width: 5,
        height: 5,
        position: 'absolute',
        borderRadius: '50%',
        marginTop: -1.5,
      },
      markActive: {
        backgroundColor: '#E3EDE9 !important',
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        '& .MuiAutocomplete-input': {
          height: 19,
        },
        "&[class*='MuiOutlinedInput-root']": {
          padding: 1,
        },
      },
      clearIndicator: {
        backgroundColor: palette.neutral[500],
        width: 12,
        height: 12,
        padding: 0,
        '&::before': {
          display: 'block',
          content: "''",
          backgroundImage:
            'url("data:image/svg+xml;base64,PHN2ZyBpZD0iamFzbWlqbl93ZWJfaWNvX2NsZWFyXzAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICA8cmVjdCBpZD0iY29udGFpbmVyIiB3aWR0aD0iOCIgaGVpZ2h0PSI4IiBmaWxsPSJub25lIi8+CiAgPHBhdGggaWQ9Imphc21pam5fd2ViX2ljb19jbGVhcl8wMS0yIiBkYXRhLW5hbWU9Imphc21pam5fd2ViX2ljb19jbGVhcl8wMSIgZD0iTTEyLDBBMTIsMTIsMCwxLDAsMjQsMTIsMTIuMDE0LDEyLjAxNCwwLDAsMCwxMiwwWm00Ljg1MywxNS40MzlhLjUuNSwwLDAsMSwwLC43MDdsLS43MDcuNzA3YS41LjUsMCwwLDEtLjcwNywwTDEyLDEzLjQxNCw4LjU2MSwxNi44NTNhLjUuNSwwLDAsMS0uNzA3LDBsLS43MDctLjcwN2EuNS41LDAsMCwxLDAtLjcwN0wxMC41ODYsMTIsNy4xNDcsOC41NjFhLjUuNSwwLDAsMSwwLS43MDdsLjcwNy0uNzA3YS41LjUsMCwwLDEsLjcwNywwTDEyLDEwLjU4NmwzLjQzOS0zLjQzOWEuNS41LDAsMCwxLC43MDcsMGwuNzA3LjcwN2EuNS41LDAsMCwxLDAsLjcwN0wxMy40MTQsMTJaIiBmaWxsPSIjNTI2NDZmIi8+Cjwvc3ZnPgo=")',
          backgroundSize: 'cover',
          height: '100%',
          width: '100%',
        },

        '& .MuiSvgIcon-root': {
          fill: '#fff',
        },
        '& .MuiIconButton-label': {
          display: 'none',
        },
      },
      option: {
        // Selected
        '&[aria-selected="true"]': {
          backgroundColor: 'transparent',
          borderColor: 'transparent',
        },
      },
      listbox: {
        '& li': {
          height: 36,
          padding: 0,
          color: palette.neutral[500],
          '&:hover': {
            backgroundColor: palette.neutral[50],
          },
          '& img': {
            width: 16,
            height: 16,
            margin: 8,
          },
          '& .MuiFormControlLabel-root': {
            margin: 0,
          },
        },
      },
    },
    MuiTable: {
      root: {
        border: `1px solid ${palette.neutral[50]}`,
      },
    },
    MuiTableBody: {
      root: {},
    },
    MuiTableSortLabel: {
      active: {},
    },
    MuiTableCell: {
      root: {
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        border: `0.5px solid ${palette.neutral[200]}`,
        '&.ColumnIsSorted': {
          backgroundColor: palette.primary[300],
        },
      },
      head: {
        flexDirection: 'row-reverse',
        justifyContent: 'center',
        borderColor: '#fff',
        '& th': {},
      },
      body: {
        '&:last-child': {
          justifyContent: 'center',
        },
      },
    },
    MuiTableRow: {
      root: {
        minHeight: 50,
        '&:nth-of-type(even)': {
          backgroundColor: palette.neutral[50],
        },
      },
      head: {
        backgroundColor: palette.primary[200],
        borderBottom: `1px solid ${palette.neutral[50]}`,
        '& th': {
          color: '#fff',
          '& .MuiSvgIcon-root': {
            '& path': {
              fill: '#fff',
            },
          },
        },
      },
      footer: {
        '& td': {
          justifyContent: 'flex-end',
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 720,
      lg: 1280,
      xl: 1920,
    },
  },
} as unknown) as FloraMaidThemeOptions)

export default FloraMaidTheme
