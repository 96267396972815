import { HttpLink, Operation } from '@apollo/client'
import uploadFetch from './uploader/xhr'

// eslint-disable-next-line no-shadow
export enum EndpointType {
  Cms = 'cms',
  Identity = 'identity',
  Main = 'main',
  Upload = 'upload',
  Price = 'price',
}

export const AppEndpoints: Record<EndpointType, HttpLink> = {
  main: new HttpLink({ uri: process.env.REACT_APP_GQL_MAIN_LINK }),
  cms: new HttpLink({ uri: process.env.REACT_APP_GQL_CMS_LINK }),
  identity: new HttpLink({ uri: process.env.REACT_APP_GQL_IDENTITY_LINK }),
  price: new HttpLink({ uri: process.env.REACT_APP_GQL_PRICE_LINK }),
  upload: new HttpLink({
    uri: process.env.REACT_APP_GQL_CMS_LINK,
    fetch: uploadFetch as any,
  }),
}

export const contextWithEndpoint = (endpoint: EndpointType, context?: any) => ({
  context: { ...context, endpoint },
})

export const checkContextForEndpoint = (endpoint: EndpointType) => (
  operation: Operation,
) => operation.getContext().endpoint === endpoint
