import {
  Log,
  UserManager,
  UserManagerEvents,
  UserManagerSettings,
} from 'oidc-client'
import { Constants } from './constants'

class AuthService {
  private _userGettingRefreshed = false

  private readonly _settings: UserManagerSettings = {
    authority: Constants.stsAuthority,
    client_id: Constants.clientId,
    redirect_uri: `${Constants.clientRoot}${Constants.redirectRoute}`,
    silent_redirect_uri: `${Constants.clientRoot}${Constants.silentRedirectRoute}`,
    post_logout_redirect_uri: Constants.clientRoot,
    response_type: 'code',
    scope: Constants.clientScope,
    client_secret: Constants.clientSecret,
    automaticSilentRenew: true,
  }
  private readonly _manager = new UserManager(this._settings)
  private _authenticationMethod: 'popup' | 'redirect' = 'redirect'

  constructor() {
    this._watchExpiring()
    this._manager.clearStaleState()

    Log.logger = console
    Log.level = Log.INFO
  }

  public getUser = () => this._manager.getUser()

  public login = () =>
    this._authenticationMethod === 'redirect'
      ? this._manager.signinRedirect()
      : this._manager.signinPopup()

  public loginCallback = () =>
    this._authenticationMethod === 'redirect'
      ? this._manager.signinRedirectCallback()
      : this._manager.signinPopupCallback()

  public renewToken = () => this._manager.signinSilent()

  public logout = () => this._manager.signoutRedirect()

  public addUserLoaded: UserManagerEvents['addUserLoaded'] = (cb) =>
    this._manager.events.addUserLoaded((user) => {
      console.log('addUserLoaded')

      return cb(user)
    })

  public addUserUnloaded: UserManagerEvents['addUserUnloaded'] = (cb) =>
    this._manager.events.addUserUnloaded(() => {
      console.log('addUserUnloaded')

      return cb()
    })

  private _watchExpiring = () => {
    this._manager.events.addAccessTokenExpiring(() => {
      if (this._userGettingRefreshed) {
        return
      }

      this._userGettingRefreshed = true
      this._manager
        .signinSilent()
        .catch(() => this._manager.removeUser())
        .finally(() => (this._userGettingRefreshed = false))
    })
    return this._userGettingRefreshed
  }
}

export default new AuthService()
