import React, { ComponentClass, FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useAuthContext } from '../Auth.context'
import { Constants } from '../constants'
import Login from './Login'
import SignInReturnCallBack from './SignInReturnCallBack'

export interface IAuthRouteProps {
  authenticatedRoutes: FC | ComponentClass
  defaultRoutes?: FC | ComponentClass
}

const AuthRoute: FC<IAuthRouteProps> = ({
  defaultRoutes: DefaultRoutes,
  authenticatedRoutes: AuthenticatedRoutes,
}) => {
  const { isAuthenticated } = useAuthContext()

  return (
    <Switch>
      <Route path={Constants.redirectRoute}>
        <SignInReturnCallBack />
      </Route>
      {DefaultRoutes && <DefaultRoutes />}
      <Route path="*">
        {isAuthenticated ? <AuthenticatedRoutes /> : <Login />}
      </Route>
    </Switch>
  )
}

export default AuthRoute
