import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import { SvgLoader } from '@sur-ui/core'
import { ReactComponent as FullLogo } from 'assets/svg/FullLogo.svg'
import { ReactComponent as Logo } from 'assets/svg/Logo.svg'
import { useAppContext } from 'domains/App'
import React, { memo } from 'react'
import { NavLink } from 'react-router-dom'
import useDefaultLayout from './DefaultLayout.biz'
import { DefaultLayoutProvider } from './DefaultLayout.context'
import useStyle from './DefaultLayout.styles'
import HeaderNotification from './HeaderNotification'
import LanguageSelector from './LanguageSelector'
import TotalSearch from './TotalSearch'
import UserAvatar from './UserAvatar'

interface IProp {
  // header?: React.ReactNode
  // sider?: React.ReactNode
  children?: React.ReactNode
  // isCollapsed?: boolean
}

const DefaultLayout = ({
  // header, sider, isCollapsed,
  children,
}: IProp) => {
  const classes = useStyle()
  const { navs } = useAppContext()
  const groupedNavs = navs.reduce((r: any, a) => {
    r[a.uIResourceType] = [...(r[a.uIResourceType] || []), a]
    return r
  }, {})

  const value = useDefaultLayout()

  return (
    <DefaultLayoutProvider {...{ value }}>
      <Box component="main" className={classes.root}>
        <header className={classes.AppBar}>
          <Box component="div" className={classes.logo}>
            <FullLogo />
          </Box>
          <Box component="div" className={classes.topMenuContainer}>
            <TotalSearch />
            <Box component="div" className={classes.endAppBar}>
              <LanguageSelector />
              <HeaderNotification />
              <UserAvatar />
            </Box>
          </Box>
        </header>
        <Box component="main" className={classes.container}>
          <aside className={classes.sidebarContainer}>
            <Drawer
              variant="permanent"
              classes={{
                root: classes.drawerRoot,
                paper: classes.drawerPaper,
              }}
            >
              <List>
                {
                  Object.keys(groupedNavs).map((keyName, i) => (
                    <div key={i}>
                      {i > 0 && <hr className={classes.devider} />}
                      {groupedNavs[keyName].map((nav: INavItem) => (
                        <ListItem
                          key={nav.id}
                          classes={{
                            root: classes.navItemsRoot,
                            gutters: classes.navItemsGutters,
                            selected: classes.navItemsSelected,
                          }}
                          button
                          component={NavLink}
                          activeClassName={classes.activeRoute}
                          to={nav.path ?? '/'}
                        >
                          <ListItemIcon classes={{ root: classes.navIcons }}>
                            {nav.iconUri ? (
                              <SvgLoader src={nav.iconUri} />
                            ) : (
                              <Logo />
                            )}
                          </ListItemIcon>
                          <ListItemText primary={nav.label} />
                        </ListItem>
                      ))}
                    </div>
                  ))

                  // navs.map((nav) => (
                  //   <ListItem
                  //     key={nav.id}
                  //     classes={{
                  //       root: classes.navItemsRoot,
                  //       gutters: classes.navItemsGutters,
                  //       selected: classes.navItemsSelected,
                  //     }}
                  //     button
                  //     component={NavLink}
                  //     activeClassName={classes.activeRoute}
                  //     to={nav.path ?? '/'}
                  //   >
                  //     <ListItemIcon classes={{ root: classes.navIcons }}>
                  //       {nav.iconUri ? <SvgLoadr src={nav.iconUri} /> : <Logo />}
                  //     </ListItemIcon>
                  //     <ListItemText primary={nav.label} />
                  //   </ListItem>
                  // ))
                }
              </List>
            </Drawer>
          </aside>
          <main className={classes.content}>{children}</main>
        </Box>
      </Box>
    </DefaultLayoutProvider>
  )
}
export default memo(DefaultLayout)
