import React, { createContext, FC, useContext } from 'react'
import { Language, Person } from 'types/serverTypes'

export interface IDefaultLayoutContext {
  currentLanguage?: any
  userProfile?: Person
  languages?: Language[]
  handleUserLanguageChange(item: Language): void
}

interface IDefaultLayoutProviderProps {
  children: any
  value: IDefaultLayoutContext
}

const DefaultLayoutContext = createContext<IDefaultLayoutContext>({
  currentLanguage: undefined,
  handleUserLanguageChange: () => {},
})

export const useDefaultLayoutContext = () =>
  useContext<IDefaultLayoutContext>(DefaultLayoutContext)

export const DefaultLayoutProvider: FC<IDefaultLayoutProviderProps> = (
  props,
) => <DefaultLayoutContext.Provider {...props} />
