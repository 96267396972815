import {
  useChangeLanguage,
  useGetUserLanguages,
  useGetUserProfile,
  useUpdateLocaleResources,
} from 'domains/App/gql/hooks'
import { IDefaultLayoutContext } from './DefaultLayout.context'

const useDefaultLayout: () => IDefaultLayoutContext = () => {
  const { handleUserLanguageChange } = useChangeLanguage()
  const { languages } = useGetUserLanguages()
  const { userProfile, currentLanguageId } = useGetUserProfile()
  const currentLanguage = languages.find(
    (item) => item.id === currentLanguageId,
  )

  useUpdateLocaleResources(currentLanguage)

  return {
    userProfile,
    currentLanguage,
    languages,
    handleUserLanguageChange,
  }
}

export default useDefaultLayout
