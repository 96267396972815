import { Person } from 'types/serverTypes'
import { useGetPersonQuery } from '../query.generated'

// eslint-disable-next-line import/prefer-default-export
export const useGetUserProfile = () => {
  const { data } = useGetPersonQuery()
  const userProfile = data?.profile?.listDto?.items?.[0] as Person
  const currentLanguageId = userProfile?.personSetting?.languageId

  return { userProfile, currentLanguageId }
}
