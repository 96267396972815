import { contextWithEndpoint, EndpointType } from 'core/apollo/links'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Country } from 'types/serverTypes'
import {
  array as YupArray,
  object as YupObject,
  string as YupString,
} from 'yup'
import { useGeneralScreenContext } from '../../GeneralScreen.context'
import { useGetCountriesInCreateCurrencyQuery } from '../../GeneralScreen.queries.generated'
import currencyTabMessages from '../CurrencyTab.messages'
import {
  CuerrencyEditModalProps,
  CurrencyModalValues,
} from '../CurrencyTab.types'

const useCurrencyModal = ({ modalData, isOpen }: CuerrencyEditModalProps) => {
  const { formatMessage } = useIntl()
  const {
    currencies,
    getCurrenciesList,
    currenciesLoading,
  } = useGeneralScreenContext()
  // const [currencySelector, setCurrencySelector] = useState<Currency[]>()
  // const [countriesSelector, setCountriesSelector] = useState<Language[]>()

  const {
    data: countries,
    loading: countriesLoading,
    refetch: createCountriesRefetch,
  } = useGetCountriesInCreateCurrencyQuery({
    ...contextWithEndpoint(EndpointType.Price),
  })

  useEffect(() => {
    if (!isOpen || !modalData) return
    getCurrenciesList()
    createCountriesRefetch?.()
  }, [createCountriesRefetch, getCurrenciesList, isOpen, modalData])

  const selectCountriesOptions = {
    disabled: false,
    labelExp: 'name',
    valueExp: 'id',
    iconExp: 'flagUrl',
    disableExp: 'disabled',
    multiple: true,
    isLoading: countriesLoading,
    disableCloseOnSelect: true,
  }
  const selectCountriesInputProps = {
    name: 'country',
    label: formatMessage(currencyTabMessages.modalFormCountryLabel),
  }
  const selectCurrencyOptions = {
    disabled: false,
    labelExp: 'name',
    valueExp: 'id',
    iconExp: 'slug',
    disableExp: 'disabled',
    multiple: false,
    isLoading: currenciesLoading,
    disableCloseOnSelect: false,
  }
  const selectCurrencyInputProps = {
    name: 'currency',
    label: formatMessage(currencyTabMessages.modalFormCurrencyLabel),
  }

  const initialValues: CurrencyModalValues = {
    selectCurrency: modalData?.row ?? undefined,
    selectCountries: (modalData?.row?.usedInCountryDtos as any) ?? [],
  }
  const validationSchema = YupObject().shape({
    selectCurrency: YupString().required(),
    selectCountries: YupArray().test(
      'isNotEmpty',
      formatMessage(currencyTabMessages.modalFormCountryValidation),
      (val: any) => val && val.length > 0,
    ),
  })

  const countryList =
    modalData?.status === 'edit'
      ? modalData.row?.usedInCountryDtos?.concat(
          countries?.countriesInCreateCurrency?.listDto?.items as Country[],
        )
      : countries?.countriesInCreateCurrency?.listDto?.items

  return {
    formatMessage,
    selectCountriesOptions,
    selectCountriesInputProps,
    selectCurrencyOptions,
    selectCurrencyInputProps,
    initialValues,
    validationSchema,
    countries: countryList,
    currencies: currencies?.filter(
      (currency) => !currency.usedInCountryDtos?.length,
    ),
    countriesLoading,
  }
}

export default useCurrencyModal
