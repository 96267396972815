import { useAppContext } from 'domains/App'
import { authService } from 'domains/Auth'
import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const SignInReturnCallBack: FC = () => {
  const { replace } = useHistory()
  const { redirectRoutes } = useAppContext()

  useEffect(() => {
    authService.loginCallback().finally(() => replace(redirectRoutes.signIn))
  }, [redirectRoutes.signIn, replace])

  return (
    <div>
      <h1>Please Wait...</h1>
    </div>
  )
}

export default SignInReturnCallBack
