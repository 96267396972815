import { defineMessages } from 'react-intl'

const scope = 'app.layout.default.avatar'

const UserAvatarMessages = defineMessages({
  profile: {
    id: `${scope}.profile`,
    defaultMessage: 'Profile',
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: 'Logout',
  },
  changePassword: {
    id: `${scope}.changePassword`,
    defaultMessage: 'Change Password',
  },
})
export default UserAvatarMessages
