import { ApolloProvider } from '@apollo/client'
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import client from 'core/apollo/client'
import FloraMaidTheme from 'theme'
import React, { Children, FC, memo, ReactNode } from 'react'
import LanguageProvider from './LanguageProvider'
import { SurToastContainer } from '@sur-ui/core'

interface IProvidersProps {
  children: ReactNode
}

const Providers: FC<IProvidersProps> = ({ children }) => (
  <ApolloProvider {...{ client }}>
    <SurToastContainer />
    <LanguageProvider>
      <ThemeProvider theme={FloraMaidTheme}>
        <CssBaseline />
        {Children.only(children)}
      </ThemeProvider>
    </LanguageProvider>
  </ApolloProvider>
)

export default memo(Providers)
