export class Constants {
  public static stsAuthority = process.env.REACT_APP_STS_AUTHORITY
  public static clientId = process.env.REACT_APP_CLIENT_ID
  public static clientRoot = process.env.REACT_APP_CLIENT_ROOT
  public static clientScope = process.env.REACT_APP_CLIENT_SCOPE
  public static clientSecret = process.env.REACT_APP_CLIENT_SECRET
  public static apiRoot = process.env.REACT_APP_API_ROOT
  public static redirectRoute = '/signin-callback'
  public static silentRedirectRoute = '/silent-renew.html'
}
