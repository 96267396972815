import { useMediaQuery } from './useMediaQuery'

export interface IScreenWidth {
  narrow: boolean
  mid: boolean
  wide: boolean
}

const breakpoints = {
  sm: 768,
  xl: 1280,
}

export const useScreenWidth: () => IScreenWidth = () => {
  const narrow = useMediaQuery(`(max-width: ${breakpoints.sm}px)`)
  const wide = useMediaQuery(`(min-width: ${breakpoints.xl}px)`)

  return {
    narrow,
    wide,
    mid: !(narrow || wide),
  }
}
