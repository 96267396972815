import { makeFloraMaidStyles } from 'theme'

export default makeFloraMaidStyles(({ spacing }) => ({
  root: {
    margin: spacing(0, 8, 0, 4),
  },
  button: {
    borderRadius: 99,
    padding: spacing(1),
  },
  badge: {
    border: '2px solid #fff',
    height: 21,
  },
}))
