import { Typography } from '@material-ui/core'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import useStyle from './PageTemplate.styles'

const PageMenuItem: FC<INavItem> = ({ label, iconUri, path }) => {
  const classes = useStyle()
  return (
    <Link to={path} className={classes.menuItem} data-cy={`menuItem-${label}`}>
      {iconUri && <img src={iconUri} alt={label} />}
      <Typography component="h5" variant="h5">
        {label}
      </Typography>
      <Typography>View and update your store details</Typography>
    </Link>
  )
}

export default PageMenuItem
