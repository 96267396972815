import { ApolloClient, useApolloClient } from '@apollo/client'
import { contextWithEndpoint, EndpointType } from 'core/apollo/links'
import useWatchLocale from 'core/helpers/locale'
import { useCallback, useMemo } from 'react'
import {
  GetPermissionTitlesQuery,
  useGetPermissionsLazyQuery,
  useGetPermissionTitlesLazyQuery,
} from '../query.generated'

const onGetTitlesCompleted = (
  client: ApolloClient<any>,
  data: GetPermissionTitlesQuery,
): void => {
  data.permissions?.listDto?.items?.forEach((_: any) => {
    client.cache.modify({
      id: client.cache.identify(_),
      fields: {
        title: () => _.title as string,
      },
    })
  })
}
// eslint-disable-next-line import/prefer-default-export
export const useGetPermissions = () => {
  const client = useApolloClient()
  const [
    getPermissions,
    { data: identityResources, loading: loadingIdentityResources },
  ] = useGetPermissionsLazyQuery(contextWithEndpoint(EndpointType.Identity))

  const [getPermissionTitles] = useGetPermissionTitlesLazyQuery(
    useMemo(
      () => ({
        fetchPolicy: 'network-only',
        onCompleted: onGetTitlesCompleted.bind(null, client),
      }),
      [client],
    ),
  )

  useWatchLocale(
    useCallback(() => getPermissionTitles(), [getPermissionTitles]),
  )

  return useMemo(
    () => ({
      getPermissions,
      identityResources,
      loadingIdentityResources,
    }),
    [getPermissions, identityResources, loadingIdentityResources],
  )
}
