import { useApolloClient } from '@apollo/client'
import { LANGUAGE_QUERY } from 'core/apollo/generalQueries'
import { Maybe } from 'graphql/jsutils/Maybe'
import { useEffect } from 'react'
import { from } from 'zen-observable'

const useWatchLocale = (callback: (data: LocalizationType) => void) => {
  const client = useApolloClient()

  useEffect(() => {
    const watcher$ = from(
      client.watchQuery<Maybe<LocalizationType>>({
        query: LANGUAGE_QUERY,
      }),
    ).filter(({ data }) => !!data?.locale)

    const localeSubscription = watcher$.subscribe(({ data }) => {
      callback(data as LocalizationType)
    })
    return () => localeSubscription.unsubscribe()
  }, [callback, client])
}

export default useWatchLocale
