import React from 'react'
import App from './App'
import Providers from './components/Providers/Providers'

export { useAppContext } from './App.context'

export default () => (
  <Providers>
    <App />
  </Providers>
)
