import { setContext } from '@apollo/client/link/context'
import client from './client'
import { TOKEN_QUERY } from './generalQueries'

const authLink = setContext((_, { headers }) => {
  try {
    // get the authentication token from local storage if it exists
    const data = client.readQuery({ query: TOKEN_QUERY })
    // return the headers to the context so httpLink can read them

    return {
      headers: {
        ...headers,
        authorization: data.access_token ? `Bearer ${data.access_token}` : '',
      },
    }
  } catch (e) {
    return { headers }
  }
})
export default authLink
