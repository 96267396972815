import { IScreenWidth } from 'core/hooks/mediaQuery'
import React, { createContext, FC, useContext } from 'react'
import { RouteProps } from 'react-router-dom'

export interface IAppContext {
  redirectRoutes: {
    notFound: string
    signIn: string
  }
  loadingIdentityResources: boolean
  routes: RouteProps[]
  navs: INavItem[]
  screenWidth: IScreenWidth
  getPermissions?: any
  pageLoading: boolean
  setPageLoading(pageLoading: boolean): void
}

interface IAppProviderProps {
  children: any
  value: IAppContext
}

const AppContext = createContext<IAppContext>({
  loadingIdentityResources: true,
  routes: [],
  navs: [],
  screenWidth: {} as IScreenWidth,
  redirectRoutes: {
    notFound: '/',
    signIn: '/',
  },
  pageLoading: false,
  setPageLoading: () => {},
})

export const useAppContext = () => useContext<IAppContext>(AppContext)

export const AppProvider: FC<IAppProviderProps> = (props) => (
  <AppContext.Provider {...props} />
)
