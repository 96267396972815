import React, { FC } from 'react'
import useAuth from './Auth.biz'
import { AuthContextProvider } from './Auth.context'
import { AuthRoutes } from './components'
import { IAuthRouteProps } from './components/AuthRoutes'

export interface IAuthProps extends IAuthRouteProps {
  signInRedirectRoute: string
}

const Auth: FC<IAuthProps> = (props) => {
  const {
    defaultRoutes,
    authenticatedRoutes,
    contextValue: value,
    loading,
  } = useAuth(props)

  if (loading) return <div>Loading ...</div>

  return (
    <AuthContextProvider {...{ value }}>
      <AuthRoutes {...{ authenticatedRoutes, defaultRoutes }} />
    </AuthContextProvider>
  )
}

export default Auth
