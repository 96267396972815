import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useAppContext } from '../../App.context'

const NotFound: FC = () => {
  const { loadingIdentityResources, redirectRoutes } = useAppContext()

  return loadingIdentityResources ? null : (
    <div>
      <h1>Page NotFound</h1>
      <Link to={redirectRoutes.notFound}>Back</Link>
    </div>
  )
}

export default NotFound
