import PagesTemplate from 'domains/App/components/Layouts/DefaultLayout/PageTemplate'
import { useSettingContext } from 'domains/Setting/Setting.context'
import React from 'react'

const SettingScreen = () => {
  const { breadCrumbs, navs } = useSettingContext()
  return (
    <PagesTemplate
      breadcrumbs={{
        routes: breadCrumbs,
        currentAttribute: 'setting',
      }}
      {...{ data: navs }}
    />
  )
}
export default SettingScreen
