import { SurToast } from '@sur-ui/core'
import { ReactComponent as LanguageNotPublishedIcon } from 'assets/svg/LanguageNotPublishedIcon.svg'
import { ReactComponent as LanguagePublishedIcon } from 'assets/svg/LanguagePublishedIcon.svg'
import { contextWithEndpoint, EndpointType } from 'core/apollo/links'
import { useGetUserLanguages } from 'domains/App/gql/hooks'
import { map, pipe, values } from 'ramda'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Country, Language } from 'types/serverTypes'
import { useGeneralScreenContext } from '../GeneralScreen.context'
import {
  useChangeDefaultLanguageMutation,
  useCreateLanguageMutation,
  useDeleteLanguageMutation,
  useUpdateLanguageMutation,
} from '../GeneralScreen.mutations.generated'
import messages from './LanguageTab.messages'
import { LanguageModalData, LanguageModalSubmit } from './LanguageTab.types'

const useLanguageTab = () => {
  const [createLanguage] = useCreateLanguageMutation({
    ...contextWithEndpoint(EndpointType.Cms),
  })
  const [updateLanguage] = useUpdateLanguageMutation({
    ...contextWithEndpoint(EndpointType.Cms),
  })
  const [deleteLanguage] = useDeleteLanguageMutation({
    ...contextWithEndpoint(EndpointType.Cms),
  })

  const [changeDefaultLanguage] = useChangeDefaultLanguageMutation({
    ...contextWithEndpoint(EndpointType.Cms),
  })
  const { formatMessage } = useIntl()
  const {
    languages,
    languageLoading,
    getLanguagesList,
  } = useGeneralScreenContext()
  const { refetchLanguage } = useGetUserLanguages()
  const defaultLanguage = languages?.find((item) => item.isDefault)
  const [primaryLanguageValue, setPrimaryLanguageValue] = useState<
  Language | undefined
  >()
  const [modalData, setModalData] = useState<LanguageModalData | undefined>(
    undefined,
  )

  useEffect(() => {
    getLanguagesList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setPrimaryLanguageValue(defaultLanguage)
  }, [defaultLanguage])

  const primaryLanguageOptions = {
    disabled: false,
    labelExp: 'countryLanguageDto.name',
    valueExp: 'id',
    iconExp: 'flagUrl',
    disableExp: 'disabled',
    multiple: false,
    isLoading: false,
    disableCloseOnSelect: true,
  }
  const primaryLanguageInputProps = {
    name: 'primaryLanguage',
    label: formatMessage(messages.primaryLanguageTitle),
    // placeholder: 'Placeholder Text',
  }
  const columns = React.useMemo(
    () => [
      {
        Header: formatMessage(messages.gridHeaderTitle),
        accessor: 'countryLanguageDto.name', // accessor is the "key" in the data
        sortable: true,
        Cell: (props: any) => (
          <div
            style={{ display: 'flex', alignItems: 'center', padding: '0 12px' }}
          >
            <img
              style={{ height: 24, margin: '0 10px' }}
              src={props.row.original?.flagUrl ?? ''}
              alt={props.value}
            />
            {props.value}
          </div>
        ),
      },
      {
        Header: formatMessage(messages.gridHeaderPublished),
        accessor: 'published',
        Cell: (props: any) => (
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            {props.value ? (
              <LanguagePublishedIcon width={20} height={20} />
            ) : (
              <LanguageNotPublishedIcon width={20} height={20} />
            )}
          </div>
        ),
      },
    ],
    [formatMessage],
  )

  const handleSetPrimaryLanguage = () => {
    changeDefaultLanguage({
      variables: {
        language: {
          dto: {
            id: primaryLanguageValue?.id,
            isDefault: true,
          },
        },
      },
    }).then(() => {
      SurToast(formatMessage(messages.changePrimaryLanguageToastContent), {})
      refetchLanguage?.()
    })
  }

  const onSubmit: LanguageModalSubmit = (
    id,
    { selectLanguage, selectCountries, published, languageUploader },
  ) => {
    const fileUrl = values(languageUploader)[0].url
    const usedInCountryDtos = map(pipe((item: Country) => ({ id: item?.id })))(
      selectCountries,
    )
    if (id) {
      updateLanguage({
        variables: {
          language: {
            includes: ['UsedInCountries', 'CountryLanguage'],
            dto: {
              id,
              published,
              fileUrl,
              usedInCountryDtos,
              countryLanguageDto: {
                id: selectLanguage!.id,
              },
            },
          },
        },
      }).then(() => {
        refetchLanguage?.()
        getLanguagesList()
        setModalData(undefined)
        SurToast(formatMessage(messages.updateLangugeToastContent), {})
      })
    } else {
      createLanguage({
        variables: {
          language: {
            includes: ['UsedInCountries', 'CountryLanguage'],
            dto: {
              usedInCountryDtos,
              published,
              fileUrl,
              countryLanguageDto: {
                id: selectLanguage!.id,
              },
            },
          },
        },
      }).then(() => {
        refetchLanguage?.()
        getLanguagesList()
        setModalData(undefined)
        SurToast(formatMessage(messages.createLangugeToastContent), {})
      })
    }
  }

  const handleDeleteLanguage = (rawRow: string[] | number[]): void => {
    deleteLanguage({
      variables: { language: { ids: rawRow } },
    }).then(() => {
      getLanguagesList()
      refetchLanguage?.()
      SurToast(formatMessage(messages.deleteLangugeToastContent), {})
    })
  }
  const handleEditLanguage = (row: any) => {
    setModalData({ row, status: 'edit' })
  }
  const handleDownloadLanguageTemplate = () => {}
  const handleAddLanguageClick = () => setModalData({ status: 'add' })

  return {
    modalData,
    primaryLanguageOptions,
    primaryLanguageInputProps,
    columns,
    languages,
    languageLoading,
    defaultLanguage,
    primaryLanguageValue,
    handleDeleteLanguage,
    handleEditLanguage,
    handleDownloadLanguageTemplate,
    handleAddLanguageClick,
    handleSetPrimaryLanguage,
    setPrimaryLanguageValue,
    setModalData,
    formatMessage,
    onSubmit,
  }
}

export default useLanguageTab
