import { useAppContext } from 'domains/App/App.context'
import { RouteComponentProps, RouteProps } from 'react-router-dom'
import GeneralScreen from './pages/GeneralScreen'

// eslint-disable-next-line no-shadow
enum EntityType {
  GeneralEntity = 'general',
  FinancialEntity = 'financial',
  GiftcardsEntity = 'giftcards',
  LocationZonesEntity = 'location&zones',
  UsersPermissionsEntity = 'users&permissions',
  ThemesEntity = 'themes',
  NotificationsEntity = 'notifications',
  FormsEntity = 'forms',
}

const entities: Record<EntityType, React.ComponentType<any>> = {
  [EntityType.GeneralEntity]: GeneralScreen,
  [EntityType.FinancialEntity]: GeneralScreen,
  [EntityType.GiftcardsEntity]: GeneralScreen,
  [EntityType.LocationZonesEntity]: GeneralScreen,
  [EntityType.UsersPermissionsEntity]: GeneralScreen,
  [EntityType.ThemesEntity]: GeneralScreen,
  [EntityType.NotificationsEntity]: GeneralScreen,
  [EntityType.FormsEntity]: GeneralScreen,
}

const useSetting = ({ match }: RouteComponentProps) => {
  const { navs: rootNavs } = useAppContext()
  const currentNav = rootNavs.find(({ path }) => path === match.path)
  const allNavChilds = currentNav?.children

  const routes = allNavChilds
    ?.map(
      ({ entityName, path }) =>
        ({
          path,
          component: entities[entityName as EntityType],
        } as RouteProps),
    )
    .filter(({ component }) => !!component)

  const navs = allNavChilds?.filter(
    ({ entityName }) => entities[entityName as EntityType],
  )

  const breadCrumbs = currentNav?.children && [
    { ...currentNav, children: [] },
    ...currentNav?.children,
  ]

  // console.log(routes, 'useSetting')
  return { navs, breadCrumbs, routes }
}

export default useSetting
