import { useApolloClient } from '@apollo/client'
import {
  LANGUAGE_QUERY,
  LANGUAGE_MESSEGES_QUERY,
} from 'core/apollo/generalQueries'
import { contextWithEndpoint, EndpointType } from 'core/apollo/links'
import { useCallback, useEffect } from 'react'
import { Language, LanguageResource } from 'types/serverTypes'
import { useCreateCompanyMutation } from '../mutation.generated'
import { useGetUserLanguagesLazyQuery } from '../query.generated'
import useWatchLocale from 'core/helpers/locale'
import { useAppContext } from 'domains/App/App.context'

export const useGetUserLanguages = () => {
  const { setPageLoading } = useAppContext()
  const [
    getUserLanguages,
    { data, loading, refetch: refetchLanguage },
  ] = useGetUserLanguagesLazyQuery({
    fetchPolicy: 'network-only',
    ...contextWithEndpoint(EndpointType.Cms),
  })
  useEffect(() => {
    if (loading) setPageLoading(loading)
  }, [loading, setPageLoading])

  useEffect(() => {
    getUserLanguages({ variables: { all: true } })
  }, [getUserLanguages])
  useWatchLocale(
    useCallback(() => getUserLanguages({ variables: { all: true } }), [
      getUserLanguages,
    ]),
  )

  return {
    languages: (data?.userLanguages?.listDto?.items ?? []) as Language[],
    refetchLanguage,
  }
}

export const useUpdateLocaleResources = (currentLanguage?: Language) => {
  const client = useApolloClient()

  const locale = currentLanguage?.slug
  const rawMessages = currentLanguage?.languageResources as LanguageResource[]

  useEffect(() => {
    if (!rawMessages?.length) return

    const items = rawMessages.map((item) => [item.key, item.value])
    const messages: Record<string, string> = Object.fromEntries(items)

    client.writeQuery({
      query: LANGUAGE_MESSEGES_QUERY,
      data: { locale, messages },
    })
  }, [client, locale, rawMessages])
}

export const useWatchLoacaleResources = (
  callback: (data: LocalizationType) => void,
) => {
  const client = useApolloClient()
  useEffect(() => {
    client
      .watchQuery<LocalizationType>({ query: LANGUAGE_MESSEGES_QUERY })
      .subscribe(({ data }) => data && callback(data))
  }, [callback, client])
}

export const useChangeLanguage = () => {
  const [onUserLanguageChange] = useCreateCompanyMutation()
  const client = useApolloClient()
  const handleUserLanguageChange = useCallback(
    ({ id: languageId, slug: locale }: Language) => {
      onUserLanguageChange?.({
        variables: {
          arg: {
            dto: {
              personSetting: {
                languageId,
                languageSlug: locale,
              },
            },
          },
        },
      }).then(() => {
        client.writeQuery({
          query: LANGUAGE_QUERY,
          data: { locale },
        })
      })
    },
    [client, onUserLanguageChange],
  )

  return { handleUserLanguageChange }
}
