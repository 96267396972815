import { useApolloClient } from '@apollo/client'
import { TOKEN_QUERY } from 'core/apollo/generalQueries'
import { User } from 'oidc-client'
import { useCallback, useEffect, useState } from 'react'
import { IAuthProps } from './Auth'
import { IAuthContext } from './Auth.context'
import authService from './Auth.service'

type IUseAuth = (
  props: IAuthProps,
) => Pick<IAuthProps, 'authenticatedRoutes' | 'defaultRoutes'> & {
  loading: boolean
  contextValue: IAuthContext
}

const useAuth: IUseAuth = ({ signInRedirectRoute, ...rest }) => {
  const client = useApolloClient()
  const [loading, setLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(true)
  const setToken = useCallback(
    (user?: User | null): void => {
      client.writeQuery({
        query: TOKEN_QUERY,
        data: { access_token: user?.access_token ?? undefined },
      })
      setIsAuthenticated(!!user)
    },
    [client],
  )

  useEffect(() => {
    setLoading(true)
    authService
      .getUser()
      .then(setToken)
      .finally(() => setLoading(false))

    authService.addUserLoaded(setToken)
    authService.addUserUnloaded(setToken)
  }, [setToken])

  return {
    ...rest,
    loading,
    contextValue: { isAuthenticated },
  }
}

export default useAuth
