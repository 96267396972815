import React, { createContext, FC, useContext } from 'react'
import { RouteProps } from 'react-router-dom'

export interface ISettingContext {
  navs: INavItem[] | undefined
  breadCrumbs: INavItem[] | undefined
  routes: RouteProps[] | undefined
}

interface ISettingProviderProps {
  children: any
  value: ISettingContext
}

const SettingContext = createContext<ISettingContext>({
  routes: [],
  navs: [],
  breadCrumbs: [],
})

export const useSettingContext = () =>
  useContext<ISettingContext>(SettingContext)

export const SettingProvider: FC<ISettingProviderProps> = ({
  children,
  value,
}) => (
  <SettingContext.Provider {...{ value }}>{children}</SettingContext.Provider>
)
