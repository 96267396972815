import { makeFloraMaidStyles } from 'theme'

const useLanguageTabStyle = makeFloraMaidStyles(
  ({ palette: { neutral, primary }, spacing, breakpoints }) => ({
    root: {
      flex: 1,
      height: '100%',
    },
    languageTab: {
      position: 'relative',
      display: 'flex',
      height: '100%',
      padding: 0,
      [breakpoints.down('md')]: {
        flexWrap: 'wrap-reverse',
        paddingBottom: spacing(15),
      },
    },
    addBtnContainer: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      left: 0,
      color: '#fff',
      display: 'flex',
      flexDirection: 'row-reverse',
      '& button': {
        flex: 1,
        height: 38,
        color: '#fff',
        margin: '0 5px',
      },
      '& .downloadLanguageTemplateBtn': {
        color: '#fff',
        '& #uploaderDownloadIcon': {
          '& path': {
            fill: '#fff',
          },
        },
      },
    },
    languagesList: {
      flex: 1,
      height: '100%',
      padding: spacing(0, 2),
    },
    primaryLanguage: {
      padding: spacing(4, 8),
      border: `1px solid ${neutral[200]}`,
      margin: spacing(0, 2),
      '& .MuiTypography-root': {
        padding: spacing(0, 2),
      },
      minHeight: 200,
      height: 'fit-content',
      [breakpoints.down('md')]: {
        marginBottom: spacing(5),
      },
    },
    primaryLanguageTitle: {
      marginBottom: spacing(5),
    },
    primaryLanguageText: {
      margin: spacing(3, 0, 6, 0),
      lineHeight: 1.5,
    },
    primaryLanguageSelector: {
      display: 'flex',
      alignItems: 'flex-start',
      '& .MuiTextField-root': {
        padding: spacing(0, 2),
      },
    },
    setPrimaryLanguageButton: {
      height: 38,
    },
    setPrimaryLanguageInput: {
      flex: 1,
      width: '100%',
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(22px, -6px) scale(0.75)',
      },
    },
    modal: {},
    modalActionsContainer: {
      position: 'absolute',
      right: 33,
      bottom: 63,
    },
    autoCompletes: {
      flex: 1,
      margin: 15,
    },
    footer: {
      position: 'fixed',
      bottom: 0,
      left: 336,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '15px 0',
      marginTop: 31,
      height: 48,
      '& a': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 48,
        backgroundColor: primary[50],
        borderRadius: 3,
        padding: '0 14px',
        textDecoration: 'none',
        color: neutral[900],
      },
    },
  }),
  { name: 'GeneralScreen-LanguageTab' },
)

export default useLanguageTabStyle
