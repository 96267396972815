import { Box } from '@material-ui/core'
import {
  SurAutoCompleteField,
  SurButton,
  SurForm,
  SurModal,
  SurTextField,
  SurTooltip,
} from '@sur-ui/core'
import { ReactComponent as HelpTooltipIcon } from 'assets/svg/HelpTooltipIcon.svg'
import React, { FC } from 'react'
import { Country } from 'types/serverTypes'
import currencyTabMessages from '../CurrencyTab.messages'
import useCuerrencyTabStyle from '../CurrencyTab.style'
import { CuerrencyEditModalProps } from '../CurrencyTab.types'
import useCurrencyModal from './CurrencyModal.biz'

const CuerrencyEditModal: FC<CuerrencyEditModalProps> = (props) => {
  const { modalData, setModalData, onSubmit } = props

  const classes = useCuerrencyTabStyle()

  const {
    formatMessage,
    countries,
    currencies,
    validationSchema,
    selectCountriesOptions,
    selectCountriesInputProps,
    initialValues,
    selectCurrencyInputProps,
    selectCurrencyOptions,
  } = useCurrencyModal(props)

  return (
    <SurModal
      {...{ open: !!modalData, setOpen: () => setModalData(undefined) }}
      options={{ width: 544, height: 423 }}
      classes={{ root: classes.modal }}
      data-cy="CurrencyModal"
      title={
        <>
          {modalData?.status === 'edit' ? 'Edit' : 'Add'}{' '}
          {formatMessage(currencyTabMessages.modalTitle)}
          <SurTooltip
            title={formatMessage(currencyTabMessages.modalTitleTooltip)}
            placement="right"
          >
            <HelpTooltipIcon style={{ margin: '0 10px' }} />
          </SurTooltip>
        </>
      }
    >
      <SurForm
        {...{
          initialValues,
          onSubmit: (values, formikHelpers) =>
            onSubmit(modalData?.row?.id, values, formikHelpers),
          validationSchema,
        }}
      >
        {() => (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: 452,
                margin: '0 auto',
              }}
            >
              {modalData?.status === 'edit' ? (
                <SurTextField
                  name="selectLanguage"
                  id="selectLanguage"
                  label={formatMessage(
                    currencyTabMessages.modalFormCurrencyLabel,
                  )}
                  classes={{ root: classes.autoCompletes }}
                  // inputProps={languageInputProps}
                  disabled={true}
                  leftPrefix={
                    <h5 style={{ margin: '0 10px' }}>{modalData?.row?.slug}</h5>
                  }
                  value={modalData?.row?.name}
                />
              ) : (
                <SurAutoCompleteField
                  name="selectCurrency"
                  id="selectCurrency"
                  {...{
                    data: currencies ?? [],
                  }}
                  classes={{ root: classes.autoCompletes }}
                  options={selectCurrencyOptions}
                  inputProps={selectCurrencyInputProps}
                  hasClear
                  limitTags={3}
                />
              )}
              <SurAutoCompleteField
                name="selectCountries"
                id="selectCountries"
                {...{
                  data: (countries as Country[]) ?? [],
                }}
                classes={{ root: classes.autoCompletes }}
                options={selectCountriesOptions}
                inputProps={selectCountriesInputProps}
                hasClear
                limitTags={3}
              />
            </div>

            <Box className={classes.modalActionsContainer}>
              <SurButton
                variant="outlined"
                style={{ width: 219, margin: '0 6px' }}
                onClick={() => setModalData(undefined)}
              >
                {formatMessage(currencyTabMessages.modalCancel)}
              </SurButton>
              <SurButton
                type="submit"
                data-cy="CurrencyModalSubmit"
                style={{ width: 219, margin: '0 6px' }}
              >
                {formatMessage(currencyTabMessages.modalSubmit)}
              </SurButton>
            </Box>
          </>
        )}
      </SurForm>
    </SurModal>
  )
}

export default CuerrencyEditModal
