import * as Types from '../../../../types/serverTypes';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type GetLanguagesQueryVariables = {};


export type GetLanguagesQuery = (
  { __typename?: 'Queries' }
  & { languages?: Types.Maybe<(
    { __typename?: 'GenericQueryResponseLanguageType' }
    & { listDto?: Types.Maybe<(
      { __typename?: 'ListLanguageType' }
      & { items?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'Language' }
        & Pick<Types.Language, 'id' | 'fileUrl' | 'flagUrl' | 'isDefault' | 'languageDirection' | 'published' | 'slug'>
        & { countryLanguageDto?: Types.Maybe<(
          { __typename?: 'CountryLanguage' }
          & Pick<Types.CountryLanguage, 'id' | 'name'>
        )>, usedInCountryDtos?: Types.Maybe<Array<Types.Maybe<(
          { __typename?: 'Country' }
          & Pick<Types.Country, 'id' | 'name' | 'flagUrl'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type GetCountriesQueryVariables = {
  filter: Types.GenericFilterRequestCountryInputType;
};


export type GetCountriesQuery = (
  { __typename?: 'Queries' }
  & { countries?: Types.Maybe<(
    { __typename?: 'GenericQueryResponseCountryType' }
    & { listDto?: Types.Maybe<(
      { __typename?: 'ListCountryType' }
      & Pick<Types.ListCountryType, 'count'>
      & { items?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'Country' }
        & Pick<Types.Country, 'id' | 'name' | 'flagUrl'>
      )>>> }
    )> }
  )> }
);

export type GetCountriesInCreateLanguageQueryVariables = {};


export type GetCountriesInCreateLanguageQuery = (
  { __typename?: 'Queries' }
  & { countriesInCreateLanguage?: Types.Maybe<(
    { __typename?: 'GenericQueryResponseCountryType' }
    & { listDto?: Types.Maybe<(
      { __typename?: 'ListCountryType' }
      & Pick<Types.ListCountryType, 'count'>
      & { items?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'Country' }
        & Pick<Types.Country, 'id' | 'name' | 'flagUrl'>
        & { countryLanguageDtos?: Types.Maybe<Array<Types.Maybe<(
          { __typename?: 'CountryLanguage' }
          & Pick<Types.CountryLanguage, 'id' | 'languageDirection' | 'name' | 'slug'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type GetCountryLanguagesInCreateLanguageQueryVariables = {};


export type GetCountryLanguagesInCreateLanguageQuery = (
  { __typename?: 'Queries' }
  & { countryLanguagesInCreateLanguage?: Types.Maybe<(
    { __typename?: 'GenericQueryResponseCountryLanguageType' }
    & { listDto?: Types.Maybe<(
      { __typename?: 'ListCountryLanguageType' }
      & Pick<Types.ListCountryLanguageType, 'count'>
      & { items?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'CountryLanguage' }
        & Pick<Types.CountryLanguage, 'id' | 'name' | 'languageDirection' | 'slug'>
        & { countryDto?: Types.Maybe<(
          { __typename?: 'Country' }
          & Pick<Types.Country, 'id' | 'name' | 'flagUrl'>
        )> }
      )>>> }
    )> }
  )> }
);

export type GetCountriesListInUpdateLanguageQueryVariables = {
  filter: Types.GenericFilterRequestCountryInputType;
};


export type GetCountriesListInUpdateLanguageQuery = (
  { __typename?: 'Queries' }
  & { countriesInUpdateLanguage?: Types.Maybe<(
    { __typename?: 'GenericQueryResponseCountryType' }
    & { listDto?: Types.Maybe<(
      { __typename?: 'ListCountryType' }
      & Pick<Types.ListCountryType, 'count'>
      & { items?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'Country' }
        & Pick<Types.Country, 'id' | 'name' | 'flagUrl'>
        & { usingLanguageDto?: Types.Maybe<(
          { __typename?: 'Language' }
          & Pick<Types.Language, 'id'>
        )>, countryLanguageDtos?: Types.Maybe<Array<Types.Maybe<(
          { __typename?: 'CountryLanguage' }
          & Pick<Types.CountryLanguage, 'id' | 'languageDirection' | 'name' | 'slug'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type GetCurrenciesQueryVariables = {};


export type GetCurrenciesQuery = (
  { __typename?: 'Queries' }
  & { currencies?: Types.Maybe<(
    { __typename?: 'GenericQueryResponseCurrencyType' }
    & { listDto?: Types.Maybe<(
      { __typename?: 'ListCurrencyType' }
      & { items?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'Currency' }
        & Pick<Types.Currency, 'id' | 'isDefault' | 'published' | 'simbolUrl' | 'slug' | 'name'>
        & { usedInCountryDtos?: Types.Maybe<Array<Types.Maybe<(
          { __typename?: 'Country' }
          & Pick<Types.Country, 'id' | 'name' | 'flagUrl'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type GetCountriesInCreateCurrencyQueryVariables = {};


export type GetCountriesInCreateCurrencyQuery = (
  { __typename?: 'Queries' }
  & { countriesInCreateCurrency?: Types.Maybe<(
    { __typename?: 'GenericQueryResponseCountryType' }
    & { listDto?: Types.Maybe<(
      { __typename?: 'ListCountryType' }
      & { items?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'Country' }
        & Pick<Types.Country, 'id' | 'name' | 'flagUrl'>
        & { usingCurrencyDto?: Types.Maybe<(
          { __typename?: 'Currency' }
          & Pick<Types.Currency, 'id'>
        )> }
      )>>> }
    )> }
  )> }
);

export type GetCurrenciesInUpdateQueryVariables = {};


export type GetCurrenciesInUpdateQuery = (
  { __typename?: 'Queries' }
  & { currenciesInUpdate?: Types.Maybe<(
    { __typename?: 'GenericQueryResponseCurrencyType' }
    & { listDto?: Types.Maybe<(
      { __typename?: 'ListCurrencyType' }
      & { items?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'Currency' }
        & Pick<Types.Currency, 'id' | 'isDefault' | 'published' | 'simbolUrl' | 'slug' | 'name'>
        & { usedInCountryDtos?: Types.Maybe<Array<Types.Maybe<(
          { __typename?: 'Country' }
          & Pick<Types.Country, 'id' | 'name' | 'flagUrl'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type GetCurrenciesInCreateQueryVariables = {};


export type GetCurrenciesInCreateQuery = (
  { __typename?: 'Queries' }
  & { currenciesInCreate?: Types.Maybe<(
    { __typename?: 'GenericQueryResponseCurrencyType' }
    & { listDto?: Types.Maybe<(
      { __typename?: 'ListCurrencyType' }
      & { items?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'Currency' }
        & Pick<Types.Currency, 'id' | 'isDefault' | 'published' | 'simbolUrl' | 'slug' | 'name'>
      )>>> }
    )> }
  )> }
);


export const GetLanguagesDocument = gql`
    query GetLanguages {
  languages(filter: {all: true}) {
    listDto {
      items {
        id
        fileUrl
        flagUrl
        isDefault
        languageDirection
        published
        slug
        countryLanguageDto {
          id
          name
        }
        usedInCountryDtos {
          id
          name
          flagUrl
        }
      }
    }
  }
}
    `;
export function useGetLanguagesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLanguagesQuery, GetLanguagesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, baseOptions);
      }
export function useGetLanguagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLanguagesQuery, GetLanguagesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, baseOptions);
        }
export type GetLanguagesQueryHookResult = ReturnType<typeof useGetLanguagesQuery>;
export type GetLanguagesLazyQueryHookResult = ReturnType<typeof useGetLanguagesLazyQuery>;
export type GetLanguagesQueryResult = ApolloReactCommon.QueryResult<GetLanguagesQuery, GetLanguagesQueryVariables>;
export const GetCountriesDocument = gql`
    query GetCountries($filter: GenericFilterRequestCountryInputType!) {
  countries(filter: $filter) {
    listDto {
      count
      items {
        id
        name
        flagUrl
      }
    }
  }
}
    `;
export function useGetCountriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, baseOptions);
      }
export function useGetCountriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, baseOptions);
        }
export type GetCountriesQueryHookResult = ReturnType<typeof useGetCountriesQuery>;
export type GetCountriesLazyQueryHookResult = ReturnType<typeof useGetCountriesLazyQuery>;
export type GetCountriesQueryResult = ApolloReactCommon.QueryResult<GetCountriesQuery, GetCountriesQueryVariables>;
export const GetCountriesInCreateLanguageDocument = gql`
    query GetCountriesInCreateLanguage {
  countriesInCreateLanguage(filter: {all: true, includes: ["CountryLanguages"]}) {
    listDto {
      count
      items {
        id
        name
        flagUrl
        countryLanguageDtos {
          id
          languageDirection
          name
          slug
        }
      }
    }
  }
}
    `;
export function useGetCountriesInCreateLanguageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCountriesInCreateLanguageQuery, GetCountriesInCreateLanguageQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCountriesInCreateLanguageQuery, GetCountriesInCreateLanguageQueryVariables>(GetCountriesInCreateLanguageDocument, baseOptions);
      }
export function useGetCountriesInCreateLanguageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCountriesInCreateLanguageQuery, GetCountriesInCreateLanguageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCountriesInCreateLanguageQuery, GetCountriesInCreateLanguageQueryVariables>(GetCountriesInCreateLanguageDocument, baseOptions);
        }
export type GetCountriesInCreateLanguageQueryHookResult = ReturnType<typeof useGetCountriesInCreateLanguageQuery>;
export type GetCountriesInCreateLanguageLazyQueryHookResult = ReturnType<typeof useGetCountriesInCreateLanguageLazyQuery>;
export type GetCountriesInCreateLanguageQueryResult = ApolloReactCommon.QueryResult<GetCountriesInCreateLanguageQuery, GetCountriesInCreateLanguageQueryVariables>;
export const GetCountryLanguagesInCreateLanguageDocument = gql`
    query GetCountryLanguagesInCreateLanguage {
  countryLanguagesInCreateLanguage(filter: {all: true, includes: ["Country"]}) {
    listDto {
      count
      items {
        id
        name
        languageDirection
        slug
        countryDto {
          id
          name
          flagUrl
        }
      }
    }
  }
}
    `;
export function useGetCountryLanguagesInCreateLanguageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCountryLanguagesInCreateLanguageQuery, GetCountryLanguagesInCreateLanguageQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCountryLanguagesInCreateLanguageQuery, GetCountryLanguagesInCreateLanguageQueryVariables>(GetCountryLanguagesInCreateLanguageDocument, baseOptions);
      }
export function useGetCountryLanguagesInCreateLanguageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCountryLanguagesInCreateLanguageQuery, GetCountryLanguagesInCreateLanguageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCountryLanguagesInCreateLanguageQuery, GetCountryLanguagesInCreateLanguageQueryVariables>(GetCountryLanguagesInCreateLanguageDocument, baseOptions);
        }
export type GetCountryLanguagesInCreateLanguageQueryHookResult = ReturnType<typeof useGetCountryLanguagesInCreateLanguageQuery>;
export type GetCountryLanguagesInCreateLanguageLazyQueryHookResult = ReturnType<typeof useGetCountryLanguagesInCreateLanguageLazyQuery>;
export type GetCountryLanguagesInCreateLanguageQueryResult = ApolloReactCommon.QueryResult<GetCountryLanguagesInCreateLanguageQuery, GetCountryLanguagesInCreateLanguageQueryVariables>;
export const GetCountriesListInUpdateLanguageDocument = gql`
    query GetCountriesListInUpdateLanguage($filter: GenericFilterRequestCountryInputType!) {
  countriesInUpdateLanguage(filter: $filter) {
    listDto {
      count
      items {
        id
        name
        flagUrl
        usingLanguageDto {
          id
        }
        countryLanguageDtos {
          id
          languageDirection
          name
          slug
        }
      }
    }
  }
}
    `;
export function useGetCountriesListInUpdateLanguageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCountriesListInUpdateLanguageQuery, GetCountriesListInUpdateLanguageQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCountriesListInUpdateLanguageQuery, GetCountriesListInUpdateLanguageQueryVariables>(GetCountriesListInUpdateLanguageDocument, baseOptions);
      }
export function useGetCountriesListInUpdateLanguageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCountriesListInUpdateLanguageQuery, GetCountriesListInUpdateLanguageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCountriesListInUpdateLanguageQuery, GetCountriesListInUpdateLanguageQueryVariables>(GetCountriesListInUpdateLanguageDocument, baseOptions);
        }
export type GetCountriesListInUpdateLanguageQueryHookResult = ReturnType<typeof useGetCountriesListInUpdateLanguageQuery>;
export type GetCountriesListInUpdateLanguageLazyQueryHookResult = ReturnType<typeof useGetCountriesListInUpdateLanguageLazyQuery>;
export type GetCountriesListInUpdateLanguageQueryResult = ApolloReactCommon.QueryResult<GetCountriesListInUpdateLanguageQuery, GetCountriesListInUpdateLanguageQueryVariables>;
export const GetCurrenciesDocument = gql`
    query GetCurrencies {
  currencies(filter: {all: true, includes: ["UsedInCountries"]}) {
    listDto {
      items {
        id
        isDefault
        published
        simbolUrl
        slug
        name
        usedInCountryDtos {
          id
          name
          flagUrl
        }
      }
    }
  }
}
    `;
export function useGetCurrenciesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrenciesQuery, GetCurrenciesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrenciesQuery, GetCurrenciesQueryVariables>(GetCurrenciesDocument, baseOptions);
      }
export function useGetCurrenciesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrenciesQuery, GetCurrenciesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrenciesQuery, GetCurrenciesQueryVariables>(GetCurrenciesDocument, baseOptions);
        }
export type GetCurrenciesQueryHookResult = ReturnType<typeof useGetCurrenciesQuery>;
export type GetCurrenciesLazyQueryHookResult = ReturnType<typeof useGetCurrenciesLazyQuery>;
export type GetCurrenciesQueryResult = ApolloReactCommon.QueryResult<GetCurrenciesQuery, GetCurrenciesQueryVariables>;
export const GetCountriesInCreateCurrencyDocument = gql`
    query GetCountriesInCreateCurrency {
  countriesInCreateCurrency(filter: {all: true, includes: ["UsingCurrency"]}) {
    listDto {
      items {
        id
        name
        flagUrl
        usingCurrencyDto {
          id
        }
      }
    }
  }
}
    `;
export function useGetCountriesInCreateCurrencyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCountriesInCreateCurrencyQuery, GetCountriesInCreateCurrencyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCountriesInCreateCurrencyQuery, GetCountriesInCreateCurrencyQueryVariables>(GetCountriesInCreateCurrencyDocument, baseOptions);
      }
export function useGetCountriesInCreateCurrencyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCountriesInCreateCurrencyQuery, GetCountriesInCreateCurrencyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCountriesInCreateCurrencyQuery, GetCountriesInCreateCurrencyQueryVariables>(GetCountriesInCreateCurrencyDocument, baseOptions);
        }
export type GetCountriesInCreateCurrencyQueryHookResult = ReturnType<typeof useGetCountriesInCreateCurrencyQuery>;
export type GetCountriesInCreateCurrencyLazyQueryHookResult = ReturnType<typeof useGetCountriesInCreateCurrencyLazyQuery>;
export type GetCountriesInCreateCurrencyQueryResult = ApolloReactCommon.QueryResult<GetCountriesInCreateCurrencyQuery, GetCountriesInCreateCurrencyQueryVariables>;
export const GetCurrenciesInUpdateDocument = gql`
    query GetCurrenciesInUpdate {
  currenciesInUpdate(filter: {all: true}) {
    listDto {
      items {
        id
        isDefault
        published
        simbolUrl
        slug
        name
        usedInCountryDtos {
          id
          name
          flagUrl
        }
      }
    }
  }
}
    `;
export function useGetCurrenciesInUpdateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrenciesInUpdateQuery, GetCurrenciesInUpdateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrenciesInUpdateQuery, GetCurrenciesInUpdateQueryVariables>(GetCurrenciesInUpdateDocument, baseOptions);
      }
export function useGetCurrenciesInUpdateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrenciesInUpdateQuery, GetCurrenciesInUpdateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrenciesInUpdateQuery, GetCurrenciesInUpdateQueryVariables>(GetCurrenciesInUpdateDocument, baseOptions);
        }
export type GetCurrenciesInUpdateQueryHookResult = ReturnType<typeof useGetCurrenciesInUpdateQuery>;
export type GetCurrenciesInUpdateLazyQueryHookResult = ReturnType<typeof useGetCurrenciesInUpdateLazyQuery>;
export type GetCurrenciesInUpdateQueryResult = ApolloReactCommon.QueryResult<GetCurrenciesInUpdateQuery, GetCurrenciesInUpdateQueryVariables>;
export const GetCurrenciesInCreateDocument = gql`
    query GetCurrenciesInCreate {
  currenciesInCreate(filter: {all: true}) {
    listDto {
      items {
        id
        isDefault
        published
        simbolUrl
        slug
        name
      }
    }
  }
}
    `;
export function useGetCurrenciesInCreateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrenciesInCreateQuery, GetCurrenciesInCreateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrenciesInCreateQuery, GetCurrenciesInCreateQueryVariables>(GetCurrenciesInCreateDocument, baseOptions);
      }
export function useGetCurrenciesInCreateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrenciesInCreateQuery, GetCurrenciesInCreateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrenciesInCreateQuery, GetCurrenciesInCreateQueryVariables>(GetCurrenciesInCreateDocument, baseOptions);
        }
export type GetCurrenciesInCreateQueryHookResult = ReturnType<typeof useGetCurrenciesInCreateQuery>;
export type GetCurrenciesInCreateLazyQueryHookResult = ReturnType<typeof useGetCurrenciesInCreateLazyQuery>;
export type GetCurrenciesInCreateQueryResult = ApolloReactCommon.QueryResult<GetCurrenciesInCreateQuery, GetCurrenciesInCreateQueryVariables>;