import Dashboard from 'domains/Dashboard'
import React, { FC, memo } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useAppContext } from './App.context'
import { NotFound } from './components'
import { DefaultLayout } from './components/Layouts'

const AppRoutes: FC = () => {
  const { routes } = useAppContext()

  return (
    <DefaultLayout>
      <Switch>
        {routes.map((route) => (
          <Route key={route.path as string} {...route} />
        ))}
        <Route path="/profile" component={Dashboard} />
        <Route path="/change-password" component={Dashboard} />
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </DefaultLayout>
  )
}

export default memo(AppRoutes)
