import { Typography } from '@material-ui/core'
import { ReactComponent as ChangePassword } from 'assets/svg/changePassword.svg'
import { ReactComponent as LogoutIcon } from 'assets/svg/logout.svg'
import { ReactComponent as ArrowIcon } from 'assets/svg/simpleArrow.svg'
import { ReactComponent as UserProfile } from 'assets/svg/userProfile.svg'
import { authService } from 'domains/Auth'
import React, { useState } from 'react'
import { useDefaultLayoutContext } from '../DefaultLayout.context'
import useStyle from './UserAvatar.styles'
import { UserMenuItem } from './UserMenuItem'
import { useIntl } from 'react-intl'
import messages from './UserAvatar.messages'
import { SurAvatar, SurPopOver } from '@sur-ui/core'

const UserAvatar: React.FC = () => {
  const classes = useStyle()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const { userProfile } = useDefaultLayoutContext()
  const { formatMessage } = useIntl()
  return (
    <>
      <div
        className={classes.buttonContainer}
        data-cy="UserAvatarButton"
        onClick={(event) => {
          setAnchorEl(event.currentTarget as any)
        }}
      >
        <SurAvatar
          size="medium"
          variant="circle"
          src={userProfile?.avatarUrl ?? ''}
        />
        <Typography variant="h5" className={classes.userName}>
          {userProfile?.firstName} {userProfile?.lastName}
        </Typography>
        <ArrowIcon className={classes.arrowIcon} />
      </div>
      <SurPopOver
        id="TestPopOver"
        {...{ anchorEl }}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classes.paper }}
        data-cy="UserAvatarPopOver"
      >
        <UserMenuItem
          title={formatMessage(messages.profile)}
          icon={<UserProfile />}
          onClick={() => {}}
        />
        <UserMenuItem
          title={formatMessage(messages.changePassword)}
          icon={<ChangePassword />}
          onClick={() => {}}
        />
        <UserMenuItem
          title={formatMessage(messages.logout)}
          icon={<LogoutIcon />}
          onClick={() => authService.logout()}
        />
      </SurPopOver>
    </>
  )
}
export default UserAvatar
