import { Box } from '@material-ui/core'
import {
  SurAutoCompleteField,
  SurButton,
  SurCheckboxField,
  SurForm,
  SurModal,
  SurTextField,
  SurTooltip,
} from '@sur-ui/core'
import { ReactComponent as HelpTooltipIcon } from 'assets/svg/HelpTooltipIcon.svg'
import { SurFileUploaderField } from 'components'
import { EndpointType } from 'core/apollo/links'
import { useUploadImageMutation } from 'domains/Dashboard/mutation.generated'
import { FormikProps } from 'formik'
import { dissoc } from 'ramda'
import React, { FC } from 'react'
import { EntityType } from 'types/serverTypes'
import messages from '../LanguageTab.messages'
import useLanguageTabStyle from '../LanguageTab.style'
import {
  LanguageModalFormValues,
  LanguageModalProps,
} from '../LanguageTab.types'
import useLanguageModal from './LanguageModal.biz'

const LanguageEditModal: FC<LanguageModalProps> = (props) => {
  const { modalData, setModalData, onSubmit } = props
  const classes = useLanguageTabStyle()
  const {
    countriesSelector,
    languageSelector,
    fileUploaderLocalization,
    initialValues,
    options,
    selectCountriesInputProps,
    selectCountriesOptions,
    selectLanguageInputProps,
    selectLanguageOptions,
    validationSchema,
    formatMessage,
  } = useLanguageModal(props)

  return (
    <SurModal
      {...{ open: !!modalData, setOpen: () => setModalData(undefined) }}
      data-cy="LanguageModal"
      options={{ width: 1022, height: 767 }}
      classes={{ root: classes.modal }}
      title={
        <>
          {modalData?.status === 'edit'
            ? formatMessage(messages.modalTitleEditPrefix)
            : formatMessage(messages.modalTitleAddPrefix)}{' '}
          {formatMessage(messages.modalTitle)}
          <SurTooltip
            title={formatMessage(messages.modalTitleTooltip)}
            placement="right"
          >
            <HelpTooltipIcon style={{ margin: '0 10px' }} />
          </SurTooltip>
        </>
      }
    >
      <SurForm
        {...{
          initialValues,
          onSubmit: (values, formikHelpers) =>
            onSubmit(modalData?.row?.id, values, formikHelpers),
          validationSchema,
        }}
      >
        {({ values, setFieldValue }: FormikProps<LanguageModalFormValues>) => (
          <>
            <div style={{ display: 'flex' }}>
              {modalData?.status === 'edit' ? (
                <SurTextField
                  name="selectLanguage"
                  id="selectLanguage"
                  label={formatMessage(messages.modalFormLanguageLabel)}
                  classes={{ root: classes.autoCompletes }}
                  disabled={true}
                  leftPrefix={
                    <img
                      width={16}
                      height={16}
                      alt={modalData?.row?.countryLanguageDto?.name ?? ''}
                      src={modalData.row?.flagUrl ?? ''}
                    />
                  }
                  value={modalData?.row?.countryLanguageDto?.name}
                />
              ) : (
                <SurAutoCompleteField
                  name="selectLanguage"
                  id="selectLanguage"
                  {...{
                    data: languageSelector ?? [],
                  }}
                  classes={{ root: classes.autoCompletes }}
                  options={selectLanguageOptions}
                  inputProps={selectLanguageInputProps}
                  onCustomCange={(value: any, setValue: any) => {
                    setValue(value)
                    setFieldValue('selectCountries', [value?.countryDto])
                  }}
                  hasClear
                  limitTags={3}
                />
              )}
              <SurAutoCompleteField
                name="selectCountries"
                id="selectCountries"
                {...{
                  data: countriesSelector ?? [],
                }}
                classes={{ root: classes.autoCompletes }}
                options={selectCountriesOptions}
                inputProps={selectCountriesInputProps}
                hasClear
                limitTags={3}
              />
            </div>
            <div
              style={{
                display: 'flex',
                padding: '20px 20px 0 20px',
                marginBottom: 30,
              }}
            >
              <SurCheckboxField
                name="published"
                label={formatMessage(messages.modalFormPublishedLabel)}
                color="primary"
                data-cy="PrimaryLanguageCheckBox"
              />
            </div>
            <SurFileUploaderField
              defaultImageAddress={
                (modalData?.status === 'add'
                  ? values.selectLanguage?.countryDto?.flagUrl
                  : modalData?.row?.flagUrl) ??
                'https://legionstories.com/wp-content/themes/fox/images/placeholder.jpg'
              }
              name="languageUploader"
              endpointType={EndpointType.Upload}
              entityType={EntityType.Language}
              useMutation={useUploadImageMutation}
              onCancelDrops={(id: string) => {
                const newValue = dissoc(id, values.languageUploader)
                setFieldValue('languageUploader', newValue)
              }}
              {...{
                options,
                localization: fileUploaderLocalization,
              }}
            />
            <Box className={classes.modalActionsContainer}>
              <SurButton
                variant="outlined"
                style={{ width: 219 }}
                onClick={() => setModalData(undefined)}
              >
                {formatMessage(messages.modalCancel)}
              </SurButton>
              <SurButton
                type="submit"
                style={{ width: 219, margin: '0 10px' }}
                data-cy="LanguageModalSubmit"
              >
                {formatMessage(messages.modalSubmit)}
              </SurButton>
            </Box>
          </>
        )}
      </SurForm>
    </SurModal>
  )
}

export default LanguageEditModal
