import { Box, Typography } from '@material-ui/core'
import { SurTab, SurTabPanel, SurTabs, SurTooltip } from '@sur-ui/core'
import { ReactComponent as CurrencyTabIcon } from 'assets/svg/CurrencyTabIcon.svg'
import { ReactComponent as HelpTooltipIcon } from 'assets/svg/HelpTooltipIcon.svg'
import { ReactComponent as LanguageTabIcon } from 'assets/svg/LanguageTabIcon.svg'
import { ReactComponent as LegalTabIcon } from 'assets/svg/LegalTabIcon.svg'
import { ReactComponent as TaxTabIcon } from 'assets/svg/TaxTabIcon.svg'
import { contextWithEndpoint, EndpointType } from 'core/apollo/links'
import PagesTemplate from 'domains/App/components/Layouts/DefaultLayout/PageTemplate'
import { useSettingContext } from 'domains/Setting/Setting.context'
import React, { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { RouteComponentProps } from 'react-router-dom'
import { Currency, Language } from 'types/serverTypes'
import CurrencyTab from './CurrencyTab'
import { GeneralScreenProvider } from './GeneralScreen.context'
// import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import generalMessages from './GeneralScreen.messages'
import {
  useGetCurrenciesLazyQuery,
  useGetLanguagesLazyQuery,
} from './GeneralScreen.queries.generated'
import useGeneralScreenStyle from './GeneralScreen.style'
import LanguageTab from './LanguageTab'

const GeneralScreen: FC<RouteComponentProps> = ({ match: { path } }) => {
  const { formatMessage } = useIntl()
  const { breadCrumbs } = useSettingContext()
  const { label } = breadCrumbs?.find(
    ({ path: bPath }) => bPath === path,
  ) as INavItem

  const [
    getLanguages,
    {
      data: languageData,
      loading: languageLoading,
      called: languageCalled,
      refetch: refetchLanguages,
    },
  ] = useGetLanguagesLazyQuery({
    variables: { filter: { all: true, includes: ['UsedInCountries'] } },
    ...contextWithEndpoint(EndpointType.Cms),
  })

  const [
    getCurrencies,
    {
      data: currenciesData,
      loading: currenciesLoading,
      called: currenciesCalled,
      refetch: currenciesRefetch,
    },
  ] = useGetCurrenciesLazyQuery({
    variables: { filter: { all: true, includes: ['UsedInCountries'] } },
    ...contextWithEndpoint(EndpointType.Price),
  })

  const classes = useGeneralScreenStyle()
  const [value, setValue] = useState<number>(0)

  const getLanguagesList = () => {
    if (!languageCalled) getLanguages()
    refetchLanguages?.()
  }

  const getCurrenciesList = () => {
    if (!currenciesCalled) getCurrencies()
    currenciesRefetch?.()
  }
  return (
    <GeneralScreenProvider
      value={{
        languages: (languageData?.languages?.listDto?.items ??
          []) as Language[],
        currencies: (currenciesData?.currencies?.listDto?.items ??
          []) as Currency[],
        currenciesLoading,
        languageLoading,
        getLanguagesList,
        getCurrenciesList,
      }}
    >
      <PagesTemplate
        breadcrumbs={{
          routes: breadCrumbs,
          currentAttribute: path,
        }}
      >
        <Box className={classes.pageTitleContainer}>
          <Typography
            component="h4"
            variant="h4"
            classes={{ root: classes.pageTitle }}
          >
            {label}
          </Typography>
          <SurTooltip
            title={formatMessage(generalMessages.headerTitleTooltip)}
            placement="right"
          >
            <HelpTooltipIcon className={classes.tooltipIcon} />
          </SurTooltip>
        </Box>
        <Box className={classes.tabsContainer}>
          <SurTabs
            {...{ value }}
            color="white"
            variant="fullWidth"
            activeColor="primary"
            onChange={(_: any, newValue: any) => setValue(newValue)}
            classes={{ root: classes.tabs }}
          >
            <SurTab
              data-cy="LanguageTabHeader"
              hasHorizental
              icon={<LanguageTabIcon />}
              label={formatMessage(generalMessages.tabsLanguageTitle)}
            />
            <SurTab
              data-cy="CurrencyTabHeader"
              hasHorizental
              icon={<CurrencyTabIcon />}
              label={formatMessage(generalMessages.tabsCurrenciesTitle)}
            />
            <SurTab
              data-cy="TaxTabHeader"
              hasHorizental
              icon={<TaxTabIcon />}
              label={formatMessage(generalMessages.tabsTaxTitle)}
            />
            <SurTab
              data-cy="LeagalTabHeader"
              hasHorizental
              icon={<LegalTabIcon />}
              label={formatMessage(generalMessages.tabsLegalTitle)}
            />
          </SurTabs>
          <div className={classes.panels}>
            <LanguageTab {...{ value }} />
            <CurrencyTab {...{ value }} />
            <SurTabPanel {...{ value }} index={2}>
              panel3
            </SurTabPanel>
            <SurTabPanel {...{ value }} index={3}>
              panel4
            </SurTabPanel>
          </div>
        </Box>
      </PagesTemplate>
    </GeneralScreenProvider>
  )
}

export default GeneralScreen
