import { makeStyles } from '@material-ui/core'
import {
  ClassNameMap,
  Styles,
  WithStylesOptions,
} from '@material-ui/styles/withStyles'
import { Omit } from '@material-ui/types'
import { IFloraMaidTheme } from 'types/FloraMaidTheme'

function makeFloraMaidStyles<ClassKey extends string = string>(
  style: Styles<IFloraMaidTheme, object, ClassKey>,
  options?: Omit<WithStylesOptions<IFloraMaidTheme>, 'withTheme'>,
): (props?: any) => ClassNameMap<ClassKey>
function makeFloraMaidStyles<
  Props extends object = object,
  ClassKey extends string = string
>(
  styles: Styles<IFloraMaidTheme, Props, ClassKey>,
  options?: Omit<WithStylesOptions<IFloraMaidTheme>, 'withTheme'>,
): (props: Props) => ClassNameMap<ClassKey>

function makeFloraMaidStyles(styles: any, options: any) {
  return makeStyles(styles, options)
}

export default makeFloraMaidStyles
