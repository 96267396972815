import { makeFloraMaidStyles } from 'theme'

const useGeneralScreenStyle = makeFloraMaidStyles(
  ({ palette: { neutral }, spacing, breakpoints }) => ({
    root: {},
    pageTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      margin: spacing(7, 0, 5, 0),
    },
    pageTitle: {
      color: neutral[600],
      fontWeight: 500,
    },
    tooltipIcon: {
      margin: '0 10px',
    },
    tabsContainer: {
      border: `1px solid ${neutral[200]}`,
    },
    tabs: {
      backgroundColor: 'white',
      padding: spacing(0, 12),
      borderBottom: `1px solid ${neutral[200]}`,
      '& .MuiTab-root': {
        borderBottom: 'unset!important',
      },
    },
    panels: {
      backgroundColor: 'white',
      position: 'relative',
      height: '50vh',
      [breakpoints.down('md')]: {
        height: 'auto',
      },
      padding: spacing(6, 5),
    },
  }),
  { name: 'GeneralScreen' },
)

export default useGeneralScreenStyle
