import { useState, useEffect } from 'react'

export const useMediaQuery = (query: string) => {
  const supportMatchMedia =
    typeof window !== 'undefined' && typeof window.matchMedia !== 'undefined'

  const [match, setMatch] = useState(
    () => supportMatchMedia && matchMedia(query).matches,
  )

  useEffect(() => {
    if (!supportMatchMedia) return

    let active = true
    const queryList = matchMedia(query)
    const updateMatch = () => active && setMatch(queryList.matches)
    queryList.addListener(updateMatch)

    return () => {
      active = false
      queryList.removeListener(updateMatch)
    }
  }, [supportMatchMedia, query])

  return match
}
