import { contextWithEndpoint, EndpointType } from 'core/apollo/links'
import { useField } from 'formik'
import React, { FC } from 'react'
import { EntityType } from 'types/serverTypes'
import fileToBinaries from 'utils/filteToBase64'
import { SurFileUploader } from '@sur-ui/core'

type IReqType = 'error' | 'value' | 'onChange' | 'onUpload'

interface ISurFileUploaderFieldProps extends Omit<any, IReqType> {
  name: string
  useMutation: any
  endpointType: EndpointType
  entityType: EntityType
}
const SurFileUploaderField: FC<ISurFileUploaderFieldProps> = (props) => {
  const [
    { value, onChange, ...field },
    { touched, error },
    { setValue },
  ] = useField(props.name)
  // const [cancelDrop, setCancelDrops] = useState<any>()
  const fetchOptions = {
    onLoadStart: (id: string, abortHandler: any) => {
      setValue({
        ...value,
        [id]: {
          progress: {},
          abortHandler,
        },
      })
    },
    onProgress: (progress: ProgressEvent, id: string) => {
      const { loaded, total } = progress
      setValue({ ...value, [id]: { progress: { loaded, total } } })
    },
    // onAbortPossible: (id: string, onCancel: () => void) => {
    //   // console.log(id, onCancel, 'onCancel')
    //   setCancelDrops({ ...cancelDrop, [id]: { onCancel } })
    //   // if (id === cancelId) onCancel()
    // },
  }

  const [upload] = props.useMutation({
    ...contextWithEndpoint(props.endpointType, {
      fetchOptions,
    }),
  })

  const onUpload = async (file: File, id: string, reset?: boolean) => {
    const binarieryFile = await fileToBinaries(file)
    await upload({
      variables: {
        file: {
          dto: {
            entityType: EntityType.Resource,
            ...binarieryFile,
          },
        },
      },
    }).then((res: any) => {
      const url = res.data!.createFile!.listDto!.items![0].url
      if (reset) {
        return setValue({ [id]: { url } })
      }
      return setValue({ ...value, [id]: { url } })
    })
  }

  // const handleCancel = (id: string) => {
  //   console.log(value, id)
  //   const newValue = dissoc(id, value)
  //   setValue(newValue)
  // }

  return (
    <SurFileUploader
      {...field}
      {...props}
      {...{
        value,
        onUpload,
        onChange: setValue,
      }}
      error={touched && error}
    />
  )
}

export default SurFileUploaderField
