import React, { ReactNode, FC } from 'react'
import { makeFloraMaidStyles } from 'theme'
import { Box, Typography } from '@material-ui/core'
import { SurButton } from '@sur-ui/core'

interface IUserMenuItemProps {
  title: string
  icon: ReactNode
  onClick(): void
}

const useStyle = makeFloraMaidStyles(({ spacing, palette }) => ({
  root: {
    width: '100%',
    height: 36,
    padding: spacing(0, 1, 0, 6),
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    justifyContent: 'flex-start',
  },
  colorInherit: {
    color: palette.neutral[500],
    fontWeight: 600,
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    margin: spacing(0, 2),
  },
}))

export const UserMenuItem: FC<IUserMenuItemProps> = ({
  onClick,
  title,
  icon,
}) => {
  const classes = useStyle()
  return (
    <SurButton
      variant="text"
      color="inherit"
      classes={{ root: classes.root, label: classes.label }}
      {...{ onClick }}
    >
      <Box component="div" className={classes.icon}>
        {icon}
      </Box>
      <Typography variant="h6" classes={{ colorInherit: classes.colorInherit }}>
        {title}
      </Typography>
    </SurButton>
  )
}

export default UserMenuItem
