import { onError } from '@apollo/client/link/error'
import { SurToast } from '@sur-ui/core'
import { authService } from 'domains/Auth'
import client from './client'

// current issue on github
// https://github.com/apollographql/apollo-link/issues/300
// right now we fire a toast message, better to  be forwarded to 500 page

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const error = (text: any) => {
    SurToast(text, {})
  }
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      if (extensions && extensions.code) {
        error(message)
      }
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      )
    })
  if (
    networkError &&
    'statusCode' in networkError &&
    networkError.statusCode === 401
  ) {
    authService.logout()
    client.resetStore()
  }
})
export default errorLink
