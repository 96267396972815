import * as Types from '../../../../types/serverTypes';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type CreateLanguageMutationVariables = {
  language: Types.GenericMutationRequestLanguageInputType;
};


export type CreateLanguageMutation = (
  { __typename?: 'Mutations' }
  & { createLanguage?: Types.Maybe<(
    { __typename?: 'GenericQueryResponseLanguageType' }
    & Pick<Types.GenericQueryResponseLanguageType, 'isSuccess'>
    & { listDto?: Types.Maybe<(
      { __typename?: 'ListLanguageType' }
      & { items?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'Language' }
        & Pick<Types.Language, 'id' | 'isDefault' | 'published' | 'languageDirection' | 'slug' | 'fileUrl' | 'flagUrl'>
        & { countryLanguageDto?: Types.Maybe<(
          { __typename?: 'CountryLanguage' }
          & Pick<Types.CountryLanguage, 'id'>
        )>, usedInCountryDtos?: Types.Maybe<Array<Types.Maybe<(
          { __typename?: 'Country' }
          & Pick<Types.Country, 'id' | 'name' | 'flagUrl'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type DeleteLanguageMutationVariables = {
  language: Types.GenericMutationRequestLanguageInputType;
};


export type DeleteLanguageMutation = (
  { __typename?: 'Mutations' }
  & { deleteLanguage?: Types.Maybe<(
    { __typename?: 'GenericQueryResponseLanguageType' }
    & Pick<Types.GenericQueryResponseLanguageType, 'isSuccess'>
    & { listDto?: Types.Maybe<(
      { __typename?: 'ListLanguageType' }
      & { items?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'Language' }
        & Pick<Types.Language, 'id'>
      )>>> }
    )> }
  )> }
);

export type UpdateLanguageMutationVariables = {
  language: Types.GenericMutationRequestLanguageInputType;
};


export type UpdateLanguageMutation = (
  { __typename?: 'Mutations' }
  & { updateLanguage?: Types.Maybe<(
    { __typename?: 'GenericQueryResponseLanguageType' }
    & Pick<Types.GenericQueryResponseLanguageType, 'isSuccess'>
    & { listDto?: Types.Maybe<(
      { __typename?: 'ListLanguageType' }
      & { items?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'Language' }
        & Pick<Types.Language, 'id' | 'isDefault' | 'published' | 'languageDirection' | 'slug' | 'fileUrl' | 'flagUrl'>
        & { countryLanguageDto?: Types.Maybe<(
          { __typename?: 'CountryLanguage' }
          & Pick<Types.CountryLanguage, 'id'>
        )>, usedInCountryDtos?: Types.Maybe<Array<Types.Maybe<(
          { __typename?: 'Country' }
          & Pick<Types.Country, 'id' | 'name' | 'flagUrl'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type ChangeDefaultLanguageMutationVariables = {
  language: Types.GenericMutationRequestLanguageInputType;
};


export type ChangeDefaultLanguageMutation = (
  { __typename?: 'Mutations' }
  & { defaultLanguage?: Types.Maybe<(
    { __typename?: 'GenericQueryResponseLanguageType' }
    & Pick<Types.GenericQueryResponseLanguageType, 'isSuccess'>
    & { listDto?: Types.Maybe<(
      { __typename?: 'ListLanguageType' }
      & { items?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'Language' }
        & Pick<Types.Language, 'id' | 'isDefault'>
      )>>> }
    )> }
  )> }
);

export type UpdateCurrencyMutationVariables = {
  currency: Types.GenericMutationRequestCurrencyInputType;
};


export type UpdateCurrencyMutation = (
  { __typename?: 'Mutations' }
  & { updateCurrency?: Types.Maybe<(
    { __typename?: 'GenericQueryResponseCurrencyType' }
    & Pick<Types.GenericQueryResponseCurrencyType, 'isSuccess'>
    & { listDto?: Types.Maybe<(
      { __typename?: 'ListCurrencyType' }
      & { items?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'Currency' }
        & Pick<Types.Currency, 'id' | 'isDefault' | 'published' | 'simbolUrl' | 'slug' | 'name'>
        & { usedInCountryDtos?: Types.Maybe<Array<Types.Maybe<(
          { __typename?: 'Country' }
          & Pick<Types.Country, 'id' | 'name' | 'flagUrl'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type DeleteCurrencyMutationVariables = {
  currency: Types.GenericMutationRequestCurrencyInputType;
};


export type DeleteCurrencyMutation = (
  { __typename?: 'Mutations' }
  & { deleteCurrency?: Types.Maybe<(
    { __typename?: 'GenericQueryResponseCurrencyType' }
    & Pick<Types.GenericQueryResponseCurrencyType, 'isSuccess'>
    & { listDto?: Types.Maybe<(
      { __typename?: 'ListCurrencyType' }
      & { items?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'Currency' }
        & Pick<Types.Currency, 'id'>
      )>>> }
    )> }
  )> }
);

export type DefaultCurrencyMutationVariables = {
  currency: Types.GenericMutationRequestCurrencyInputType;
};


export type DefaultCurrencyMutation = (
  { __typename?: 'Mutations' }
  & { defaultCurrency?: Types.Maybe<(
    { __typename?: 'GenericQueryResponseCurrencyType' }
    & Pick<Types.GenericQueryResponseCurrencyType, 'isSuccess'>
    & { listDto?: Types.Maybe<(
      { __typename?: 'ListCurrencyType' }
      & { items?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'Currency' }
        & Pick<Types.Currency, 'id' | 'isDefault'>
      )>>> }
    )> }
  )> }
);


export const CreateLanguageDocument = gql`
    mutation createLanguage($language: GenericMutationRequestLanguageInputType!) {
  createLanguage(language: $language) {
    isSuccess
    listDto {
      items {
        id
        isDefault
        published
        languageDirection
        slug
        fileUrl
        flagUrl
        countryLanguageDto {
          id
        }
        usedInCountryDtos {
          id
          name
          flagUrl
        }
      }
    }
  }
}
    `;
export type CreateLanguageMutationFn = ApolloReactCommon.MutationFunction<CreateLanguageMutation, CreateLanguageMutationVariables>;
export function useCreateLanguageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateLanguageMutation, CreateLanguageMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateLanguageMutation, CreateLanguageMutationVariables>(CreateLanguageDocument, baseOptions);
      }
export type CreateLanguageMutationHookResult = ReturnType<typeof useCreateLanguageMutation>;
export type CreateLanguageMutationResult = ApolloReactCommon.MutationResult<CreateLanguageMutation>;
export type CreateLanguageMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateLanguageMutation, CreateLanguageMutationVariables>;
export const DeleteLanguageDocument = gql`
    mutation deleteLanguage($language: GenericMutationRequestLanguageInputType!) {
  deleteLanguage(language: $language) {
    isSuccess
    listDto {
      items {
        id
      }
    }
  }
}
    `;
export type DeleteLanguageMutationFn = ApolloReactCommon.MutationFunction<DeleteLanguageMutation, DeleteLanguageMutationVariables>;
export function useDeleteLanguageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteLanguageMutation, DeleteLanguageMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteLanguageMutation, DeleteLanguageMutationVariables>(DeleteLanguageDocument, baseOptions);
      }
export type DeleteLanguageMutationHookResult = ReturnType<typeof useDeleteLanguageMutation>;
export type DeleteLanguageMutationResult = ApolloReactCommon.MutationResult<DeleteLanguageMutation>;
export type DeleteLanguageMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteLanguageMutation, DeleteLanguageMutationVariables>;
export const UpdateLanguageDocument = gql`
    mutation updateLanguage($language: GenericMutationRequestLanguageInputType!) {
  updateLanguage(language: $language) {
    isSuccess
    listDto {
      items {
        id
        isDefault
        published
        languageDirection
        slug
        fileUrl
        flagUrl
        countryLanguageDto {
          id
        }
        usedInCountryDtos {
          id
          name
          flagUrl
        }
      }
    }
  }
}
    `;
export type UpdateLanguageMutationFn = ApolloReactCommon.MutationFunction<UpdateLanguageMutation, UpdateLanguageMutationVariables>;
export function useUpdateLanguageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLanguageMutation, UpdateLanguageMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateLanguageMutation, UpdateLanguageMutationVariables>(UpdateLanguageDocument, baseOptions);
      }
export type UpdateLanguageMutationHookResult = ReturnType<typeof useUpdateLanguageMutation>;
export type UpdateLanguageMutationResult = ApolloReactCommon.MutationResult<UpdateLanguageMutation>;
export type UpdateLanguageMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLanguageMutation, UpdateLanguageMutationVariables>;
export const ChangeDefaultLanguageDocument = gql`
    mutation changeDefaultLanguage($language: GenericMutationRequestLanguageInputType!) {
  defaultLanguage(language: $language) {
    isSuccess
    listDto {
      items {
        id
        isDefault
      }
    }
  }
}
    `;
export type ChangeDefaultLanguageMutationFn = ApolloReactCommon.MutationFunction<ChangeDefaultLanguageMutation, ChangeDefaultLanguageMutationVariables>;
export function useChangeDefaultLanguageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeDefaultLanguageMutation, ChangeDefaultLanguageMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeDefaultLanguageMutation, ChangeDefaultLanguageMutationVariables>(ChangeDefaultLanguageDocument, baseOptions);
      }
export type ChangeDefaultLanguageMutationHookResult = ReturnType<typeof useChangeDefaultLanguageMutation>;
export type ChangeDefaultLanguageMutationResult = ApolloReactCommon.MutationResult<ChangeDefaultLanguageMutation>;
export type ChangeDefaultLanguageMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeDefaultLanguageMutation, ChangeDefaultLanguageMutationVariables>;
export const UpdateCurrencyDocument = gql`
    mutation updateCurrency($currency: GenericMutationRequestCurrencyInputType!) {
  updateCurrency(currency: $currency) {
    isSuccess
    listDto {
      items {
        id
        isDefault
        published
        simbolUrl
        slug
        name
        usedInCountryDtos {
          id
          name
          flagUrl
        }
      }
    }
  }
}
    `;
export type UpdateCurrencyMutationFn = ApolloReactCommon.MutationFunction<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>;
export function useUpdateCurrencyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>(UpdateCurrencyDocument, baseOptions);
      }
export type UpdateCurrencyMutationHookResult = ReturnType<typeof useUpdateCurrencyMutation>;
export type UpdateCurrencyMutationResult = ApolloReactCommon.MutationResult<UpdateCurrencyMutation>;
export type UpdateCurrencyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>;
export const DeleteCurrencyDocument = gql`
    mutation deleteCurrency($currency: GenericMutationRequestCurrencyInputType!) {
  deleteCurrency(currency: $currency) {
    isSuccess
    listDto {
      items {
        id
      }
    }
  }
}
    `;
export type DeleteCurrencyMutationFn = ApolloReactCommon.MutationFunction<DeleteCurrencyMutation, DeleteCurrencyMutationVariables>;
export function useDeleteCurrencyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCurrencyMutation, DeleteCurrencyMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCurrencyMutation, DeleteCurrencyMutationVariables>(DeleteCurrencyDocument, baseOptions);
      }
export type DeleteCurrencyMutationHookResult = ReturnType<typeof useDeleteCurrencyMutation>;
export type DeleteCurrencyMutationResult = ApolloReactCommon.MutationResult<DeleteCurrencyMutation>;
export type DeleteCurrencyMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCurrencyMutation, DeleteCurrencyMutationVariables>;
export const DefaultCurrencyDocument = gql`
    mutation defaultCurrency($currency: GenericMutationRequestCurrencyInputType!) {
  defaultCurrency(currency: $currency) {
    isSuccess
    listDto {
      items {
        id
        isDefault
      }
    }
  }
}
    `;
export type DefaultCurrencyMutationFn = ApolloReactCommon.MutationFunction<DefaultCurrencyMutation, DefaultCurrencyMutationVariables>;
export function useDefaultCurrencyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DefaultCurrencyMutation, DefaultCurrencyMutationVariables>) {
        return ApolloReactHooks.useMutation<DefaultCurrencyMutation, DefaultCurrencyMutationVariables>(DefaultCurrencyDocument, baseOptions);
      }
export type DefaultCurrencyMutationHookResult = ReturnType<typeof useDefaultCurrencyMutation>;
export type DefaultCurrencyMutationResult = ApolloReactCommon.MutationResult<DefaultCurrencyMutation>;
export type DefaultCurrencyMutationOptions = ApolloReactCommon.BaseMutationOptions<DefaultCurrencyMutation, DefaultCurrencyMutationVariables>;