import { gql } from '@apollo/client'

export const TOKEN_QUERY = gql`
  query getToken {
    access_token @client
  }
`

export const LANGUAGE_QUERY = gql`
  query getLocale {
    locale @client
  }
`
export const LANGUAGE_MESSEGES_QUERY = gql`
  query getLocalizations {
    locale @client
    messages @client
  }
`

// culture (locale), direction, messages?
// [{id, defaultValue}] hello {date, date, short}
