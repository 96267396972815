import React, { FC, memo } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { useSettingContext } from './Setting.context'
import SettingScreen from './pages/SettingScreen'

const SettingRoutes: FC = () => {
  const { routes } = useSettingContext()
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/`} component={SettingScreen} exact />
      {routes?.map((route) => (
        <Route key={route.path as string} {...route} exact />
      ))}
    </Switch>
  )
}

export default memo(SettingRoutes)
