import * as Types from '../../../types/serverTypes';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type GetPermissionTitlesQueryVariables = {};


export type GetPermissionTitlesQuery = (
  { __typename?: 'Queries' }
  & { permissions?: Types.Maybe<(
    { __typename?: 'GenericQueryResponseResourceType' }
    & { listDto?: Types.Maybe<(
      { __typename?: 'ListResourceType' }
      & { items?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'Resource' }
        & Pick<Types.Resource, 'id' | 'title'>
      )>>> }
    )> }
  )> }
);

export type GetPermissionsQueryVariables = {};


export type GetPermissionsQuery = (
  { __typename?: 'Queries' }
  & { permissions?: Types.Maybe<(
    { __typename?: 'GenericQueryResponseResourceType' }
    & { listDto?: Types.Maybe<(
      { __typename?: 'ListResourceType' }
      & Pick<Types.ListResourceType, 'count'>
      & { items?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'Resource' }
        & Pick<Types.Resource, 'id' | 'title' | 'fileUrl' | 'attribute' | 'uIResourceType' | 'parentId' | 'value'>
      )>>> }
    )> }
  )> }
);

export type GetPersonQueryVariables = {};


export type GetPersonQuery = (
  { __typename?: 'Queries' }
  & { profile?: Types.Maybe<(
    { __typename?: 'GenericQueryResponsePersonType' }
    & { listDto?: Types.Maybe<(
      { __typename?: 'ListPersonType' }
      & { items?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'Person' }
        & Pick<Types.Person, 'firstName' | 'lastName' | 'id' | 'avatarUrl'>
        & { userDto?: Types.Maybe<(
          { __typename?: 'User' }
          & Pick<Types.User, 'id'>
        )>, personSetting?: Types.Maybe<(
          { __typename?: 'PersonSetting' }
          & Pick<Types.PersonSetting, 'languageId'>
        )> }
      )>>> }
    )> }
  )> }
);

export type GetUserLanguagesQueryVariables = {};


export type GetUserLanguagesQuery = (
  { __typename?: 'Queries' }
  & { userLanguages?: Types.Maybe<(
    { __typename?: 'GenericQueryResponseLanguageType' }
    & { listDto?: Types.Maybe<(
      { __typename?: 'ListLanguageType' }
      & { items?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'Language' }
        & Pick<Types.Language, 'id' | 'fileUrl' | 'flagUrl' | 'isDefault' | 'languageDirection' | 'published' | 'slug'>
        & { languageResources?: Types.Maybe<Array<Types.Maybe<(
          { __typename?: 'LanguageResource' }
          & Pick<Types.LanguageResource, 'key' | 'value'>
        )>>>, usedInCountryDtos?: Types.Maybe<Array<Types.Maybe<(
          { __typename?: 'Country' }
          & Pick<Types.Country, 'id' | 'name'>
        )>>>, countryLanguageDto?: Types.Maybe<(
          { __typename?: 'CountryLanguage' }
          & Pick<Types.CountryLanguage, 'id' | 'name'>
        )> }
      )>>> }
    )> }
  )> }
);

export type GetSearchValuesQueryVariables = {};


export type GetSearchValuesQuery = (
  { __typename?: 'Queries' }
  & { languages?: Types.Maybe<(
    { __typename?: 'GenericQueryResponseLanguageType' }
    & { listDto?: Types.Maybe<(
      { __typename?: 'ListLanguageType' }
      & { items?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'Language' }
        & Pick<Types.Language, 'id' | 'languageDirection' | 'fileUrl' | 'isDefault'>
        & { usedInCountryDtos?: Types.Maybe<Array<Types.Maybe<(
          { __typename?: 'Country' }
          & Pick<Types.Country, 'id' | 'name' | 'flagUrl'>
        )>>> }
      )>>> }
    )> }
  )> }
);


export const GetPermissionTitlesDocument = gql`
    query GetPermissionTitles {
  permissions(filter: {all: true}) {
    listDto {
      items {
        id
        title
      }
    }
  }
}
    `;
export function useGetPermissionTitlesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPermissionTitlesQuery, GetPermissionTitlesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPermissionTitlesQuery, GetPermissionTitlesQueryVariables>(GetPermissionTitlesDocument, baseOptions);
      }
export function useGetPermissionTitlesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPermissionTitlesQuery, GetPermissionTitlesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPermissionTitlesQuery, GetPermissionTitlesQueryVariables>(GetPermissionTitlesDocument, baseOptions);
        }
export type GetPermissionTitlesQueryHookResult = ReturnType<typeof useGetPermissionTitlesQuery>;
export type GetPermissionTitlesLazyQueryHookResult = ReturnType<typeof useGetPermissionTitlesLazyQuery>;
export type GetPermissionTitlesQueryResult = ApolloReactCommon.QueryResult<GetPermissionTitlesQuery, GetPermissionTitlesQueryVariables>;
export const GetPermissionsDocument = gql`
    query GetPermissions {
  permissions(filter: {all: true}) {
    listDto {
      count
      items {
        id
        title
        fileUrl
        attribute
        uIResourceType
        parentId
        value
      }
    }
  }
}
    `;
export function useGetPermissionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(GetPermissionsDocument, baseOptions);
      }
export function useGetPermissionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(GetPermissionsDocument, baseOptions);
        }
export type GetPermissionsQueryHookResult = ReturnType<typeof useGetPermissionsQuery>;
export type GetPermissionsLazyQueryHookResult = ReturnType<typeof useGetPermissionsLazyQuery>;
export type GetPermissionsQueryResult = ApolloReactCommon.QueryResult<GetPermissionsQuery, GetPermissionsQueryVariables>;
export const GetPersonDocument = gql`
    query GetPerson {
  profile(filter: {all: true}) {
    listDto {
      items {
        firstName
        lastName
        id
        userDto {
          id
        }
        personSetting {
          languageId
        }
        avatarUrl
      }
    }
  }
}
    `;
export function useGetPersonQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPersonQuery, GetPersonQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPersonQuery, GetPersonQueryVariables>(GetPersonDocument, baseOptions);
      }
export function useGetPersonLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPersonQuery, GetPersonQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPersonQuery, GetPersonQueryVariables>(GetPersonDocument, baseOptions);
        }
export type GetPersonQueryHookResult = ReturnType<typeof useGetPersonQuery>;
export type GetPersonLazyQueryHookResult = ReturnType<typeof useGetPersonLazyQuery>;
export type GetPersonQueryResult = ApolloReactCommon.QueryResult<GetPersonQuery, GetPersonQueryVariables>;
export const GetUserLanguagesDocument = gql`
    query GetUserLanguages {
  userLanguages(filter: {all: true}) {
    listDto {
      items {
        id
        fileUrl
        flagUrl
        isDefault
        languageDirection
        published
        slug
        languageResources {
          key
          value
        }
        usedInCountryDtos {
          id
          name
        }
        countryLanguageDto {
          id
          name
        }
      }
    }
  }
}
    `;
export function useGetUserLanguagesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserLanguagesQuery, GetUserLanguagesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserLanguagesQuery, GetUserLanguagesQueryVariables>(GetUserLanguagesDocument, baseOptions);
      }
export function useGetUserLanguagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserLanguagesQuery, GetUserLanguagesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserLanguagesQuery, GetUserLanguagesQueryVariables>(GetUserLanguagesDocument, baseOptions);
        }
export type GetUserLanguagesQueryHookResult = ReturnType<typeof useGetUserLanguagesQuery>;
export type GetUserLanguagesLazyQueryHookResult = ReturnType<typeof useGetUserLanguagesLazyQuery>;
export type GetUserLanguagesQueryResult = ApolloReactCommon.QueryResult<GetUserLanguagesQuery, GetUserLanguagesQueryVariables>;
export const GetSearchValuesDocument = gql`
    query GetSearchValues {
  languages(filter: {all: true, includes: ["UsedInCountries"]}) {
    listDto {
      items {
        id
        languageDirection
        fileUrl
        isDefault
        usedInCountryDtos {
          id
          name
          flagUrl
        }
      }
    }
  }
}
    `;
export function useGetSearchValuesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSearchValuesQuery, GetSearchValuesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSearchValuesQuery, GetSearchValuesQueryVariables>(GetSearchValuesDocument, baseOptions);
      }
export function useGetSearchValuesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSearchValuesQuery, GetSearchValuesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSearchValuesQuery, GetSearchValuesQueryVariables>(GetSearchValuesDocument, baseOptions);
        }
export type GetSearchValuesQueryHookResult = ReturnType<typeof useGetSearchValuesQuery>;
export type GetSearchValuesLazyQueryHookResult = ReturnType<typeof useGetSearchValuesLazyQuery>;
export type GetSearchValuesQueryResult = ApolloReactCommon.QueryResult<GetSearchValuesQuery, GetSearchValuesQueryVariables>;