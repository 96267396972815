import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import authLink from './authentication'
import errorLink from './error'
import { AppEndpoints, checkContextForEndpoint, EndpointType } from './links'

const otherLinks = ApolloLink.split(
  checkContextForEndpoint(EndpointType.Price),
  AppEndpoints.price,
  AppEndpoints.main,
)

const mainLinks = ApolloLink.split(
  checkContextForEndpoint(EndpointType.Cms),
  AppEndpoints.cms,
  otherLinks,
)

const uploadLink = ApolloLink.split(
  checkContextForEndpoint(EndpointType.Upload),
  AppEndpoints.upload,
  mainLinks,
)
const surLink = ApolloLink.from([errorLink, authLink]).split(
  checkContextForEndpoint(EndpointType.Identity),
  AppEndpoints.identity,
  uploadLink,
)

const cache = new InMemoryCache()

const client = new ApolloClient({
  cache,
  link: surLink,
  queryDeduplication: true,
  defaultOptions: {
    mutate: {
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'cache-first',
    },
  },
})

export default client
