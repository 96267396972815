import * as Types from '../../../types/serverTypes';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type CreateCompanyMutationVariables = {
  arg: Types.GenericMutationRequestPersonInputType;
};


export type CreateCompanyMutation = (
  { __typename?: 'Mutations' }
  & { updatePersonSetting?: Types.Maybe<(
    { __typename?: 'GenericQueryResponsePersonType' }
    & Pick<Types.GenericQueryResponsePersonType, 'isSuccess'>
    & { listDto?: Types.Maybe<(
      { __typename?: 'ListPersonType' }
      & { items?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'Person' }
        & Pick<Types.Person, 'id' | 'fullName'>
        & { personSetting?: Types.Maybe<(
          { __typename?: 'PersonSetting' }
          & Pick<Types.PersonSetting, 'languageId'>
        )> }
      )>>> }
    )> }
  )> }
);


export const CreateCompanyDocument = gql`
    mutation createCompany($arg: GenericMutationRequestPersonInputType!) {
  updatePersonSetting(person: $arg) {
    isSuccess
    listDto {
      items {
        id
        fullName
        personSetting {
          languageId
        }
      }
    }
  }
}
    `;
export type CreateCompanyMutationFn = ApolloReactCommon.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;
export function useCreateCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, baseOptions);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = ApolloReactCommon.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;