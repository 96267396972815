import { Box } from '@material-ui/core'
import React from 'react'
import PageContentContainer from './PageContentContainer'
import useStyle from './PageTemplate.styles'
import PageTemplateHeader from './PageTemplateHeader'

interface IPagesTemplateProps {
  data?: INavItem[]
  breadcrumbs?: {
    routes?: INavItem[]
    currentAttribute: string
  }

}

const PagesTemplate: React.FC<IPagesTemplateProps> = ({
  children,
  data,
  breadcrumbs,
}) => {
  const classes = useStyle({})
  return (
    <>
      <Box component="main" position="relative" className={classes.root}>
        {breadcrumbs && (
          <PageTemplateHeader
            {...breadcrumbs}
            classes={{
              root: classes.header,
              container: classes.breadcrumbs,
              link: classes.breadcrumb,
              last: classes.lastBreadcrumb,
            }}
          />
        )}
        <PageContentContainer {...{ data }}>{children}</PageContentContainer>
      </Box>
    </>
  )
}

export default PagesTemplate
