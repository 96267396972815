const convertToSortedTreeItems = <T extends ITreeNodeData>(tree: T[]): T[] =>
  tree.flatMap((_) =>
    _.children?.length
      ? [_].concat(convertToSortedTreeItems(_.children as T[]))
      : [_],
  )
const filterTree = <T extends ITreeNodeData>(
  tree: T[],
  filterBy: (item: T) => boolean,
  // map: Record<string, T>,
) => {
  // FIXME: Should I reverse the tree?
  const sortedReverse = convertToSortedTreeItems(tree).reverse()
  sortedReverse.forEach((item) => {
    if (filterBy(item) || !item.parentId) {
      return
    }

    // let parent = map[item.parentId]
    // let child = item
    // let propagate = !!parent

    // while (propagate) {
    //   const index = parent.children!.findIndex((_) => _.id === child.id)
    //   parent.children!.splice(index, 1)
    //   parent.hasChildren = parent.children!.length > 0

    //   child = parent
    //   parent = map[child.parentId!]
    //   propagate = parent && !child.hasChildren
    // }
  })
}

const convertArrayToTree = <T extends ITreeNodeData = ITreeNodeData>(
  items: T[],
  filterBy?: (item: T) => boolean,
): [T[], T[]] => {
  const map = Object.fromEntries(
    items.map((_) => [_.id, { ..._, children: [] }]),
  )
  const treeItems: T[] = Object.values(map)

  treeItems
    .filter((_) => _.parentId && _.parentId in map)
    .forEach((item) => {
      const parent = map[item.parentId!]
      parent.children!.push(item)
      parent.hasChildren = true
    })

  const tree = treeItems.filter((_) => !_.parentId)

  if (filterBy) {
    filterTree(tree, filterBy)
  }

  return [tree, treeItems]
}

export default convertArrayToTree
