import React from 'react'
import { Box } from '@material-ui/core'
import useStyle from './HeaderNotification.styles'
import { ReactComponent as BellIcon } from 'assets/svg/bell.svg'
import { SurBadge, SurIconButton } from '@sur-ui/core'

const HeaderNotification: React.FC = () => {
  const classes = useStyle()
  return (
    <Box component="div" className={classes.root}>
      <SurBadge
        overlap="circle"
        color="primary"
        badgeContent={9}
        max={99}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        classes={{ badge: classes.badge }}
      >
        <SurIconButton
          variant="text"
          classes={{ root: classes.button }}
          color="inherit"
        >
          <BellIcon />
        </SurIconButton>
      </SurBadge>
    </Box>
  )
}
export default HeaderNotification
