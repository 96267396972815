import { useWatchLoacaleResources } from 'domains/App/gql/hooks'
import React, { FC, useCallback, useState } from 'react'
import { IntlProvider, ReactIntlErrorCode } from 'react-intl'

const LanguageProvider: FC = ({ children }) => {
  const [intlprops, setIntlProps] = useState<LocalizationType>({
    locale: 'en-US',
    messages: {},
  })

  useWatchLoacaleResources(
    useCallback((data) => {
      setIntlProps(data)
    }, []),
  )
  return (
    <IntlProvider
      {...intlprops}
      onError={(err) => {
        //@ts-expect-error
        if (err.code === ReactIntlErrorCode.MISSING_TRANSLATION)
          return console.warn(err.message, 'messages')
        console.error(err)
      }}
    >
      {children}
    </IntlProvider>
  )
}

export default LanguageProvider
