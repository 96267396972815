import { makeFloraMaidStyles } from 'theme'

export default makeFloraMaidStyles(({ spacing }) => ({
  root: {
    flex: 1,
    position: 'relative',
    // paddingTop: 27,
    // paddingRight: 20,
  },
  form: {
    position: 'absolute',
    top: 29,
    left: 0,
    right: 20,
    zIndex: 999,
    overflow: 'hidden',
  },
  searchInput: {
    flex: 1,
    padding: spacing(0, 5, 0, 0),
    height: '90%',
  },
  endAdornment: {
    right: 0,
    top: 0,
    height: 39,
    display: 'flex',
    alignItems: 'center',
  },
  popupIndicator: {
    width: 30,
    height: 30,
    '& button:hover': {
      backgroundColor: 'unset',
    },
  },
}))
