import { Box, Grid, Typography } from '@material-ui/core'
import { ReactComponent as PlusIcon } from 'assets/svg/plusIcon.svg'
import { ReactComponent as QuestionIcon } from 'assets/svg/questionIcon.svg'
import { ReactComponent as DownloadIcon } from 'assets/svg/uploaderDownloadIcon.svg'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import LanguageEditModal from './LanguageModal'
import useLanguageTab from './LanguageTab.biz'
import messages from './LanguageTab.messages'
import useLanguageTabStyle from './LanguageTab.style'
import { LanguageTabProps } from './LanguageTab.types'
import Skeleton from '@material-ui/lab/Skeleton'
import {
  SurAutocomplete,
  SurButton,
  SurDataGrid,
  SurTabPanel,
} from '@sur-ui/core'

const LanguageTab: FC<LanguageTabProps> = ({ value: position }) => {
  const classes = useLanguageTabStyle()

  const {
    modalData,
    primaryLanguageInputProps,
    primaryLanguageOptions,
    columns,
    languages,
    languageLoading,
    defaultLanguage,
    primaryLanguageValue,
    setPrimaryLanguageValue,
    setModalData,
    handleSetPrimaryLanguage,
    handleAddLanguageClick,
    handleDeleteLanguage,
    handleDownloadLanguageTemplate,
    handleEditLanguage,
    formatMessage,
    onSubmit,
  } = useLanguageTab()

  return (
    <SurTabPanel
      {...{ value: position }}
      index={0}
      classes={{
        root: classes.root,
        content: classes.languageTab,
      }}
    >
      <Grid lg={7} md={12} className={classes.languagesList}>
        {/* {modalData?.status && ( */}
        <LanguageEditModal
          {...{ modalData, setModalData, onSubmit, isOpen: !!modalData }}
        />
        {/* )} */}
        <SurDataGrid
          {...{
            data: languages ?? [],
            columns,
            isLoading: languageLoading,
            noToolbar: true,
            phrases: {
              actionColumnTitle: formatMessage(messages.gridHeaderActions),
            },
            'data-cy': 'LanguageDataGrid',
            onDelete: handleDeleteLanguage,
            onEditClick: handleEditLanguage,
          }}
        />
      </Grid>
      <Grid lg={5} md={12} className={classes.primaryLanguage}>
        <Typography
          variant="h5"
          component="h5"
          classes={{ root: classes.primaryLanguageTitle }}
        >
          {formatMessage(messages.primaryLanguageTitle)}
        </Typography>
        <Typography classes={{ root: classes.primaryLanguageText }}>
          {formatMessage(messages.primaryLanguageContent)}{' '}
          {defaultLanguage?.countryLanguageDto?.name}
        </Typography>

        <Box className={classes.primaryLanguageSelector}>
          {primaryLanguageValue ? (
            <SurAutocomplete
              id="selectPrimaryLanguage"
              classes={{ root: classes.setPrimaryLanguageInput }}
              {...{
                data: languages ?? [],
                value: primaryLanguageValue,
                onChange: setPrimaryLanguageValue,
              }}
              options={primaryLanguageOptions}
              inputProps={primaryLanguageInputProps}
            />
          ) : (
            <Skeleton
              variant="rect"
              width="100%"
              height={40}
              style={{ marginRight: '10px' }}
            />
          )}
          <SurButton
            className={classes.setPrimaryLanguageButton}
            data-cy="SetPrimaryLanguageButton"
            onClick={handleSetPrimaryLanguage}
          >
            {formatMessage(messages.primaryLanguageButton)}
          </SurButton>
        </Box>
      </Grid>
      <div className={classes.addBtnContainer}>
        <Grid
          // xl={5}
          lg={5}
          md={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <SurButton
            classes={{ root: 'downloadLanguageTemplateBtn' }}
            onClick={handleDownloadLanguageTemplate}
            // size="small"
          >
            <DownloadIcon style={{ margin: '0 12px' }} />
            {formatMessage(messages.downloadTemplate)}
          </SurButton>
          <SurButton
            classes={{ root: 'addLanguageBtn' }}
            onClick={handleAddLanguageClick}
            data-cy="AddLanguageButton"
            // size="small"
          >
            <PlusIcon style={{ margin: '0 12px' }} />
            {formatMessage(messages.addLanguage)}
          </SurButton>
        </Grid>
      </div>
      <Box component="footer" className={classes.footer}>
        <Link to="#">
          <QuestionIcon style={{ margin: '0 5px' }} />
          {formatMessage(messages.footerHelpLabel)}
        </Link>
      </Box>
    </SurTabPanel>
  )
}

export default LanguageTab
