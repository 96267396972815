import { contextWithEndpoint, EndpointType } from 'core/apollo/links'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Language } from 'types/serverTypes'
import { isNotNilAndEmpty } from 'utils/nullish'
import {
  array as YupArray,
  boolean as YupBoolean,
  object as YupObject,
} from 'yup'
import {
  useGetCountriesInCreateLanguageQuery,
  useGetCountriesListInUpdateLanguageLazyQuery,
  useGetCountryLanguagesInCreateLanguageQuery,
} from '../../GeneralScreen.queries.generated'
import messages from '../LanguageTab.messages'
import {
  LanguageModalFormValues,
  LanguageModalProps,
} from '../LanguageTab.types'

const useLanguageModal = ({ isOpen, modalData }: LanguageModalProps) => {
  const { formatMessage } = useIntl()

  const [languageSelector, setLanguageSelector] = useState<Language[]>()
  const [countriesSelector, setCountriesSelector] = useState<Language[]>()

  const {
    loading: createCountriesLoading,
    refetch: createCountriesRefetch,
  } = useGetCountriesInCreateLanguageQuery({
    onCompleted: (data) => {
      setCountriesSelector(
        (data?.countriesInCreateLanguage?.listDto?.items as any[]) ?? [],
      )
    },
    ...contextWithEndpoint(EndpointType.Cms),
  })
  const [
    getUpdateCountrieas,
    { loading: updateCountriesLoading, refetch: updateCountriesRefetch },
  ] = useGetCountriesListInUpdateLanguageLazyQuery({
    onCompleted: (data) => {
      setCountriesSelector(
        (data?.countriesInUpdateLanguage?.listDto?.items as any[]) ?? [],
      )
    },
    ...contextWithEndpoint(EndpointType.Cms),
  })
  const {
    loading: languageLoading,
    refetch: languageRefetch,
  } = useGetCountryLanguagesInCreateLanguageQuery({
    onCompleted: (data) => {
      setLanguageSelector(
        (data?.countryLanguagesInCreateLanguage?.listDto?.items as any[]) ?? [],
      )
    },
    ...contextWithEndpoint(EndpointType.Cms),
  })

  useEffect(() => {
    if (!isOpen || !modalData) return
    languageRefetch?.()
    if (modalData.status === 'add') {
      createCountriesRefetch?.()
    } else {
      updateCountriesRefetch?.()
    }
    getUpdateCountrieas({
      variables: {
        filter: {
          all: true,
          includes: ['CountryLanguages'],
          dto: {
            usingLanguageDto: {
              id: modalData.row?.id,
            },
          },
        },
      },
    })
  }, [
    countriesSelector,
    createCountriesRefetch,
    getUpdateCountrieas,
    isOpen,
    languageRefetch,
    modalData,
    updateCountriesRefetch,
  ])

  const countriesLoading = modalData?.row?.id
    ? createCountriesLoading
    : updateCountriesLoading

  const selectCountriesOptions = {
    disabled: false,
    labelExp: 'name',
    valueExp: 'id',
    iconExp: 'flagUrl',
    disableExp: 'disabled',
    multiple: true,
    isLoading: countriesLoading,
    disableCloseOnSelect: true,
  }
  const selectCountriesInputProps = {
    name: 'country',
    label: formatMessage(messages.modalFormCountryLabel),
  }

  const selectLanguageOptions = {
    disabled: modalData?.status === 'edit',
    labelExp: 'name',
    valueExp: 'id',
    iconExp: 'countryDto.flagUrl',
    disableExp: 'disabled',
    multiple: false,
    isLoading: languageLoading,
    disableCloseOnSelect: true,
  }
  const selectLanguageInputProps = {
    name: 'language',
    label: formatMessage(messages.modalFormLanguageLabel),
  }

  const urlAddress = modalData?.row?.fileUrl?.match(/[^/]+[^.]+$/)?.[0]

  const initialValues: LanguageModalFormValues = {
    selectLanguage: modalData?.row?.countryLanguageDto ?? undefined,
    selectCountries: (modalData?.row?.usedInCountryDtos as any) ?? [],
    published: modalData?.row?.published ?? false,
    languageUploader: urlAddress
      ? ({
        [urlAddress]: {
          name: `${modalData?.row?.countryLanguageDto?.name}-${formatMessage(
            messages.modalFormLanguageLabel,
          )}`,
          url: modalData?.row?.fileUrl,
        },
      } as any)
      : {},
  }
  const validationSchema = YupObject().shape({
    selectLanguage: YupObject()
      .shape({})
      .test(
        'isNotEmpty',
        formatMessage(messages.modalFormLanguageValidation),
        isNotNilAndEmpty,
      ),
    selectCountries: YupArray().test(
      'isNotEmpty',
      formatMessage(messages.modalFormCountryValidation),
      (val: any) => val && val.length > 0,
    ),
    published: YupBoolean(),
    languageUploader: YupObject()
      .shape({})
      .test(
        'isNotEmpty',
        formatMessage(messages.modalFormBrowseFileValidation),
        (val) => isNotNilAndEmpty(val),
      ),
  })

  const options = {
    accept: ['.csv'],
    multiple: false,
    noIntro: false,
    dragable: true,
    clickable: true,
    maxSize: 2000000,
    maxFiles: 1,
  }

  const fileUploaderLocalization: any = {
    browseFile: formatMessage(messages.modalFormBrowseFileLabel),
    dragAndDropFileHere: formatMessage(
      messages.modalFormDragAndDropFileHereLabel,
    ),
    limitFile: formatMessage(messages.modalFormLimitFileLabel),
    or: formatMessage(messages.modalFormOrLabel),
    sizeLimited: formatMessage(messages.modalFormSizeLimitedLabel),
    supportedFiles: formatMessage(messages.modalFormSupportedFilesLabel),
  }

  return {
    formatMessage,
    selectCountriesOptions,
    selectCountriesInputProps,
    selectLanguageOptions,
    selectLanguageInputProps,
    initialValues,
    validationSchema,
    options,
    fileUploaderLocalization,
    languageSelector,
    languageLoading,
    countriesSelector,
    countriesLoading,
  }
}

export default useLanguageModal
