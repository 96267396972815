export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Date` scalar type represents a year, month and day in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  Date: any;
  /** The `DateTime` scalar type represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTime: any;
  /** The `DateTimeOffset` scalar type represents a date, time and offset from UTC. `DateTimeOffset` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTimeOffset: any;
  /** The `Seconds` scalar type represents a period of time represented as the total number of seconds. */
  Seconds: any;
  /** The `Milliseconds` scalar type represents a period of time represented as the total number of milliseconds. */
  Milliseconds: any;
  Decimal: any;
  Uri: any;
  Guid: any;
  Short: any;
  UShort: any;
  UInt: any;
  Long: any;
  BigInt: any;
  ULong: any;
  Byte: any;
  SByte: any;
};

















export type Queries = {
  __typename?: 'Queries';
  countries?: Maybe<GenericQueryResponseCountryType>;
  countriesInCreateCurrency?: Maybe<GenericQueryResponseCountryType>;
  countriesInCreateLanguage?: Maybe<GenericQueryResponseCountryType>;
  countriesInUpdateCurrency?: Maybe<GenericQueryResponseCountryType>;
  countriesInUpdateLanguage?: Maybe<GenericQueryResponseCountryType>;
  countryLanguagesInCreateLanguage?: Maybe<GenericQueryResponseCountryLanguageType>;
  currencies?: Maybe<GenericQueryResponseCurrencyType>;
  currenciesInCreate?: Maybe<GenericQueryResponseCurrencyType>;
  currenciesInUpdate?: Maybe<GenericQueryResponseCurrencyType>;
  dashboardReports?: Maybe<GenericQueryResponseDashboardReportType>;
  dashboards?: Maybe<GenericQueryResponseDashboardType>;
  languages?: Maybe<GenericQueryResponseLanguageType>;
  permissions?: Maybe<GenericQueryResponseResourceType>;
  persons?: Maybe<GenericQueryResponsePersonType>;
  profile?: Maybe<GenericQueryResponsePersonType>;
  resources?: Maybe<GenericQueryResponseResourceType>;
  roles?: Maybe<GenericQueryResponseRoleType>;
  userLanguages?: Maybe<GenericQueryResponseLanguageType>;
  users?: Maybe<GenericQueryResponseUserType>;
};


export type QueriesCountriesArgs = {
  filter: GenericFilterRequestCountryInputType;
};


export type QueriesCountriesInCreateCurrencyArgs = {
  filter: GenericFilterRequestCountryInputType;
};


export type QueriesCountriesInCreateLanguageArgs = {
  filter: GenericFilterRequestCountryInputType;
};


export type QueriesCountriesInUpdateCurrencyArgs = {
  filter: GenericFilterRequestCountryInputType;
};


export type QueriesCountriesInUpdateLanguageArgs = {
  filter: GenericFilterRequestCountryInputType;
};


export type QueriesCountryLanguagesInCreateLanguageArgs = {
  filter: GenericFilterRequestCountryLanguageInputType;
};


export type QueriesCurrenciesArgs = {
  filter: GenericFilterRequestCurrencyInputType;
};


export type QueriesCurrenciesInCreateArgs = {
  filter: GenericFilterRequestCurrencyInputType;
};


export type QueriesCurrenciesInUpdateArgs = {
  filter: GenericFilterRequestCurrencyInputType;
};


export type QueriesDashboardReportsArgs = {
  filter: GenericFilterRequestDashboardReportInputType;
};


export type QueriesDashboardsArgs = {
  filter: GenericFilterRequestDashboardInputType;
};


export type QueriesLanguagesArgs = {
  filter: GenericFilterRequestLanguageInputType;
};


export type QueriesPermissionsArgs = {
  filter: GenericFilterRequestResourceInputType;
};


export type QueriesPersonsArgs = {
  filter: GenericFilterRequestPersonInputType;
};


export type QueriesProfileArgs = {
  filter: GenericFilterRequestPersonInputType;
};


export type QueriesResourcesArgs = {
  filter: GenericFilterRequestResourceInputType;
};


export type QueriesRolesArgs = {
  filter: GenericFilterRequestRoleInputType;
};


export type QueriesUserLanguagesArgs = {
  filter: GenericFilterRequestLanguageInputType;
};


export type QueriesUsersArgs = {
  filter: GenericFilterRequestUserInputType;
};

export type GenericQueryResponseLanguageType = {
  __typename?: 'GenericQueryResponseLanguageType';
  isSuccess?: Maybe<Scalars['Boolean']>;
  listDto?: Maybe<ListLanguageType>;
  messagingKey?: Maybe<Scalars['String']>;
  responseExceptionStatus?: Maybe<ResponseExceptionStatusType>;
};

export type ListLanguageType = {
  __typename?: 'ListLanguageType';
  count?: Maybe<Scalars['Long']>;
  items?: Maybe<Array<Maybe<Language>>>;
};

export type Language = {
  __typename?: 'Language';
  countryLanguageDto?: Maybe<CountryLanguage>;
  createdDateTime: Scalars['Date'];
  fileUrl?: Maybe<Scalars['String']>;
  flagUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  languageDirection?: Maybe<LanguageDirection>;
  languageResources?: Maybe<Array<Maybe<LanguageResource>>>;
  published: Scalars['Boolean'];
  slug?: Maybe<Scalars['String']>;
  updatedDateTime: Scalars['Date'];
  usedInCountryDtos?: Maybe<Array<Maybe<Country>>>;
};

export enum LanguageDirection {
  Ltr = 'LTR',
  Rtl = 'RTL'
}

export type Country = {
  __typename?: 'Country';
  countryLanguageDtos?: Maybe<Array<Maybe<CountryLanguage>>>;
  createdDateTime: Scalars['Date'];
  flagUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  published: Scalars['Boolean'];
  updatedDateTime: Scalars['Date'];
  usingCurrencyDto?: Maybe<Currency>;
  usingLanguageDto?: Maybe<Language>;
};

export type CountryLanguage = {
  __typename?: 'CountryLanguage';
  countryDto?: Maybe<Country>;
  createdDateTime: Scalars['Date'];
  id: Scalars['ID'];
  languageDirection?: Maybe<LanguageDirection>;
  languageDto?: Maybe<Language>;
  name?: Maybe<Scalars['String']>;
  published: Scalars['Boolean'];
  slug?: Maybe<Scalars['String']>;
  updatedDateTime: Scalars['Date'];
};

export type LanguageResource = {
  __typename?: 'LanguageResource';
  key?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ResponseExceptionStatusType = {
  __typename?: 'ResponseExceptionStatusType';
  exceptionType?: Maybe<ExceptionGraphType>;
  message?: Maybe<Scalars['String']>;
};

export enum ExceptionGraphType {
  Accepted = 'ACCEPTED',
  BadGateway = 'BAD_GATEWAY',
  BadRequest = 'BAD_REQUEST',
  CannotDelete = 'CANNOT_DELETE',
  Conflict = 'CONFLICT',
  Continue = 'CONTINUE',
  Created = 'CREATED',
  EntityExist = 'ENTITY_EXIST',
  ExpectationFailed = 'EXPECTATION_FAILED',
  Forbidden = 'FORBIDDEN',
  GatewayTimeout = 'GATEWAY_TIMEOUT',
  Gone = 'GONE',
  HttpVersionNotSupported = 'HTTP_VERSION_NOT_SUPPORTED',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  LengthRequired = 'LENGTH_REQUIRED',
  MethodNotAllowed = 'METHOD_NOT_ALLOWED',
  Moved = 'MOVED',
  MultipleChoices = 'MULTIPLE_CHOICES',
  NonAuthoritativeInformation = 'NON_AUTHORITATIVE_INFORMATION',
  NotAcceptable = 'NOT_ACCEPTABLE',
  NotFound = 'NOT_FOUND',
  NotImplemented = 'NOT_IMPLEMENTED',
  NotModified = 'NOT_MODIFIED',
  NoContent = 'NO_CONTENT',
  Ok = 'OK',
  PartialContent = 'PARTIAL_CONTENT',
  PaymentRequired = 'PAYMENT_REQUIRED',
  PreconditionFailed = 'PRECONDITION_FAILED',
  ProxyAuthenticationRequired = 'PROXY_AUTHENTICATION_REQUIRED',
  Redirect = 'REDIRECT',
  RedirectMethod = 'REDIRECT_METHOD',
  RequestedRangeNotSatisfiable = 'REQUESTED_RANGE_NOT_SATISFIABLE',
  RequestEntityTooLarge = 'REQUEST_ENTITY_TOO_LARGE',
  RequestTimeout = 'REQUEST_TIMEOUT',
  RequestUriTooLong = 'REQUEST_URI_TOO_LONG',
  ResetContent = 'RESET_CONTENT',
  ServiceUnavailable = 'SERVICE_UNAVAILABLE',
  SwitchingProtocols = 'SWITCHING_PROTOCOLS',
  TemporaryRedirect = 'TEMPORARY_REDIRECT',
  Unauthorized = 'UNAUTHORIZED',
  UnsupportedMediaType = 'UNSUPPORTED_MEDIA_TYPE',
  Unused = 'UNUSED',
  UpgradeRequired = 'UPGRADE_REQUIRED',
  UseProxy = 'USE_PROXY'
}

export type GenericFilterRequestLanguageInputType = {
  all?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Maybe<Scalars['Guid']>>>;
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  dto?: Maybe<LanguageInput>;
  filterExpression?: Maybe<Scalars['String']>;
  orderByFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  orderByDescendings?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  includes?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalIncludes?: Maybe<Array<Maybe<Scalars['String']>>>;
  sessionKey?: Maybe<Scalars['String']>;
};

export type LanguageInput = {
  id?: Maybe<Scalars['Guid']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  fileUrl?: Maybe<Scalars['String']>;
  languageDirection?: Maybe<LanguageDirection>;
  usedInCountryDtos?: Maybe<Array<Maybe<CountryInput>>>;
  countryLanguageDto?: Maybe<CountryLanguageInput>;
  displayOrder?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
};

export type CountryInput = {
  countryLanguageDtos?: Maybe<Array<Maybe<CountryLanguageInput>>>;
  displayOrder?: Maybe<Scalars['Int']>;
  flagUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Guid']>;
  name?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  usingCurrencyDto?: Maybe<CurrencyInput>;
  usingLanguageDto?: Maybe<LanguageInput>;
};

export type CountryLanguageInput = {
  id?: Maybe<Scalars['Guid']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  languageDirection?: Maybe<LanguageDirection>;
  countryDto?: Maybe<CountryInput>;
  languageDto?: Maybe<LanguageInput>;
  displayOrder?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
};

export type GenericQueryResponseCountryType = {
  __typename?: 'GenericQueryResponseCountryType';
  isSuccess?: Maybe<Scalars['Boolean']>;
  listDto?: Maybe<ListCountryType>;
  messagingKey?: Maybe<Scalars['String']>;
  responseExceptionStatus?: Maybe<ResponseExceptionStatusType>;
};

export type ListCountryType = {
  __typename?: 'ListCountryType';
  count?: Maybe<Scalars['Long']>;
  items?: Maybe<Array<Maybe<Country>>>;
};

export type GenericFilterRequestCountryInputType = {
  all?: Maybe<Scalars['Boolean']>;
  dto?: Maybe<CountryInput>;
  externalIncludes?: Maybe<Array<Maybe<Scalars['String']>>>;
  filterExpression?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['Guid']>>>;
  includes?: Maybe<Array<Maybe<Scalars['String']>>>;
  orderByDescendings?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  orderByFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  pageIndex?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sessionKey?: Maybe<Scalars['String']>;
};

export type GenericQueryResponseCountryLanguageType = {
  __typename?: 'GenericQueryResponseCountryLanguageType';
  isSuccess?: Maybe<Scalars['Boolean']>;
  listDto?: Maybe<ListCountryLanguageType>;
  messagingKey?: Maybe<Scalars['String']>;
  responseExceptionStatus?: Maybe<ResponseExceptionStatusType>;
};

export type ListCountryLanguageType = {
  __typename?: 'ListCountryLanguageType';
  count?: Maybe<Scalars['Long']>;
  items?: Maybe<Array<Maybe<CountryLanguage>>>;
};

export type GenericFilterRequestCountryLanguageInputType = {
  all?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Maybe<Scalars['Guid']>>>;
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  dto?: Maybe<CountryLanguageInput>;
  filterExpression?: Maybe<Scalars['String']>;
  orderByFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  orderByDescendings?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  includes?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalIncludes?: Maybe<Array<Maybe<Scalars['String']>>>;
  sessionKey?: Maybe<Scalars['String']>;
};

export type Mutations = {
  __typename?: 'Mutations';
  clearLanguageCountries?: Maybe<GenericQueryResponseLanguageType>;
  createDashboard?: Maybe<GenericQueryResponseDashboardType>;
  createDashboardReport?: Maybe<GenericQueryResponseDashboardReportType>;
  createFile?: Maybe<GenericQueryResponseFileType>;
  createLanguage?: Maybe<GenericQueryResponseLanguageType>;
  createPerson?: Maybe<GenericQueryResponsePersonType>;
  createRole?: Maybe<GenericQueryResponseRoleType>;
  createUser?: Maybe<GenericQueryResponseUserType>;
  defaultCurrency?: Maybe<GenericQueryResponseCurrencyType>;
  defaultLanguage?: Maybe<GenericQueryResponseLanguageType>;
  deleteCurrency?: Maybe<GenericQueryResponseCurrencyType>;
  deleteDashboard?: Maybe<GenericQueryResponseDashboardType>;
  deleteDashboardReport?: Maybe<GenericQueryResponseDashboardReportType>;
  deleteFile?: Maybe<GenericQueryResponseFileType>;
  deleteLanguage?: Maybe<GenericQueryResponseLanguageType>;
  deletePerson?: Maybe<GenericQueryResponsePersonType>;
  deleteRole?: Maybe<GenericQueryResponseRoleType>;
  deleteUser?: Maybe<GenericQueryResponseUserType>;
  updateCurrency?: Maybe<GenericQueryResponseCurrencyType>;
  updateDashboard?: Maybe<GenericQueryResponseDashboardType>;
  updateDashboardReport?: Maybe<GenericQueryResponseDashboardReportType>;
  updateLanguage?: Maybe<GenericQueryResponseLanguageType>;
  updatePerson?: Maybe<GenericQueryResponsePersonType>;
  updatePersonSetting?: Maybe<GenericQueryResponsePersonType>;
  updateRole?: Maybe<GenericQueryResponseRoleType>;
  updateUser?: Maybe<GenericQueryResponseUserType>;
};


export type MutationsClearLanguageCountriesArgs = {
  language: GenericMutationRequestLanguageInputType;
};


export type MutationsCreateDashboardArgs = {
  dashboard: GenericMutationRequestDashboardInputType;
};


export type MutationsCreateDashboardReportArgs = {
  dashboardReport: GenericMutationRequestDashboardReportInputType;
};


export type MutationsCreateFileArgs = {
  file: GenericMutationRequestFileInputType;
};


export type MutationsCreateLanguageArgs = {
  language: GenericMutationRequestLanguageInputType;
};


export type MutationsCreatePersonArgs = {
  person: GenericMutationRequestPersonInputType;
};


export type MutationsCreateRoleArgs = {
  role: GenericMutationRequestRoleInputType;
};


export type MutationsCreateUserArgs = {
  user: GenericMutationRequestUserInputType;
};


export type MutationsDefaultCurrencyArgs = {
  currency: GenericMutationRequestCurrencyInputType;
};


export type MutationsDefaultLanguageArgs = {
  language: GenericMutationRequestLanguageInputType;
};


export type MutationsDeleteCurrencyArgs = {
  currency: GenericMutationRequestCurrencyInputType;
};


export type MutationsDeleteDashboardArgs = {
  dashboard: GenericMutationRequestDashboardInputType;
};


export type MutationsDeleteDashboardReportArgs = {
  dashboardReport: GenericMutationRequestDashboardReportInputType;
};


export type MutationsDeleteFileArgs = {
  file: GenericMutationRequestFileInputType;
};


export type MutationsDeleteLanguageArgs = {
  language: GenericMutationRequestLanguageInputType;
};


export type MutationsDeletePersonArgs = {
  person: GenericMutationRequestPersonInputType;
};


export type MutationsDeleteRoleArgs = {
  role: GenericMutationRequestRoleInputType;
};


export type MutationsDeleteUserArgs = {
  user: GenericMutationRequestUserInputType;
};


export type MutationsUpdateCurrencyArgs = {
  currency: GenericMutationRequestCurrencyInputType;
};


export type MutationsUpdateDashboardArgs = {
  dashboard: GenericMutationRequestDashboardInputType;
};


export type MutationsUpdateDashboardReportArgs = {
  dashboardReport: GenericMutationRequestDashboardReportInputType;
};


export type MutationsUpdateLanguageArgs = {
  language: GenericMutationRequestLanguageInputType;
};


export type MutationsUpdatePersonArgs = {
  person: GenericMutationRequestPersonInputType;
};


export type MutationsUpdatePersonSettingArgs = {
  person: GenericMutationRequestPersonInputType;
};


export type MutationsUpdateRoleArgs = {
  role: GenericMutationRequestRoleInputType;
};


export type MutationsUpdateUserArgs = {
  user: GenericMutationRequestUserInputType;
};

export type GenericMutationRequestLanguageInputType = {
  ids?: Maybe<Array<Maybe<Scalars['Guid']>>>;
  dto?: Maybe<LanguageInput>;
  sessionKey?: Maybe<Scalars['String']>;
  includes?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalIncludes?: Maybe<Array<Maybe<Scalars['String']>>>;
  ignoreGetResponse?: Maybe<Scalars['Boolean']>;
};

export type GenericQueryResponseFileType = {
  __typename?: 'GenericQueryResponseFileType';
  isSuccess?: Maybe<Scalars['Boolean']>;
  listDto?: Maybe<ListFileType>;
  messagingKey?: Maybe<Scalars['String']>;
  responseExceptionStatus?: Maybe<ResponseExceptionStatusType>;
};

export type ListFileType = {
  __typename?: 'ListFileType';
  count?: Maybe<Scalars['Long']>;
  items?: Maybe<Array<Maybe<File>>>;
};

export type File = {
  __typename?: 'File';
  createdDateTime: Scalars['Date'];
  entityType?: Maybe<EntityType>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  published: Scalars['Boolean'];
  size?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  updatedDateTime: Scalars['Date'];
  url?: Maybe<Scalars['String']>;
};

export enum EntityType {
  Product = 'PRODUCT',
  Category = 'CATEGORY',
  Collection = 'COLLECTION',
  Resource = 'RESOURCE',
  Person = 'PERSON',
  Country = 'COUNTRY',
  Language = 'LANGUAGE',
  Currency = 'CURRENCY',
  Test = 'TEST'
}

export type GenericMutationRequestFileInputType = {
  ids?: Maybe<Array<Maybe<Scalars['Guid']>>>;
  dto?: Maybe<FileInput>;
  sessionKey?: Maybe<Scalars['String']>;
  includes?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalIncludes?: Maybe<Array<Maybe<Scalars['String']>>>;
  ignoreGetResponse?: Maybe<Scalars['Boolean']>;
};

export type FileInput = {
  id?: Maybe<Scalars['Guid']>;
  url?: Maybe<Scalars['String']>;
  entityType?: Maybe<EntityType>;
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  binary?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
};

export type GenericQueryResponseResourceType = {
  __typename?: 'GenericQueryResponseResourceType';
  isSuccess?: Maybe<Scalars['Boolean']>;
  listDto?: Maybe<ListResourceType>;
  messagingKey?: Maybe<Scalars['String']>;
  responseExceptionStatus?: Maybe<ResponseExceptionStatusType>;
};

export type ListResourceType = {
  __typename?: 'ListResourceType';
  count?: Maybe<Scalars['Long']>;
  items?: Maybe<Array<Maybe<Resource>>>;
};

export type Resource = {
  __typename?: 'Resource';
  attribute: Scalars['String'];
  childrenDtos?: Maybe<Array<Maybe<Resource>>>;
  createdDateTime: Scalars['Date'];
  fileUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mobileAttribute: Scalars['String'];
  mobileValue: Scalars['String'];
  orderBy: Scalars['Int'];
  parentDto?: Maybe<Resource>;
  parentId?: Maybe<Scalars['ID']>;
  published: Scalars['Boolean'];
  resourceType?: Maybe<ResourceType>;
  roleDtos?: Maybe<Array<Maybe<Role>>>;
  showOnMobile: Scalars['Boolean'];
  title: Scalars['String'];
  uIResourceType?: Maybe<UiResourceType>;
  updatedDateTime: Scalars['Date'];
  value: Scalars['String'];
};

export enum ResourceType {
  None = 'NONE',
  Ui = 'UI',
  Business = 'BUSINESS',
  Service = 'SERVICE'
}

export enum UiResourceType {
  None = 'NONE',
  CommonMenu = 'COMMON_MENU',
  Control = 'CONTROL',
  NotificationMenu = 'NOTIFICATION_MENU'
}

export type Role = {
  __typename?: 'Role';
  createdDateTime: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
  published: Scalars['Boolean'];
  resourceDtos?: Maybe<Array<Maybe<Resource>>>;
  updatedDateTime: Scalars['Date'];
  userDtos?: Maybe<Array<Maybe<User>>>;
};

export type User = {
  __typename?: 'User';
  dashboardDtos?: Maybe<Array<Maybe<Dashboard>>>;
  email: Scalars['String'];
  emailConfirmed: Scalars['Boolean'];
  id: Scalars['ID'];
  password?: Maybe<Scalars['String']>;
  personDto?: Maybe<Person>;
  phoneNumber?: Maybe<Scalars['String']>;
  roleDtos?: Maybe<Array<Maybe<Role>>>;
  userName: Scalars['String'];
};

export type Person = {
  __typename?: 'Person';
  avatarUrl?: Maybe<Scalars['String']>;
  createdDateTime: Scalars['Date'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  gender?: Maybe<Gender>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  nationalCode: Scalars['String'];
  personSetting?: Maybe<PersonSetting>;
  published: Scalars['Boolean'];
  updatedDateTime: Scalars['Date'];
  userDto?: Maybe<User>;
};

export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE'
}

export type PersonSetting = {
  __typename?: 'PersonSetting';
  currencyId: Scalars['ID'];
  languageId: Scalars['ID'];
  languageSlug: Scalars['String'];
};

export type Dashboard = {
  __typename?: 'Dashboard';
  createdDateTime: Scalars['Date'];
  dashboardConfigDto?: Maybe<DashboardConfig>;
  dashboardReportDtos?: Maybe<Array<Maybe<DashboardReport>>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  published: Scalars['Boolean'];
  updatedDateTime: Scalars['Date'];
  userDto?: Maybe<User>;
};

export type DashboardReport = {
  __typename?: 'DashboardReport';
  createdDateTime: Scalars['Date'];
  dashboardDto?: Maybe<Dashboard>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  published: Scalars['Boolean'];
  reportType?: Maybe<ReportType>;
  tileConfigDto?: Maybe<TileConfig>;
  updatedDateTime: Scalars['Date'];
};

export enum ReportType {
  None = 'NONE'
}

export type TileConfig = {
  __typename?: 'TileConfig';
  col: Scalars['Int'];
  row: Scalars['Int'];
  tileId: Scalars['ID'];
};

export type DashboardConfig = {
  __typename?: 'DashboardConfig';
  tileConfigDtos?: Maybe<Array<Maybe<TileConfig>>>;
};

export type GenericFilterRequestResourceInputType = {
  all?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Maybe<Scalars['Guid']>>>;
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  dto?: Maybe<ResourceInput>;
  filterExpression?: Maybe<Scalars['String']>;
  orderByFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  orderByDescendings?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  includes?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalIncludes?: Maybe<Array<Maybe<Scalars['String']>>>;
  sessionKey?: Maybe<Scalars['String']>;
};

export type ResourceInput = {
  id?: Maybe<Scalars['Guid']>;
  parentDto?: Maybe<ResourceInput>;
  childrenDtos?: Maybe<Array<Maybe<ResourceInput>>>;
  title?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  mobileValue?: Maybe<Scalars['String']>;
  resourceType?: Maybe<ResourceType>;
  attribute?: Maybe<Scalars['String']>;
  mobileAttribute?: Maybe<Scalars['String']>;
  uIResourceType?: Maybe<UiResourceType>;
  showOnMobile?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<Scalars['Int']>;
  roleDtos?: Maybe<Array<Maybe<RoleInput>>>;
  fileUrl?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
};

export type RoleInput = {
  id?: Maybe<Scalars['Guid']>;
  name?: Maybe<Scalars['String']>;
  userDtos?: Maybe<Array<Maybe<UserInput>>>;
  resourceDtos?: Maybe<Array<Maybe<ResourceInput>>>;
  displayOrder?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
};

export type UserInput = {
  id?: Maybe<Scalars['Guid']>;
  userName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailConfirmed?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  oldPassword?: Maybe<Scalars['String']>;
  confirmPassword?: Maybe<Scalars['String']>;
  personDto?: Maybe<PersonInput>;
  dashboardDtos?: Maybe<Array<Maybe<DashboardInput>>>;
  roleDtos?: Maybe<Array<Maybe<RoleInput>>>;
};

export type PersonInput = {
  id?: Maybe<Scalars['Guid']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nationalCode?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  userDto?: Maybe<UserInput>;
  fullName?: Maybe<Scalars['String']>;
  personSetting?: Maybe<PersonSettingInput>;
  displayOrder?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
};

export type PersonSettingInput = {
  languageId?: Maybe<Scalars['Guid']>;
  currencyId?: Maybe<Scalars['Guid']>;
  languageSlug?: Maybe<Scalars['String']>;
};

export type DashboardInput = {
  id?: Maybe<Scalars['Guid']>;
  name?: Maybe<Scalars['String']>;
  userDto?: Maybe<UserInput>;
  dashboardReportDtos?: Maybe<Array<Maybe<DashboardReportInput>>>;
  dashboardConfigDto?: Maybe<DashboardConfigInput>;
  displayOrder?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
};

export type DashboardReportInput = {
  id?: Maybe<Scalars['Guid']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  reportType?: Maybe<ReportType>;
  tileConfigDto?: Maybe<TileConfigInput>;
  dashboardDto?: Maybe<DashboardInput>;
  displayOrder?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
};

export type TileConfigInput = {
  tileId?: Maybe<Scalars['Guid']>;
  row?: Maybe<Scalars['Int']>;
  col?: Maybe<Scalars['Int']>;
};

export type DashboardConfigInput = {
  tileConfigDtos?: Maybe<Array<Maybe<TileConfigInput>>>;
};

export type GenericQueryResponseDashboardType = {
  __typename?: 'GenericQueryResponseDashboardType';
  isSuccess?: Maybe<Scalars['Boolean']>;
  listDto?: Maybe<ListDashboardType>;
  messagingKey?: Maybe<Scalars['String']>;
  responseExceptionStatus?: Maybe<ResponseExceptionStatusType>;
};

export type ListDashboardType = {
  __typename?: 'ListDashboardType';
  count?: Maybe<Scalars['Long']>;
  items?: Maybe<Array<Maybe<Dashboard>>>;
};

export type GenericFilterRequestDashboardInputType = {
  all?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Maybe<Scalars['Guid']>>>;
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  dto?: Maybe<DashboardInput>;
  filterExpression?: Maybe<Scalars['String']>;
  orderByFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  orderByDescendings?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  includes?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalIncludes?: Maybe<Array<Maybe<Scalars['String']>>>;
  sessionKey?: Maybe<Scalars['String']>;
};

export type GenericQueryResponseDashboardReportType = {
  __typename?: 'GenericQueryResponseDashboardReportType';
  isSuccess?: Maybe<Scalars['Boolean']>;
  listDto?: Maybe<ListDashboardReportType>;
  messagingKey?: Maybe<Scalars['String']>;
  responseExceptionStatus?: Maybe<ResponseExceptionStatusType>;
};

export type ListDashboardReportType = {
  __typename?: 'ListDashboardReportType';
  count?: Maybe<Scalars['Long']>;
  items?: Maybe<Array<Maybe<DashboardReport>>>;
};

export type GenericFilterRequestDashboardReportInputType = {
  all?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Maybe<Scalars['Guid']>>>;
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  dto?: Maybe<DashboardReportInput>;
  filterExpression?: Maybe<Scalars['String']>;
  orderByFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  orderByDescendings?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  includes?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalIncludes?: Maybe<Array<Maybe<Scalars['String']>>>;
  sessionKey?: Maybe<Scalars['String']>;
};

export type GenericQueryResponsePersonType = {
  __typename?: 'GenericQueryResponsePersonType';
  isSuccess?: Maybe<Scalars['Boolean']>;
  listDto?: Maybe<ListPersonType>;
  messagingKey?: Maybe<Scalars['String']>;
  responseExceptionStatus?: Maybe<ResponseExceptionStatusType>;
};

export type ListPersonType = {
  __typename?: 'ListPersonType';
  count?: Maybe<Scalars['Long']>;
  items?: Maybe<Array<Maybe<Person>>>;
};

export type GenericFilterRequestPersonInputType = {
  all?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Maybe<Scalars['Guid']>>>;
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  dto?: Maybe<PersonInput>;
  filterExpression?: Maybe<Scalars['String']>;
  orderByFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  orderByDescendings?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  includes?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalIncludes?: Maybe<Array<Maybe<Scalars['String']>>>;
  sessionKey?: Maybe<Scalars['String']>;
};

export type GenericQueryResponseRoleType = {
  __typename?: 'GenericQueryResponseRoleType';
  isSuccess?: Maybe<Scalars['Boolean']>;
  listDto?: Maybe<ListRoleType>;
  messagingKey?: Maybe<Scalars['String']>;
  responseExceptionStatus?: Maybe<ResponseExceptionStatusType>;
};

export type ListRoleType = {
  __typename?: 'ListRoleType';
  count?: Maybe<Scalars['Long']>;
  items?: Maybe<Array<Maybe<Role>>>;
};

export type GenericFilterRequestRoleInputType = {
  all?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Maybe<Scalars['Guid']>>>;
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  dto?: Maybe<RoleInput>;
  filterExpression?: Maybe<Scalars['String']>;
  orderByFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  orderByDescendings?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  includes?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalIncludes?: Maybe<Array<Maybe<Scalars['String']>>>;
  sessionKey?: Maybe<Scalars['String']>;
};

export type GenericQueryResponseUserType = {
  __typename?: 'GenericQueryResponseUserType';
  isSuccess?: Maybe<Scalars['Boolean']>;
  listDto?: Maybe<ListUserType>;
  messagingKey?: Maybe<Scalars['String']>;
  responseExceptionStatus?: Maybe<ResponseExceptionStatusType>;
};

export type ListUserType = {
  __typename?: 'ListUserType';
  count?: Maybe<Scalars['Long']>;
  items?: Maybe<Array<Maybe<User>>>;
};

export type GenericFilterRequestUserInputType = {
  all?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Maybe<Scalars['Guid']>>>;
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  dto?: Maybe<UserInput>;
  filterExpression?: Maybe<Scalars['String']>;
  orderByFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  orderByDescendings?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  includes?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalIncludes?: Maybe<Array<Maybe<Scalars['String']>>>;
  sessionKey?: Maybe<Scalars['String']>;
};

export type GenericMutationRequestDashboardInputType = {
  ids?: Maybe<Array<Maybe<Scalars['Guid']>>>;
  dto?: Maybe<DashboardInput>;
  sessionKey?: Maybe<Scalars['String']>;
  includes?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalIncludes?: Maybe<Array<Maybe<Scalars['String']>>>;
  ignoreGetResponse?: Maybe<Scalars['Boolean']>;
};

export type GenericMutationRequestDashboardReportInputType = {
  ids?: Maybe<Array<Maybe<Scalars['Guid']>>>;
  dto?: Maybe<DashboardReportInput>;
  sessionKey?: Maybe<Scalars['String']>;
  includes?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalIncludes?: Maybe<Array<Maybe<Scalars['String']>>>;
  ignoreGetResponse?: Maybe<Scalars['Boolean']>;
};

export type GenericMutationRequestPersonInputType = {
  ids?: Maybe<Array<Maybe<Scalars['Guid']>>>;
  dto?: Maybe<PersonInput>;
  sessionKey?: Maybe<Scalars['String']>;
  includes?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalIncludes?: Maybe<Array<Maybe<Scalars['String']>>>;
  ignoreGetResponse?: Maybe<Scalars['Boolean']>;
};

export type GenericMutationRequestRoleInputType = {
  ids?: Maybe<Array<Maybe<Scalars['Guid']>>>;
  dto?: Maybe<RoleInput>;
  sessionKey?: Maybe<Scalars['String']>;
  includes?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalIncludes?: Maybe<Array<Maybe<Scalars['String']>>>;
  ignoreGetResponse?: Maybe<Scalars['Boolean']>;
};

export type GenericMutationRequestUserInputType = {
  ids?: Maybe<Array<Maybe<Scalars['Guid']>>>;
  dto?: Maybe<UserInput>;
  sessionKey?: Maybe<Scalars['String']>;
  includes?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalIncludes?: Maybe<Array<Maybe<Scalars['String']>>>;
  ignoreGetResponse?: Maybe<Scalars['Boolean']>;
};

export type GenericQueryResponseCurrencyType = {
  __typename?: 'GenericQueryResponseCurrencyType';
  isSuccess?: Maybe<Scalars['Boolean']>;
  listDto?: Maybe<ListCurrencyType>;
  messagingKey?: Maybe<Scalars['String']>;
  responseExceptionStatus?: Maybe<ResponseExceptionStatusType>;
};

export type ListCurrencyType = {
  __typename?: 'ListCurrencyType';
  count?: Maybe<Scalars['Long']>;
  items?: Maybe<Array<Maybe<Currency>>>;
};

export type Currency = {
  __typename?: 'Currency';
  createdDateTime: Scalars['Date'];
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  published: Scalars['Boolean'];
  simbolUrl?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updatedDateTime: Scalars['Date'];
  usedInCountryDtos?: Maybe<Array<Maybe<Country>>>;
};

export type GenericFilterRequestCurrencyInputType = {
  all?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Maybe<Scalars['Guid']>>>;
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  dto?: Maybe<CurrencyInput>;
  filterExpression?: Maybe<Scalars['String']>;
  orderByFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  orderByDescendings?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  includes?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalIncludes?: Maybe<Array<Maybe<Scalars['String']>>>;
  sessionKey?: Maybe<Scalars['String']>;
};

export type CurrencyInput = {
  id?: Maybe<Scalars['Guid']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  simbolUrl?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  usedInCountryDtos?: Maybe<Array<Maybe<CountryInput>>>;
  displayOrder?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
};

export type GenericMutationRequestCurrencyInputType = {
  ids?: Maybe<Array<Maybe<Scalars['Guid']>>>;
  dto?: Maybe<CurrencyInput>;
  sessionKey?: Maybe<Scalars['String']>;
  includes?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalIncludes?: Maybe<Array<Maybe<Scalars['String']>>>;
  ignoreGetResponse?: Maybe<Scalars['Boolean']>;
};
