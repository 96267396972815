import { defineMessages } from 'react-intl'

const scope = 'app.pages.setting.general'

const generalScreenMessages = defineMessages({
  headerTitleTooltip: {
    id: `${scope}.header.title.tooltip`,
    defaultMessage: 'General Help',
  },
  tabsLanguageTitle: {
    id: `${scope}.tabs.language.title`,
    defaultMessage: 'Languages',
  },
  tabsCurrenciesTitle: {
    id: `${scope}.tabs.Currencies.title`,
    defaultMessage: 'Currencies',
  },
  tabsTaxTitle: {
    id: `${scope}.tabs.Tax.title`,
    defaultMessage: 'Tax',
  },
  tabsLegalTitle: {
    id: `${scope}.tabs.Legal.title`,
    defaultMessage: 'Legal',
  },
})

export default generalScreenMessages
