// import { FormattedMessage } from 'react-intl'
// import messages from '../messages'
import React, { FC, useEffect } from 'react'
import authService from '../Auth.service'

const Login: FC = () => {
  useEffect(() => {
    authService.login()
  }, [])

  return (
    <div>
      loading
      {/* <h1>
      <FormattedMessage {...messages.greeting} />
    </h1>
    <span>
      <FormattedMessage {...messages.loginInfo} />
    </span>
    <br />
    <button onClick={() => authService.login()}>ورود</button> */}
    </div>
  )
}

export default Login
