import { Box } from '@material-ui/core'
import { SurSearch } from '@sur-ui/core'
import produce from 'immer'
import React, { useCallback, useEffect, useState } from 'react'
import useStyle from './TotalSearch.styles'

interface IData {
  alpha2Code: string
  flag: string
  name: string
}
interface IRequesetItems {
  open: boolean
  loading: boolean
  value: string
  data: IData[]
  history: IData[]
}

const TotalSearch: React.FC = () => {
  const classes = useStyle()

  const [{ open, data, loading, value, history }, setRequestItems] = useState<
  IRequesetItems
  >({
    open: false,
    loading: false,
    value: '',
    data: [],
    history: [],
  })

  const getData = async () => {
    setRequestItems(
      produce((draft: IRequesetItems) => {
        draft.loading = true
      }),
    )
    return fetch(
      'https://restcountries.eu/rest/v2/all?fields=name;alpha2Code;flag',
    )
  }

  const onOpen = useCallback(() => {
    setRequestItems(
      produce((draft: IRequesetItems) => {
        draft.open = true
      }),
    )
  }, [])
  const onClose = useCallback(
    () =>
      setRequestItems(
        produce((draft: IRequesetItems) => {
          draft.open = false
        }),
      ),
    [],
  )
  useEffect(() => {
    if (value.length === 0) {
      setRequestItems(
        produce((draft: IRequesetItems) => {
          draft.data = []
        }),
      )
    }
  }, [value])

  const onInputChange = useCallback(async (val: string) => {
    setRequestItems(
      produce((draft: IRequesetItems) => {
        draft.value = val
      }),
    )
    if (val.length > 2) {
      setRequestItems(
        produce((draft: IRequesetItems) => {
          draft.loading = true
        }),
      )
      const newData = await getData().then((response) => response.json())
      setRequestItems(
        produce((draft) => {
          draft.loading = false
          draft.data = newData.filter((optionName: IData) =>
            optionName.name.toLowerCase().includes(val.toLowerCase()),
          )
        }),
      )
    }
  }, [])

  const onItemClick = (item: IData) => {
    const historyHasValue = history.find(
      (his) => his.alpha2Code === item.alpha2Code,
    )
    setRequestItems(
      produce((draft) => {
        draft.value = item.name
        if (!historyHasValue) {
          draft.history = [...history, item]
        }
      }),
    )
    onClose()
  }

  const onHistoryItemRemove = useCallback((_, filterHistory?: any[]) => {
    if (filterHistory) {
      setRequestItems(
        produce((draft) => {
          draft.history = filterHistory
        }),
      )
    }
  }, [])
  const handleSubmit: ((val: any) => void) | undefined = (val) => {
    alert(JSON.stringify(val))
    onClose()
  }
  return (
    <Box component="div" className={classes.root}>
      <form className={classes.form} onSubmit={handleSubmit}>
        <SurSearch
          {...{
            data,
            history,
            value,
            open,
            loading,
            onOpen,
            onClose,
            onItemClick,
            onChange: onInputChange,
            onHistoryItemRemove,
          }}
          onSubmit={handleSubmit}
          labelExp="name"
        />
      </form>
    </Box>
  )
}
export default TotalSearch
