import { SurPageLoading } from '@sur-ui/core'
import Auth from 'domains/Auth'
import React, { FC } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import useApp from './App.biz'
import { AppProvider } from './App.context'
import AppRoutes from './AppRoutes'

const App: FC = () => {
  const value = useApp()

  return (
    <AppProvider {...{ value }}>
      <SurPageLoading visible={value.pageLoading} />
      <Router>
        <Auth signInRedirectRoute="/" authenticatedRoutes={AppRoutes} />
      </Router>
    </AppProvider>
  )
}

export default App
