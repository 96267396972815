import React from 'react'
import ReactDOM from 'react-dom'
import App from 'domains/App'
// import BreakPointsScreen from 'screens/BreakPoints/BreakPoints'
import * as serviceWorker from './serviceWorker'

// import FormikSample from 'screens/Form/FormikSample'

ReactDOM.render(<App />, document.getElementById('🌼root🌼'))

serviceWorker.unregister()
