import { makeFloraMaidStyles } from 'theme'

export default makeFloraMaidStyles(({ spacing, palette }) => ({
  root: {
    margin: spacing(3, 0),
  },
  button: {
    borderRadius: 99,
    padding: spacing(3, 2),
  },
  paper: {
    width: 140,
    boxShadow: `0px 5px 10px rgba(0,0,0,0.2)`,
    padding: spacing(2, 0, 4, 0),
    '& .TickSvg': {
      '& path': {
        fill: palette.neutral[500],
      },
    },
  },
  flagImage: {
    width: 30,
    height: 20,
    borderRadius: 3,
  },
  childButton: {
    width: '100%',
  },
  childButtonLabel: {
    justifyContent: 'start',
  },
  childImage: {
    width: 16,
    margin: spacing(0, 2),
  },
}))
