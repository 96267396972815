import React, { createContext, FC, useContext } from 'react'

export interface IAuthContext {
  isAuthenticated: boolean
}

interface IAuthContextProviderProps {
  value: IAuthContext
}

const AuthContext = createContext<IAuthContext>({
  isAuthenticated: false,
})

export const useAuthContext = () => useContext(AuthContext)

export const AuthContextProvider: FC<IAuthContextProviderProps> = (props) => (
  <AuthContext.Provider {...props} />
)
