import { defineMessages } from 'react-intl'

const scope = 'app.pages.setting.general.languageTab'

const LanguageTab = defineMessages({
  downloadTemplate: {
    id: `${scope}.downloadTemplate`,
    defaultMessage: 'Template',
  },
  addLanguage: {
    id: `${scope}.addLanguage`,
    defaultMessage: 'Language',
  },
  primaryLanguageTitle: {
    id: `${scope}.primaryLanguage.title`,
    defaultMessage: 'Primary language',
  },
  primaryLanguageContent: {
    id: `${scope}.primaryLanguage.content`,
    defaultMessage: 'Your primary language is set to',
  },
  primaryLanguageButton: {
    id: `${scope}.primaryLanguage.button`,
    defaultMessage: 'Set',
  },
  gridHeaderTitle: {
    id: `${scope}.grid.header.title`,
    defaultMessage: 'Title',
  },
  gridHeaderPublished: {
    id: `${scope}.grid.header.published`,
    defaultMessage: 'Published',
  },
  gridHeaderActions: {
    id: `${scope}.grid.header.actions`,
    defaultMessage: 'Actions',
  },
  modalTitle: {
    id: `${scope}.modal.title`,
    defaultMessage: 'Language',
  },
  modalTitleAddPrefix: {
    id: `${scope}.modal.title.prefix.add`,
    defaultMessage: 'Add',
  },
  modalTitleEditPrefix: {
    id: `${scope}.modal.title.prefix.edit`,
    defaultMessage: 'Edit',
  },
  modalTitleTooltip: {
    id: `${scope}.modal.title.tooltip`,
    defaultMessage: 'Language Help',
  },
  modalFormLanguageLabel: {
    id: `${scope}.modal.form.language.label`,
    defaultMessage: 'Language',
  },
  modalFormLanguageValidation: {
    id: `${scope}.modal.form.language.validation`,
    defaultMessage: 'Please upload Dictionary',
  },
  modalFormCountryLabel: {
    id: `${scope}.modal.form.country.label`,
    defaultMessage: 'Country',
  },
  modalFormCountryValidation: {
    id: `${scope}.modal.form.country.validation`,
    defaultMessage: 'Please select a Country',
  },
  modalFormPublishedLabel: {
    id: `${scope}.modal.form.published.label`,
    defaultMessage: 'Publish Language',
  },
  modalFormBrowseFileLabel: {
    id: `${scope}.modal.form.uploader.browseFile`,
    defaultMessage: 'Browse File',
  },
  modalFormBrowseFileValidation: {
    id: `${scope}.modal.form.uploader.validation`,
    defaultMessage: 'Please upload Dictionary',
  },
  modalFormDragAndDropFileHereLabel: {
    id: `${scope}.modal.form.uploader.dragAndDropFileHere`,
    defaultMessage: 'Drag and Drop File Here',
  },
  modalFormLimitFileLabel: {
    id: `${scope}.modal.form.uploader.limitFile`,
    defaultMessage: 'Limit File',
  },
  modalFormOrLabel: {
    id: `${scope}.modal.form.uploader.or`,
    defaultMessage: 'or',
  },
  modalFormSizeLimitedLabel: {
    id: `${scope}.modal.form.uploader.sizeLimited`,
    defaultMessage: 'Size Limited',
  },
  modalFormSupportedFilesLabel: {
    id: `${scope}.modal.form.uploader.supportedFiles`,
    defaultMessage: 'Supported Files',
  },
  modalCancel: {
    id: `${scope}.modal.cancel`,
    defaultMessage: 'Cancel',
  },
  modalSubmit: {
    id: `${scope}.modal.submit`,
    defaultMessage: 'OK',
  },
  createLangugeToastContent: {
    id: `${scope}.create.toast.content`,
    defaultMessage: 'Your Language create successfully',
  },
  updateLangugeToastContent: {
    id: `${scope}.update.toast.content`,
    defaultMessage: 'Your Language update successfully',
  },
  deleteLangugeToastContent: {
    id: `${scope}.toast.content`,
    defaultMessage: 'Your Language delete successfully',
  },
  changePrimaryLanguageToastContent: {
    id: `${scope}.primaryLanguage.toast.content`,
    defaultMessage: 'Your Primary Language change successfully',
  },
  footerHelpLabel: {
    id: `${scope}.footer.help.label`,
    defaultMessage: 'Learn more about Language',
  },
})
export default LanguageTab
