import * as Types from '../../types/serverTypes';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type UploadImageMutationVariables = {
  file: Types.GenericMutationRequestFileInputType;
};


export type UploadImageMutation = (
  { __typename?: 'Mutations' }
  & { createFile?: Types.Maybe<(
    { __typename?: 'GenericQueryResponseFileType' }
    & { listDto?: Types.Maybe<(
      { __typename?: 'ListFileType' }
      & { items?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'File' }
        & Pick<Types.File, 'url'>
      )>>> }
    )> }
  )> }
);


export const UploadImageDocument = gql`
    mutation uploadImage($file: GenericMutationRequestFileInputType!) {
  createFile(file: $file) {
    listDto {
      items {
        url
      }
    }
  }
}
    `;
export type UploadImageMutationFn = ApolloReactCommon.MutationFunction<UploadImageMutation, UploadImageMutationVariables>;
export function useUploadImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadImageMutation, UploadImageMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadImageMutation, UploadImageMutationVariables>(UploadImageDocument, baseOptions);
      }
export type UploadImageMutationHookResult = ReturnType<typeof useUploadImageMutation>;
export type UploadImageMutationResult = ApolloReactCommon.MutationResult<UploadImageMutation>;
export type UploadImageMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadImageMutation, UploadImageMutationVariables>;