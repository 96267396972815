import palette from './palette'

const typography = {
  h1: {
    fontWeight: 300,
    fontSize: '4rem',
    lineHeight: 1,
    letterSpacing: '-0.01562em',
  },
  h2: {
    fontWeight: 300,
    fontSize: '2rem',
    lineHeight: 1,
    letterSpacing: '-0.00833em',
  },
  h3: {
    fontWeight: 400,
    fontSize: '1.75rem',
    lineHeight: 1.04,
    letterSpacing: '0em',
  },
  h4: {
    fontWeight: 400,
    fontSize: '1.5rem',
    lineHeight: 1.17,
    letterSpacing: '0.00735em',
  },
  h5: {
    fontWeight: 400,
    fontSize: '1.25rem',
    lineHeight: 1.33,
    letterSpacing: '0em',
    margin: 0,
    color: palette.neutral[700],
  },
  h6: {
    fontWeight: 300,
    fontSize: '1rem',
    textAlign: 'initial',
    textTransform: 'initial',

    // lineHeight: 1.6,
    // letterSpacing: '0.0075em',
  },
  subtitle1: {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.75,
    letterSpacing: '0.00938em',
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: 1.57,
    letterSpacing: '0.00714em',
  },
  // body1: {
  //   fontWeight: 400,
  //   fontSize: '1rem',
  //   lineHeight: 1.5,
  //   letterSpacing: '0.00938em',
  // },
  // body2: {
  //   fontWeight: 400,
  //   fontSize: '0.875rem',
  //   lineHeight: 1.43,
  //   letterSpacing: '0.01071em',
  // },
  button: {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: 1.75,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
  },
  caption: {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
  },
  overline: {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 2.66,
    letterSpacing: '0.08333em',
    textTransform: 'uppercase',
  },
  htmlFontSize: 16,
  fontFamily: "'Roboto','Open Sans', sans-serif",
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
}

export default typography
