import { sha256 } from 'js-sha256'

interface IUploadFetchOptions extends RequestInit {
  onAbortPossible: any
  onLoadStart(id: string, abortHandler: () => void): void
  onProgress(e: ProgressEvent, id: string): void
}

const parseHeaders = (rawHeaders: any) => {
  const headers = new Headers()
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ')
  preProcessedHeaders.split(/\r?\n/).forEach((line: any) => {
    const parts = line.split(':')
    const key = parts.shift().trim()
    if (key) {
      const value = parts.join(':').trim()
      headers.append(key, value)
    }
  })
  return headers
}

const uploadFetch = (input: RequestInfo, options: IUploadFetchOptions) =>
  new Promise((resolve, reject) => {
    const dto =
      options.body && JSON.parse(options.body as string).variables.file.dto
    const xhr = new XMLHttpRequest()
    const id = sha256(`${dto.name}-${dto.size}`)
    xhr.onloadstart = () => {
      options.onLoadStart(id, () => xhr.abort())
    }
    xhr.onload = () => {
      const opts: any = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers: parseHeaders(xhr.getAllResponseHeaders() || ''),
      }
      opts.url =
        'responseURL' in xhr
          ? xhr.responseURL
          : opts.headers.get('X-Request-URL')
      const body = 'response' in xhr ? xhr.response : (xhr as any).responseText
      // console.log(body, opts, 'ResPonse')

      resolve(new Response(body, opts))
    }
    xhr.onerror = () => {
      reject(new TypeError('Network request failed'))
    }
    xhr.ontimeout = () => {
      reject(new TypeError('Network request failed'))
    }
    xhr.open(options.method!, input as string, true)

    Object.entries(options.headers!).forEach(([key, value]) => {
      xhr.setRequestHeader(key, value)
    })

    if (xhr.upload) {
      xhr.upload.onprogress = (e) => {
        options.onProgress(e, id)
      }
    }
    xhr.send(options.body)

    // if (options.onAbortPossible(id)) xhr.abort()
    // xhr.addEventListener('abort', options.onAbortPossible(id))
    // options.onAbortPossible(id, () => xhr.abort())
  })

export default uploadFetch
